import type { ConvexReactClient } from "convex/react";
import { Effect, Option } from "effect";
import { pipe } from "fp-ts/lib/function";
import { createContextAndHook } from "frontend-shared/src/util";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD, TE } from "shared/base-prelude";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { KnownCalendarAppt } from "shared/be/convex/Calendar/Calendar.Types";
import {
  CommunityPostReactions,
  CommunityPostWithReactionsDto,
  type CommunityInfo,
  type CommunityPostReplyInfo,
} from "shared/schemas/community/community.schemas";
import type { NearbyCalendarMonths } from "shared/types/calendar.types";
import type { CommunityDashVM } from "../../domains/community/community-dash.vm";
import { BaseStateMgr } from "./base.statemgr";
import { CalendarStateMgr } from "./calendar.statemgr";

export type CommunityPageTab = "Discussion" | "Learning" | "Members" | "Events";

export class CommunityPostStateMgr extends BaseStateMgr {
  localPostState$: Rx.BehaviorSubject<CommunityPostWithReactionsDto>;
  myReplyState$: Rx.BehaviorSubject<Option.Option<ReplyStateMgr>>;
  constructor(
    readonly p: {
      community: { slug: string; allMembers: { id: string; name: string }[] };
      post: CommunityPostWithReactionsDto;
      convex: ConvexReactClient;
      onSuccessReply: (p: { reply: CommunityPostReplyInfo }) => void;
    }
  ) {
    super({ convex: p.convex });
    this.localPostState$ =
      new Rx.BehaviorSubject<CommunityPostWithReactionsDto>(
        CommunityPostWithReactionsDto.make(
          {
            ...p.post,
            reactions: CommunityPostReactions.make(p.post.reactions, {
              disableValidation: true,
            }),
          },
          { disableValidation: true }
        )
      );
    this.myReplyState$ = new Rx.BehaviorSubject<Option.Option<ReplyStateMgr>>(
      Option.none()
    );
  }

  private mkNewReplyStateMgr = () => {
    return new ReplyStateMgr({
      communitySlug: this.p.community.slug,
      allCommunityMembers: this.p.community.allMembers,
      postId: this.p.post.id as unknown as Id<"communityPosts">,
      submitReply: async (_) => {
        this.runFetchAndSetLocalState(() => {
          this.myReplyState$.next(Option.none());
        });
        // this.p.onSuccessReply(r);
      },
    });
  };

  onCommentReplyButtonClick = () => {
    this.myReplyState$.next(Option.some(this.mkNewReplyStateMgr()));
  };

  runFetchAndSetLocalState = (onSuccess: () => void) => {
    Effect.runPromise(this.fetchAndSetLocalStateEff).then(() => {
      onSuccess();
    });
  };

  fetchAndSetLocalStateEff = Effect.gen(this, function* () {});

  setLocalLikesState(action: "like" | "unlike") {
    this.localPostState$.next(
      this.localPostState$.value.withNewLike(action === "like")
    );
  }

  likePost(action: "like" | "unlike") {
    this.setLocalLikesState(action);
  }
}

export class ReplyStateMgr {
  myReplyText$ = new Rx.BehaviorSubject<string>("");
  rdSubmitResult$ = new Rx.BehaviorSubject<RD.RemoteData<any, any>>(RD.initial);

  atMentionQuery$ = new Rx.BehaviorSubject<Option.Option<string>>(
    Option.none()
  );

  mbMatchingMentions$ = this.atMentionQuery$.pipe(
    RxO.map((mbq) => {
      return mbq.pipe(
        Option.map((q) => {
          if (q.length < 2) {
            return this.p.allCommunityMembers.slice(0, 5);
          }
          return this.p.allCommunityMembers
            .filter((m) => m.name.toLowerCase().includes(q.toLowerCase()))
            .slice(0, 5);
        })
      );
    })
  );

  constructor(
    readonly p: {
      communitySlug: string;
      allCommunityMembers: { id: string; name: string }[];
      postId: Id<"communityPosts">;
      submitReply: (p: {
        reply: { postId: Id<"communityPosts">; content: string };
      }) => Promise<void>;
    }
  ) {}

  onInputChange(v: string) {
    this.myReplyText$.next(v);

    const lastWord = v.split(" ").pop();
    if (lastWord && lastWord.startsWith("@")) {
      this.atMentionQuery$.next(Option.some(lastWord.slice(1)));
    } else {
      this.atMentionQuery$.next(Option.none());
    }
  }

  onAddMention(mid: string) {
    const matchingMember = this.p.allCommunityMembers.find(
      (mm) => mm.id === mid
    );
    console.log("MATCHING MEMBER! ", matchingMember);
    this.atMentionQuery$.next(Option.none());
  }

  onSubmitCommentClick = async () => {
    if (this.myReplyText$.value.trim().length < 2) {
      return;
    }
    this.rdSubmitResult$.next(RD.pending);
    await this.p.submitReply({
      reply: {
        content: this.myReplyText$.value,
        postId: this.p.postId,
      },
    });
    this.rdSubmitResult$.next(RD.success(undefined));
    this.rdSubmitResult$.next(RD.initial);
  };
}
