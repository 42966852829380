import { BookmarkIcon } from "lucide-react";
import { useEffect, useMemo, useRef } from "react";
import type { MomentTemporalType } from "shared/be/convex/Sessions/Annotations/Annotation.Types";
import { DisplayableTranscriptST } from "shared/schemas/session/review/transcript.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";

export const TranscriptSegmentView: React.FC<{
  start: number;
  isSelected?: boolean;
  header: {
    profilePhotoUrl: string | null;
    speakerName: string;
    sisStr: string;
    metaTitle: string;
  };
  body: {
    transcriptText: string;
  };
  matchingMoment?: {
    temporalType: MomentTemporalType;
    label: string;
    color: string;
  };
}> = ({ header, body, isSelected = false, matchingMoment }) => {
  return (
    <div
      className={`flex flex-col gap-2 p-6 border rounded-[12px] transition-colors
      ${
        isSelected
          ? "border-vid-purple bg-[rgba(105,13,255,0.1)]"
          : "border-vid-black-200"
      }`}
    >
      <div className="flex items-center gap-2">
        <div className="flex-1 flex items-center gap-2">
          <AvatarCircle
            mbProfilePhoto={ImageSrc.fromMbUrl(header.profilePhotoUrl)}
            size={56}
          />
          <h4 className="">{header.speakerName}</h4>
        </div>
        <h4 className="text-sm text-vid-black-500 font-roboto-flex">
          {header.sisStr}
        </h4>
      </div>
      <div className="leading-[151%]">{body.transcriptText}</div>
      {matchingMoment && (
        <div className="p-5 border rounded-[12px] border-vid-black-200 text-sm font-roboto-flex flex items-center gap-2">
          <BookmarkIcon color={matchingMoment.color} />
          {matchingMoment.label}
        </div>
      )}
    </div>
  );
};

export const TranscriptDisplay: React.FC<{
  transcript: typeof DisplayableTranscriptST.Encoded;
  selectedSegmentsBySis?: readonly number[];
}> = ({ transcript, selectedSegmentsBySis }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedSegmentRef = useRef<HTMLDivElement>(null);

  // Scroll to selected segment when it changes
  useEffect(() => {
    if (
      selectedSegmentsBySis?.length &&
      selectedSegmentRef.current &&
      containerRef.current
    ) {
      selectedSegmentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedSegmentsBySis]);

  const asST = useMemo(
    () => DisplayableTranscriptST.fromEncoded(transcript),
    [transcript]
  );

  const asStandardDisplaySegments = useMemo(
    () => asST.asStandardDisplaySegments,
    [asST]
  );

  return (
    <div
      ref={containerRef}
      className="flex flex-col gap-4 max-h-[450px] max-w-[624px] overflow-y-auto"
    >
      {asStandardDisplaySegments.map((segment) => (
        <div
          key={segment.start}
          ref={
            selectedSegmentsBySis?.includes(segment.start)
              ? selectedSegmentRef
              : undefined
          }
        >
          <TranscriptSegmentView
            {...segment}
            isSelected={selectedSegmentsBySis?.includes(segment.start)}
          />
        </div>
      ))}
      <div className="min-h-[100px] w-full"></div>
    </div>
  );
};
