import { useMutation, useQuery } from "convex/react";
import { useOnce } from "frontend-shared/src/util";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";

export function useCommunityEventDetailsVM(
  instanceId: Id<"calEventInstances">
) {
  const fullyRemoveCommunityCalEvent = useMutation(
    api.Calendar.CommunityCalEventFns.fullyRemoveCommunityCalEvent
  );
  const removeCommunityCalEventSingleInstance = useMutation(
    api.Calendar.CommunityCalEventFns.removeCommunityCalEventSingleInstance
  );
  const onSubmitCta = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.handleCtaSubmit
  );

  const inviteAllInCommunityToEvent = useMutation(
    api.Community.CommunityScreenFns.inviteAllInCommunityToEvent
  );

  const getEventShareableLink = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.onGetShareableLink
  );

  const now = useOnce(() => Date.now());

  const panelData = useQuery(
    api.Screens.Community.CommunityEventsScreenFns
      .getCommunityEventDetailsPanelData,
    {
      now,
      instanceId,
    }
  );

  const card = useQuery(
    api.Screens.Community.CommunityEventsScreenFns.getCommunityCardData,
    {
      instanceId,
      now,
    }
  );

  return {
    fullyRemoveCommunityCalEvent,
    removeCommunityCalEventSingleInstance,
    onSubmitCta,
    inviteAllInCommunityToEvent,
    getEventShareableLink,
    panelData,
    card,
  };
}
