import { TabView } from "@/src/components/tabs";
import { Match } from "effect";
import { Settings2 } from "lucide-react";
import React from "react";
import { IoShareOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { ColorKey } from "shared/utils/color.utils";
import { getColor } from "shared/utils/color.utils";
import { CircleRingsIcon } from "web-shared/src/assets/ellipse.svg";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "web-shared/src/components/ui/dropdown-menu";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { InviteToCommunityForm } from "../invite-to-community.form";

interface TopBannerProps {
  communityColorName: string;
  circleImageUrl: string | null;
  title:
    | { _tag: "TEXT"; text: string }
    | { _tag: "COMPONENT"; component: React.ReactNode };
  nextToTitleFc: React.ReactNode;
  rightView: React.ReactNode;
  isPrivate?: boolean;
}
export const TopBanner: React.FC<TopBannerProps> = ({
  communityColorName,
  circleImageUrl,
  title,
  nextToTitleFc,
  rightView,
  isPrivate,
}) => {
  return (
    <section className="flex flex-col h-[385px] relative">
      <TopBannerCoverPhotoSection communityColorName={communityColorName} />
      <div className="absolute bottom-0 left-8">
        <TopBannerCircleImage circleImageUrl={circleImageUrl} />
      </div>
      <div className="flex-1 min-h-0 flex justify-between pt-5 pb-[11px] pl-[182px]">
        <div className="flex items-center gap-2">
          {title._tag === "TEXT" ? (
            <h2 className="text-[36px] text-vid-black-900 leading-[45.36px] lg:min-w-0 lg:break-words lg:whitespace-normal overflow-hidden">
              {title.text}
            </h2>
          ) : (
            title.component
          )}
          {isPrivate && <div className="text-xs text-violet-400">Private</div>}
          {nextToTitleFc}
        </div>
        <div className="self-end flex-1 flex justify-end">{rightView}</div>
      </div>
    </section>
  );
};

const TopBannerCoverPhotoSection: React.FC<{
  communityColorName: string;
}> = ({ communityColorName }) => {
  return (
    <header
      className="flex flex-col w-full h-[300px] rounded-[12px] relative"
      style={{
        background: getColor(communityColorName as ColorKey),
      }}
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc04addd764ad8e8a96e0f2d32b56477025ed2959f96775d55c6914e349c57fe?apiKey=7548643458aa4f489ce4f6ae79dc4302&"
        alt=""
        className="w-full aspect-[3.57] max-md:max-w-full"
      />
    </header>
  );
};

const TopBannerCircleImage: React.FC<{
  circleImageUrl: string | null;
}> = ({ circleImageUrl }) => {
  return (
    <div className="w-[125px] aspect-square bg-white p-1 rounded-full bg-white flex flex-col">
      {circleImageUrl ? (
        <AvatarCircle
          mbProfilePhoto={ImageSrc.fromMbUrl(circleImageUrl)}
          size={120}
        />
      ) : (
        <div className="flex-1 flex justify-center items-center bg-bg-gray rounded-full w-full h-full">
          <CircleRingsIcon size={2} />
        </div>
      )}
    </div>
  );
};

interface CommunityDashLayoutProps {
  isFullWidth: boolean;
  topBanner: {
    props: TopBannerProps;
    maxHeight?: string;
    opacity?: number;
  };
  extraTopView?: React.ReactNode;
  children: React.ReactNode;
}

export const CommunityDashLayout: React.FC<CommunityDashLayoutProps> = ({
  isFullWidth,
  topBanner,
  extraTopView,
  children,
}) => {
  return (
    <div
      className={`min-h-0 flex-1 flex flex-col ${isFullWidth ? "w-full" : "max-w-[1180px]"} relative overflow-y-none`}
    >
      {extraTopView}
      <div
        className="transition-all duration-300 ease-in-out overflow-hidden"
        style={{
          maxHeight: topBanner.maxHeight,
          opacity: topBanner.opacity,
        }}
      >
        <TopBanner {...topBanner.props} />
      </div>
      <div
        className={`min-h-[200px] min-w-full ${topBanner.opacity === 1 ? "mt-8" : "-mt-[8px]"}`}
      >
        {children}
      </div>
    </div>
  );
};

export const CommunityDashTabs: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const navigate = useNavigate();
  const webDisplayVM = useWebGlobalDisplayVM();
  const curTab = useGetCurrentTab();

  const Tab: React.FC<{ tab: TabName }> = ({ tab }) => (
    <TabView title={tab} linkTo={urlForTab(communitySlug, tab)} />
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col lg:flex-row gap-9">
        <Tab tab="Discussion" />
        <Tab tab="Events" />
        <Tab tab="Members" />
        <Tab tab="Learning" />
      </div>

      <div>
        {curTab !== "Events" && (
          <SettingsButton
            menuItems={[
              ({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-50" : ""
                  } flex w-full items-center gap-4 px-4 py-2 text-vid-black-900`}
                  onClick={() => {
                    webDisplayVM.globalSheetVM.openSheet(
                      {
                        title: "Invite members to this community",
                        type: { tag: "create", icon: "profile-add" },
                      },
                      <InviteToCommunityForm communitySlug={communitySlug} />
                    );
                  }}
                >
                  <IoShareOutline className="text-xl" />
                  Send invite
                </button>
              ),
              ({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-50" : ""
                  } flex w-full items-center gap-4 px-4 py-2 text-vid-black-900`}
                  onClick={() => {
                    navigate(
                      UROUTES.COMMUNITIES.COMMUNITY.ADMIN.buildPath({
                        communitySlug: communitySlug,
                      })
                    );
                  }}
                >
                  <Settings2 className="text-xl" />
                  Admin
                </button>
              ),
            ]}
          />
        )}
      </div>
    </div>
  );
};

type TabName = (typeof TAB_NAMES)[number];
const TAB_NAMES = ["Discussion", "Events", "Members", "Learning"] as const;

function urlForTab(communitySlug: string, tab: TabName) {
  return Match.value(tab).pipe(
    Match.when("Discussion", () =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
        {
          community: communitySlug,
        }
      )
    ),
    Match.when("Events", () =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
        { community: communitySlug }
      )
    ),
    Match.when("Members", () =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
        { community: communitySlug }
      )
    ),
    Match.when("Learning", () =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.LEARNING.buildPath(
        { community: communitySlug }
      )
    ),
    Match.exhaustive
  );
}

function useGetCurrentTab() {
  const { pathname } = useLocation();

  return TAB_NAMES.find((tab) =>
    pathname.toLowerCase().includes(tab.toLowerCase())
  );
}

export const SettingsButton: React.FC<{
  menuItems: (({ active }: { active: boolean }) => React.ReactElement)[];
}> = ({ menuItems }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-[62px] aspect-square flex justify-center items-center border-[1px] border-vid-black-200 rounded-full hover:bg-gray-50">
        <GearIcon />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-h-[200px]">
        {menuItems.map((item, idx) => (
          <DropdownMenuItem key={idx} asChild>
            {item({ active: false })}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const GearIcon: React.FC<{ size?: number }> = ({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
