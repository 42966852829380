import { Switch } from "web-shared/src/components/ui/switch";
import {
  SetDurationSection,
  SetEndingSessionReminderSection,
} from "@/src/components/session-form.components";
import { Either } from "effect";
import { useStartASessionFormVM } from "frontend-shared/src/users/hp/start-session.vms";
import React from "react";
import { useNavigate } from "react-router-dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { isRight } from "shared/util";
import { ClientSearchableDropdown } from "src/domains/user/hp/clients/select-client-search.input";
import { PrimaryButton } from "web-shared/src/components/button";
import { RD } from "web-shared/src/prelude";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

type OpenDropdownId = "user" | "payment" | "duration" | "reminder";

export const StartASessionForm: React.FC<{
  onClose: () => void;
  forClient:
    | { _tag: "EXISTING"; client: SimpleUser }
    | { _tag: "SELECT"; myClients: SimpleUser[] };
}> = ({ forClient }) => {
  const nav = useNavigate();
  const webDisplayVM = useWebGlobalDisplayVM();
  const {
    onStartSession,
    selectedUserId$,
    selectedUserId,
    durationInMinutes$,
    durationInMinutes,
    endingSessionReminderInMinutes$,
    endingSessionReminderInMinutes,
    audioOnly,
    setAudioOnly,
    rdSubmitResult,
    setRdSubmitResult,
  } = useStartASessionFormVM(forClient);

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-12">
        {forClient._tag === "SELECT" && (
          <ClientSearchableDropdown
            onSelect={(client) => {
              selectedUserId$.next(client.id);
            }}
          />
        )}
        <SetDurationSection
          durationInMinutes$={durationInMinutes$}
          onChange={(v) => {
            durationInMinutes$.next(v);
          }}
        />
        <SetEndingSessionReminderSection
          endingSessionReminder$={endingSessionReminderInMinutes$}
          onChange={(v) => {
            endingSessionReminderInMinutes$.next(v);
          }}
        />
        <div className="flex gap-8 mt-8 mb-8">
          <h4 className="font-bold">Audio only?</h4>
          <Switch
            checked={audioOnly}
            onCheckedChange={(v) => {
              setAudioOnly(v);
            }}
            className="ml-2"
          />
        </div>
      </div>
      <PrimaryButton
        title={
          RD.isPending(rdSubmitResult) ? "Starting session..." : "Start session"
        }
        isLoading={RD.isPending(rdSubmitResult)}
        onClick={() => {
          setRdSubmitResult(RD.pending);
          onStartSession({
            withClientId: (selectedUserId as Id<"users">) ?? undefined,
            intendedDurationMinutes: durationInMinutes ?? undefined,
            endingSessionReminderInMinutes:
              endingSessionReminderInMinutes ?? undefined,
          }).then((er) => {
            console.log("er!!! ", er, Either.isRight(er));
            if (isRight(er)) {
              console.log("navigating to ", er.right.navNextUrl);
              nav(er.right.navNextUrl);
              webDisplayVM.globalSheetVM.closeSheet();
            } else {
              setRdSubmitResult(
                RD.failure(er.left) as RD.RemoteData<
                  { msg: string },
                  { navNextUrl: string }
                >
              );
            }
          });
        }}
      />
      {RD.isFailure(rdSubmitResult) && (
        <div className="text-red-500 font-bold self-center mt-4">
          {rdSubmitResult.error.msg}
        </div>
      )}
    </div>
  );
};
