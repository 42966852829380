import { api } from "shared/be/convex/_generated/api";
import { useOnce } from "../../util";
import { useQuery } from "convex/react";
import { Option } from "effect";
import { asMb } from "shared/util";

export function useSetupCommunitiesDash() {
  const now = useOnce(() => Date.now());

  const globalProfile = useQuery(api.User.Hp.HpFns.getMyGlobalProfile);

  const upcomingEventsForMe = useQuery(
    api.Community.CommunitiesHomeScreenFns.getUpcomingEventsForMe,
    { now }
  );

  const communitiesForMeList = useQuery(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesForMeList,
    {}
  );

  const recentlyJoinedUsers = useQuery(
    api.Community.CommunitiesHomeScreenFns.getRecentlyJoinedUsers,
    {}
  );

  return Option.all({
    globalProfile: asMb(globalProfile),
    upcomingEventsForMe: asMb(upcomingEventsForMe),
    communitiesForMeList: asMb(communitiesForMeList),
    recentlyJoinedUsers: asMb(recentlyJoinedUsers),
  });
}
