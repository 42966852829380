import { format } from "date-fns";
import type { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import type { KnownCalendarAppt } from "shared/be/convex/Calendar/Calendar.Types";
import { CalendarUtils } from "shared/utils/calendar.utils";
import { InversePrimaryButton } from "../../../components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { DayViewCalendar } from "./calendar-day-view";
import { CalendarMonthView } from "./calendar-month-view";

interface FullCalendarProps {
  stateMgr: CalendarStateMgr;
  onDayClick: (day: Date) => void;
  newApptButton?: {
    onClick: () => void;
    title: string;
  };
  onApptClick: (appt: KnownCalendarAppt) => void;
  fetchOnMount?: boolean;
}

const [StateMgrContext, useStateMgr] = createContextAndHook<
  CalendarStateMgr | undefined
>(undefined);

export const FullCalendar = ({
  stateMgr,
  onDayClick,
  onApptClick,
  newApptButton,
  fetchOnMount,
}: FullCalendarProps) => {
  const rdMonthAppts = useObservableEagerState(
    stateMgr.monthViewStateMgr.appointmentsForNearbyMonths$
  );
  const dayInFocus = useObservableEagerState(
    stateMgr.monthViewStateMgr.currentDayInFocus$
  );
  const viewState = useObservableEagerState(stateMgr.viewState$);

  useEffect(() => {
    if (fetchOnMount) {
      stateMgr.fetchAndSetAppts(dayInFocus);
    }
  }, [fetchOnMount]);

  return (
    <StateMgrContext.Provider value={stateMgr}>
      <div className="flex-1 flex flex-col gap-4 max-w-[1200px]">
        <CalendarNavbar newApptButton={newApptButton} />
        <div className="flex-1 min-h-0">
          {viewState._tag === "DAY" ? (
            <DayViewCalendar
              appointmentsForCurrentDay$={
                stateMgr.dayViewStateMgr.appointmentsForCurrentDay$
              }
            />
          ) : (
            <CalendarMonthView
              rdDaysWithEventsForMonth={rdMonthAppts}
              dayInFocus={CalendarUtils.asDOY(dayInFocus)}
              onDayClick={onDayClick}
              onApptClick={onApptClick}
            />
          )}
        </div>
      </div>
    </StateMgrContext.Provider>
  );
};

const CalendarNavbar: React.FC<{
  newApptButton?: { onClick: () => void; title: string };
}> = ({ newApptButton }) => {
  const stateMgr = useStateMgr()!;
  const viewState = useObservableEagerState(stateMgr.viewState$);

  return (
    <div className="flex justify-between h-[40px] mx-4">
      {viewState._tag === "DAY" ? (
        <DayViewDayToggles />
      ) : (
        <CalendarMonthViewDateToggles />
      )}
      <div className="flex gap-4 h-full">
        <DayMonthViewToggleButton />
        {newApptButton && (
          <InversePrimaryButton
            title={newApptButton.title}
            onClick={newApptButton.onClick}
            width={"400px"}
          />
        )}
      </div>
    </div>
  );
};

const CalendarMonthViewDateToggles: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const model = stateMgr.monthViewStateMgr;
  const currentMonth = useObservableEagerState(
    model.monthInFocusNameToDisplay$
  );

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-sans font-light text-xl w-[100px]">{currentMonth}</h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.goToPreviousMonth();
          }}
        >
          <BackwardArrowSvgIcon />
        </button>
        <button
          onClick={() => {
            model.goToNextMonth();
          }}
        >
          <ForwardArrowSvgIcon />
        </button>
      </div>
    </div>
  );
};

const DayMonthViewToggleButton: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const viewState = useObservableEagerState(stateMgr.viewState$);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="h-full flex w-full justify-center rounded-xl px-4 text-sm border text-black hover:bg-vid-gray/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/7 items-center">
        <p className="mr-2">{viewState._tag === "DAY" ? "Day" : "Month"}</p>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M16.6 7.45837L11.1666 12.8917C10.525 13.5334 9.47496 13.5334 8.8333 12.8917L3.39996 7.45837"
            stroke="#161616"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem
          onClick={() => {
            stateMgr.setViewState({ _tag: "MONTH" });
          }}
        >
          Month
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            stateMgr.setViewState({ _tag: "DAY" });
          }}
        >
          Day
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DayViewDayToggles: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const model = stateMgr.dayViewStateMgr;
  const currentDay = useObservableEagerState(model.selectedDate$);

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-bold">{format(currentDay, "EEEE, MMMM d, yyyy")}</h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.prevDay();
          }}
        >
          <BackwardArrowSvgIcon />
        </button>
        <button
          onClick={() => {
            model.nextDay();
          }}
        >
          <ForwardArrowSvgIcon />
        </button>
      </div>
    </div>
  );
};

const ForwardArrowSvgIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BackwardArrowSvgIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.09 19.9201L8.57 13.4001C7.8 12.6301 7.8 11.3701 8.57 10.6001L15.09 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
