import { LeftNavStepsCourseTemplate } from "@/src/domains/learning/courses/templates/left-nav-steps.course-template";
import { useParams } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";

export const CourseAppPage: React.FC = () => {
  const { courseId } = useTypedParams(UROUTES.COURSES.COURSE_ID);

  return (
    <div className="w-screen h-screen flex flex-col">
      <LeftNavStepsCourseTemplate courseId={courseId as Id<"courseInfo">} />
    </div>
  );
};
