import { useMutation } from "convex/react";
import { createEvent, createStore, sample, type StoreWritable } from "effector";
import { useOnce, useQueryStore } from "frontend-shared/src/util";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  LessonWithEnrollmentProgressETO,
  ModuleWithLessonsAndProgressETO,
} from "shared/be/convex/Learning/Courses/Courses.Types";

export function useSetupLeftNavStepsCourseTemplateVM(
  courseId: Id<"courseInfo">
) {
  const modules = useQueryStore(
    api.Learning.Courses.CoursesFns.getModulesWithLessonsAndProgress,
    {
      courseId,
    }
  );

  const markLessonCompleted = useMutation(
    api.Learning.Courses.LessonsFns.markLessonCompleted
  );

  const vm = useOnce(
    () =>
      new LeftNavStepsCourseTemplateVM({
        modules: modules.store,
        courseId,
        markLessonCompleted: (
          lessonId: Id<"courseLessons">,
          completed: boolean
        ) => markLessonCompleted({ lessonId, completed }),
      })
  );

  return vm;
}

export class LeftNavStepsCourseTemplateVM {
  // Events
  setActiveLessonEvt = createEvent<Id<"courseLessons">>();
  toggleModuleEvt = createEvent<Id<"courseModules">>();
  markLessonCompletedEvt = createEvent<{
    lessonId: Id<"courseLessons">;
    completed: boolean;
    nextLessonId: Id<"courseLessons"> | null;
  }>();

  // Stores
  $activeLesson = createStore<Id<"courseLessons"> | null>(null);
  $modules = createStore<ModuleWithLessonsAndProgressETO[] | null>(null);
  $currentLesson = createStore<LessonWithEnrollmentProgressETO | null>(null);
  $expandedModuleIds = createStore<Set<Id<"courseModules">>>(new Set());

  constructor(args: {
    modules: StoreWritable<ModuleWithLessonsAndProgressETO[] | null>;
    courseId: Id<"courseInfo">;
    markLessonCompleted: (
      lessonId: Id<"courseLessons">,
      completed: boolean
    ) => Promise<null>;
  }) {
    this.$modules = args.modules;

    // Handle active lesson changes
    sample({
      clock: this.setActiveLessonEvt,
      target: this.$activeLesson,
    });

    // Update current lesson when active lesson changes
    sample({
      clock: [this.$activeLesson, this.$modules],
      source: { activeLesson: this.$activeLesson, modules: this.$modules },
      fn: ({ activeLesson, modules }) => {
        if (!modules || !activeLesson) return null;
        const allLessons = modules.flatMap((m) => m.lessons);
        return allLessons.find((l) => l.id === activeLesson) ?? null;
      },
      target: this.$currentLesson,
    });

    // Handle module expansion toggling
    sample({
      clock: this.toggleModuleEvt,
      source: this.$expandedModuleIds,
      fn: (expandedIds, moduleId) => {
        const newSet = new Set(expandedIds);
        if (newSet.has(moduleId)) {
          newSet.delete(moduleId);
        } else {
          newSet.add(moduleId);
        }
        return newSet;
      },
      target: this.$expandedModuleIds,
    });

    // Handle lesson completion
    sample({
      clock: this.markLessonCompletedEvt,
      source: this.$modules,
      fn: (modules, { lessonId, completed }) => {
        if (!modules) return null;
        return modules.map((module) => ({
          ...module,
          lessons: module.lessons.map((lesson) =>
            lesson.id === lessonId
              ? { ...lesson, isCompleted: completed }
              : lesson
          ),
          completedLessons: completed
            ? module.completedLessons + 1
            : module.completedLessons - 1,
        }));
      },
      target: this.$modules,
    });

    // Navigate to next lesson after marking complete
    sample({
      clock: this.markLessonCompletedEvt,
      filter: ({ completed, nextLessonId }) =>
        completed && nextLessonId !== null,
      fn: ({ nextLessonId }) => nextLessonId!,
      target: this.setActiveLessonEvt,
    });

    // Call backend when lesson completion changes
    this.markLessonCompletedEvt.watch(({ lessonId, completed }) => {
      args.markLessonCompleted(lessonId, completed);
    });
  }
}
