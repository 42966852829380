import { useMutation } from "convex/react";
import { type Store } from "effector";
import { useUnit } from "effector-react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import {
  SomePreliveRoom,
  type CurrentRoom,
} from "shared/be/convex/Sessions/Rooms/Room.Types";
import { createContextAndHook, useOnce, useQueryStore } from "../util";

export function useOnEnterSession(p: { sessionId: Id<"sessionConfig"> }) {
  const [hasEnteredSession, setHasEnteredSession] = useState(false);
  const $myCurrentRoom = useQueryStore(
    api.Sessions.Base.SessionFns.getMyCurrentRoom,
    {
      baseSessionId: p.sessionId,
    }
  ).store;

  const sessionVM = useOnce(
    () => new SessionVM({ sessionId: p.sessionId, $myCurrentRoom })
  );

  const onEnterSession = useMutation(
    api.Sessions.Base.SessionFns.onEnterSession
  ).withOptimisticUpdate((localState, _) => {
    localState.setQuery(
      api.Sessions.Base.SessionFns.getMyCurrentRoom,
      {
        baseSessionId: p.sessionId,
      },
      SomePreliveRoom("ADMISSIONS_LOBBY")
    );
  });

  useEffect(() => {
    onEnterSession({ baseSessionId: p.sessionId }).then((r) => {
      console.log("R! ", r);
      setHasEnteredSession(true);
    });
  }, [p.sessionId]);

  return {
    sessionVM,
    hasEnteredSession,
  };
}

class SessionVM {
  $myCurrentRoom: Store<CurrentRoom | null>;

  constructor(
    readonly p: {
      sessionId: Id<"sessionConfig">;
      $myCurrentRoom: Store<CurrentRoom | null>;
    }
  ) {
    this.$myCurrentRoom = p.$myCurrentRoom;
  }
}

export const [SessionVMContext, useSessionVM] =
  createContextAndHook<SessionVM>();

export function useMySessionAndCurrentRoom() {
  const sessionVM = useSessionVM();
  const myCurrentRoom = useUnit(sessionVM.$myCurrentRoom);
  const sessionId = sessionVM.p.sessionId;
  return { sessionId, myCurrentRoom };
}
