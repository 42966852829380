import { ImageSrc } from "shared/types/miscellaneous.types";
import { FullContainerLoadingSpinner } from "./loading";

export const DefaultAvatar: React.FC<{ size?: number }> = ({ size }) => {
  const d = size ?? 45;
  return (
    <div
      className="bg-vid-black-200 rounded-full p-2 flex flex-col justify-center items-center "
      style={{
        width: d,
        height: d,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        fill="none"
        viewBox={`0 0 45 56`}
      >
        <path
          stroke="#AD99FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M22.5 23a9.375 9.375 0 100-18.75 9.375 9.375 0 100 18.75zM38.606 41.75c0-7.256-7.219-13.125-16.106-13.125-8.888 0-16.106 5.869-16.106 13.125"
        ></path>
      </svg>
    </div>
  );
};

export const AvatarCircle: React.FC<{
  mbProfilePhoto: ImageSrc | null;
  size?: number;
  padding?: number;
}> = ({ mbProfilePhoto, size = 30, padding = 0 }) => {
  if (mbProfilePhoto) {
    return (
      <div
        className="rounded-full object-cover bg-white flex items-center justify-center"
        style={{
          width: size,
          height: size,
          padding,
        }}
      >
        <img
          src={mbProfilePhoto?.src}
          alt="profile photo"
          className="rounded-full object-cover"
        />
      </div>
    );
  }
  return <DefaultAvatar size={size} />;
};

export const AvatarCircles: React.FC<{
  sources: (string | null)[];
  size?: number;
  padding?: number;
}> = ({ sources, size = 44, padding = 0 }) => {
  return (
    <div className="flex items-center gap-2 p-1">
      {sources.slice(0, 3).map((s, index) => (
        <div
          key={index}
          className={`rounded-full bg-white p-[2px] aspect-square ${index > 0 ? "-ml-6" : ""}`}
          style={{
            width: size!,
          }}
        >
          <AvatarCircle
            mbProfilePhoto={ImageSrc.fromMbUrl(s)}
            size={size! - 4}
            padding={padding}
          />
        </div>
      ))}
    </div>
  );
};

type ProfileImageSelectorProps = {
  currentMbProfile: ImageSrc | null;
  size?: number;
};

export const AvatarCirclesWithCountInfo: React.FC<{
  sources: (string | null)[];
  countText: string;
  size?: number;
}> = ({ sources, countText, size }) => {
  return (
    <div className="flex gap-2 pl-1 items-center bg-vid-black-100 rounded-full text-vid-black-800 cursor-pointer pr-4 whitespace-nowrap min-w-[140px]">
      <AvatarCircles sources={sources} size={size ?? 40} />
      <div className="text-sm leading-4 text-vid-black-800 font-robot-flex shrink-0">
        {countText}
      </div>
    </div>
  );
};

type ProfileImageViewProps = {
  isProcessingImage: boolean;
  locallyUploadedImage: ImageSrc | null;
  size?: number;
  onFileSelected: (file: File) => void;
};

export const ProfileImageView: React.FC<ProfileImageViewProps> = ({
  isProcessingImage,
  locallyUploadedImage,
  size,
  onFileSelected,
}) => {
  return (
    <div className="flex items-center justify-center">
      <label htmlFor="profileImage" className="cursor-pointer">
        <div className="relative">
          {isProcessingImage ? (
            <FullContainerLoadingSpinner />
          ) : (
            <AvatarCircle
              mbProfilePhoto={locallyUploadedImage}
              size={size ?? 125}
            />
          )}
          <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
            <img src={"/edit.svg"} alt="edit" />
          </div>
        </div>
        <input
          id="profileImage"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            if (file) {
              onFileSelected(file);
            }
          }}
        />
      </label>
    </div>
  );
};

export const MockAvatarCircles: React.FC<{
  size?: number;
}> = ({ size = 44 }) => {
  return (
    <AvatarCircles
      sources={[
        "/sample-practitioners/aiTestPerson2.jpeg",
        "/sample-practitioners/aiTestPerson3.jpeg",
        "/sample-practitioners/aiTestPerson4.jpeg",
      ]}
      size={size}
    />
  );
};
