import { MyGlobalProfilePage } from "@/src/pages/anon/dash/community/global-user-profile.page";
import { InvitePage } from "@pages/anon/invite.page";
import { OnboardLayout } from "@pages/onboard/onboard.layout";
import { type RouteObject } from "react-router-dom";
import { ANON_ROUTES, ROUTES } from "shared/routes/routes";
import { UROUTES } from "shared/routes/u.routes";
import { AnonDashLayout } from "../domains/anon/anon.dashboard";
import {
  AboutTab,
  DiscussionTab,
  EventsTab,
  ScheduleTab,
} from "../domains/communities/global-practitioner-dash";
import { MockBreakoutRoomsSimulator } from "../domains/sessions/rtc/rooms/breakout-rooms-dnd";
import { IndexLayout } from "../index.layout";
import { AuthinSuccessPage } from "../pages/auth/authin-success.page";
import { AuthinPage } from "../pages/auth/authin.page";
import { OAuthCallbackPage } from "../pages/auth/oauth-callback";
import { OnboardCompletePage } from "../pages/onboard/onboard-complete.page";
import { onboardRoutes } from "./onboard-route-views";
import { uRouteViews } from "./u-route-views";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <IndexLayout />,
    children: [
      {
        path: "mbreak",
        element: <MockBreakoutRoomsSimulator />,
      },
      {
        path: ROUTES.AUTH.SUCCESS.path,
        element: <AuthinSuccessPage />,
      },
      {
        path: ROUTES.AUTH.OAUTH_CALLBACK.path,
        element: <OAuthCallbackPage />,
      },
      {
        path: ROUTES.AUTH.AUTHIN.path,
        element: <OnboardLayout />,
        children: [
          {
            path: "",
            element: <AuthinPage />,
          },
        ],
      },
      {
        path: UROUTES.HP.ONBOARD_COMPLETE.relativePath,
        element: <OnboardCompletePage />,
      },
      onboardRoutes,
      uRouteViews,
      {
        path: ROUTES.INVITES.INVITE_ID.path,
        element: <InvitePage />,
      },
      {
        path: ANON_ROUTES.DASH.path,
        element: <AnonDashLayout />,
        children: [
          {
            path: ANON_ROUTES.DASH.COMMUNITY.MEMBERS.MEMBER_ID.DISCUSSION.path,
            element: <div>DISCUSSION YO!</div>,
          },
        ],
      },
      {
        children: [
          {
            children: [
              {
                path: ANON_ROUTES.MY_GLOBAL_PROFILE.path,
                element: <MyGlobalProfilePage />,
                children: [
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.DISCUSSION.path,
                    element: <DiscussionTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.EVENTS.path,
                    element: <EventsTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.ABOUT.path,
                    element: <AboutTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.SCHEDULE.path,
                    element: <ScheduleTab />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

console.log("ROUTES", routes);
