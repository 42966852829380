import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";

const TOP_TABS = [
  {
    title: "Account",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
    param: "account",
  },
  {
    title: "Clients And Scheduling",
    path:
      UROUTES.HP.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.path +
      "#reminders",
    param: "clients-and-scheduling",
  },
  {
    title: "Billing & Tax Documents",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING.path,
    param: "billing",
  },
];

type HpSettingsDashLayoutProps = {
  TopLevelTabLink: React.ComponentType<{
    to: string;
    isActive: boolean;
    children: React.ReactNode;
  }>;
};

export const HpSettingsDashLayout: React.FC<HpSettingsDashLayoutProps> = ({
  TopLevelTabLink,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActiveTab = useMemo(() => {
    return (
      TOP_TABS.find((tab) => pathname.includes(tab.param))?.param || "account"
    );
  }, [pathname]);

  useEffect(() => {
    if (pathname === UROUTES.HP.MY.DASHBOARD.SETTINGS.path) {
      navigate(
        UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
          {},
          undefined,
          "practice-info"
        )
      );
    }
  }, [pathname, navigate]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row pl-4 gap-28">
        {TOP_TABS.map((tab) => (
          <TopLevelTabLink
            key={tab.param}
            to={tab.path}
            isActive={isActiveTab === tab.param}
          >
            {tab.title}
          </TopLevelTabLink>
        ))}
      </div>
      <div className="flex-1 flex flex-col pt-8">
        <Outlet />
      </div>
    </div>
  );
};
