import { type StoreWritable } from "effector";
import { useUnit } from "effector-react";
import type { AuthMode } from "frontend-shared/src/onboard/onboard.types";
import { CheckCircle2, Eye, EyeOff, XCircle } from "lucide-react";
import { useState } from "react";
import { PrimaryButton } from "../../components/button";
import { LoadingSpinner } from "../../components/loading";

export const SigninWithGoogleButton: React.FC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => {
  return (
    <button className="w-[300px] google-button" onClick={onClick}>
      <img
        src="https://developers.google.com/identity/images/g-logo.png"
        alt="Google Logo"
        className="google-logo"
      />
      <div className="font-medium">{text}</div>
    </button>
  );
};

export const SigninWithAppleButton: React.FC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => {
  return (
    <button className="w-[300px] apple-button" onClick={onClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
        alt="Apple Logo"
        className="apple-logo"
      />
      <div className="font-medium">{text}</div>
    </button>
  );
};

type ResetPasswordMsg =
  | { tag: "SUCCESS"; message: string }
  | { tag: "ERROR"; message: string };

export const ResetPasswordMessage: React.FC<{
  message: ResetPasswordMsg;
}> = ({ message }) => {
  const isSuccess = message.tag === "SUCCESS";

  return (
    <div
      className={`flex items-center gap-2 px-4 py-2 rounded-lg self-center text-sm font-medium animate-fadeIn my-4 ${
        isSuccess
          ? "bg-community-green/10 text-community-green"
          : "bg-red-500/10 text-red-500"
      }`}
    >
      {isSuccess ? (
        <CheckCircle2 className="w-4 h-4" />
      ) : (
        <XCircle className="w-4 h-4" />
      )}
      {message.message}
    </div>
  );
};

export const EmailPasswordSignupFormView: React.FC<{
  $email: StoreWritable<string>;
  setEmail: (email: string) => void;
  $password: StoreWritable<string>;
  setPassword: (password: string) => void;
  $isLoading: StoreWritable<boolean>;
  onSubmit: () => void;
  $error: StoreWritable<string | null>;

  onResetPassword: () => void;
  $resetPasswordMsg: StoreWritable<ResetPasswordMsg | null>;

  authMode: AuthMode;
}> = ({
  $email,
  setEmail,
  $password,
  setPassword,
  $isLoading,
  authMode,
  onSubmit,
  onResetPassword,
  $resetPasswordMsg,
}) => {
  const [email] = useUnit([$email]);
  const [resetPasswordMsg] = useUnit([$resetPasswordMsg]);
  const [isLoading] = useUnit([$isLoading]);

  return (
    <form
      className="flex flex-col gap-2 max-w-screen"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <input
        type="email"
        placeholder="Email"
        className="text-input"
        id="email"
        value={email}
        autoComplete="email"
        autoCapitalize="none"
        autoCorrect="off"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <PasswordInput $password={$password} setPassword={setPassword} />
      {resetPasswordMsg ? (
        <ResetPasswordMessage message={resetPasswordMsg} />
      ) : null}
      {isLoading && (
        <div className="self-stretch flex justify-center items-center">
          <LoadingSpinner />
        </div>
      )}
      <PrimaryButton
        type="submit"
        title={authMode === "SIGNUP" ? "Signup" : "Signin"}
      />
      <button
        className="text-vid-black-700 hover:text-vid-purple transition-colors duration-200 text-sm self-center font-medium"
        onClick={onResetPassword}
      >
        Forgot password? Click here to reset
      </button>
    </form>
  );
};

const PasswordInput: React.FC<{
  $password: StoreWritable<string>;
  setPassword: (password: string) => void;
}> = ({ $password, setPassword }) => {
  const [password] = useUnit([$password]);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        className="text-input pr-10 w-full"
        id="password"
        value={password}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <button
        type="button"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <EyeOff className="h-4 w-4" />
        ) : (
          <Eye className="h-4 w-4" />
        )}
      </button>
    </div>
  );
};
