import { InputContainer } from "@/src/components/form/form-inputs.fc";
import { useMutation } from "convex/react";
import { Match } from "effect";
import { createEffect, StoreWritable, type Store } from "effector";
import { useUnit } from "effector-react";
import { createState, useQueryStore } from "frontend-shared/src/util";
import { useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { DraftBreakoutRoom } from "shared/be/convex/Sessions/Rooms/Room.Types";
import { isNullOrUndefined } from "shared/util";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { RadioButtons } from "web-shared/src/components/ui/radio-group";
import {
  useBreakoutRoomsVM,
  useSetupBreakoutRoomsFormVM,
  type BrSessionActivityInfo,
} from "../../activities/breakout-rooms/setup-breakout-rooms.vm";

export const SetupBreakoutRoomsToolsPanel: React.FC<{
  sessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}> = () => {
  const setup = useSetupBreakoutRoomsFormVM();

  if (setup === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <Loaded brSessionActivityInfo={setup} />;
};

const Loaded: React.FC<{
  brSessionActivityInfo: BrSessionActivityInfo;
}> = ({ brSessionActivityInfo }) => {
  const vm = useBreakoutRoomsVM({
    activityId: brSessionActivityInfo.activityId,
  });

  console.log(
    "RENDERING SETUP BREAKOUT ROOMS TOOLS PANEL",
    brSessionActivityInfo
  );

  return (
    <div className="flex-1 flex flex-col gap-4">
      <SetupBreakoutRoomsRightDrawer
        brSessionActivityInfo={brSessionActivityInfo}
      />
      {Match.value(brSessionActivityInfo.brSession.status).pipe(
        Match.when("DRAFT", () => (
          <PrimaryButton
            title="Create breakout rooms"
            onClick={() => vm.submit()}
          />
        )),
        Match.when("ACTIVE", () => (
          <InversePrimaryButton
            title="End breakout rooms"
            onClick={() => vm.cancel()}
          />
        )),
        Match.exhaustive
      )}
      <InversePrimaryButton title="Cancel" onClick={() => vm.cancel()} />
    </div>
  );
};

class SetupBreakoutRoomsVM {
  numGroups: Store<number | null>;
  isCustomGroupsVM = createState(false);
  customGroupsInputVM = createState<string>("");

  setNumberOfBreakoutRoomsFx = createEffect<number | null, void, Error>();

  constructor(p: {
    draftBreakoutRooms: StoreWritable<DraftBreakoutRoom[] | null>;
    setNumberOfBreakoutRoomsOnServer: (numBreakoutRooms: number | null) => void;
  }) {
    this.setNumberOfBreakoutRoomsFx.use(p.setNumberOfBreakoutRoomsOnServer);

    this.numGroups = p.draftBreakoutRooms.map((draftBreakoutRooms) => {
      console.log("draftBreakoutRooms!", draftBreakoutRooms);
      if (isNullOrUndefined(draftBreakoutRooms)) {
        return null;
      }
      return draftBreakoutRooms.length;
    });
  }

  setNumGroups = (numGroups: number | null) => {
    this.setNumberOfBreakoutRoomsFx(numGroups).catch();
  };
}

export const SetupBreakoutRoomsRightDrawer: React.FC<{
  brSessionActivityInfo: BrSessionActivityInfo;
}> = ({ brSessionActivityInfo }) => {
  const usersInRoomsVM = useQueryStore(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.getUsersInRooms,
    { activityId: brSessionActivityInfo.activityId }
  );

  const onSetNumberOfBreakoutRoomsClick = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns
      .onSetNumberOfBreakoutRoomsClick
  );

  const sendAllUsersToMainRoom = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns
      .sendAllUsersToMainRoom
  );

  const vm = useMemo(
    () =>
      new SetupBreakoutRoomsVM({
        draftBreakoutRooms: usersInRoomsVM.store,
        setNumberOfBreakoutRoomsOnServer: async (numBreakoutRooms) => {
          console.log("numBreakoutRooms!!", numBreakoutRooms);
          if (numBreakoutRooms !== null) {
            await onSetNumberOfBreakoutRoomsClick({
              activityId: brSessionActivityInfo.activityId,
              numBreakoutRooms,
            });
          }
        },
      }),
    [brSessionActivityInfo.activityId]
  );

  return (
    <div className="flex flex-col p-6 gap-6">
      <SetupBreakoutRoomsForm
        vm={vm}
        brSessionActivityInfo={brSessionActivityInfo}
        sendAllUsersToMainRoom={() =>
          sendAllUsersToMainRoom({
            activityId: brSessionActivityInfo.activityId,
          })
        }
      />
    </div>
  );
};

export const SetupBreakoutRoomsForm: React.FC<{
  vm: SetupBreakoutRoomsVM;
  brSessionActivityInfo: BrSessionActivityInfo;
  sendAllUsersToMainRoom: () => Promise<null>;
}> = ({ vm, sendAllUsersToMainRoom }) => {
  const [
    numGroups,
    isCustomGroups,
    setIsCustomGroups,
    customGroupsInput,
    setCustomGroupsInput,
  ] = useUnit([
    vm.numGroups,
    vm.isCustomGroupsVM.store,
    vm.isCustomGroupsVM.event,
    vm.customGroupsInputVM.store,
    vm.customGroupsInputVM.event,
  ]);

  const handleCustomInputChange = (value: string) => {
    setCustomGroupsInput(value);
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue > 0) {
      vm.setNumGroups(numValue);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <InputContainer label={{ text: "Number of groups", color: "black" }}>
        <div className="flex flex-col gap-6">
          <RadioButtons
            options={[
              {
                name: "2 groups",
                value: 2,
                icon: <CloudBeepIcon />,
              },
              { name: "3 groups", value: 3, icon: <CloudBeepIcon /> },
              { name: "4 groups", value: 4, icon: <CloudBeepIcon /> },
              { name: "Custom", value: "custom", icon: <CloudBeepIcon /> },
            ]}
            value={isCustomGroups ? "custom" : numGroups}
            onChange={(value) => {
              console.log("value!!", value);
              if (value === "custom") {
                setIsCustomGroups(true);
                vm.setNumGroups(null);
              } else {
                setIsCustomGroups(false);
                vm.setNumGroups(parseInt(value as string));
              }
            }}
          />
          {isCustomGroups && (
            <div className="flex flex-col gap-2">
              <input
                type="number"
                min="1"
                value={customGroupsInput}
                onChange={(e) => handleCustomInputChange(e.target.value)}
                className="px-3 py-2 border rounded-md"
                placeholder="Enter number of groups"
              />
            </div>
          )}
          <InversePrimaryButton
            onClick={() => {}}
            title="Randomly assign"
            color="black"
            width="100%"
          />
          <InversePrimaryButton
            onClick={() => sendAllUsersToMainRoom()}
            title="Send all users to main room"
            color="black"
            width="100%"
          />
        </div>
      </InputContainer>
    </div>
  );
};

const CloudBeepIcon: React.FC = () => {
  return <img src={"/tools/cloud-beep-icon.svg"} />;
};
