import { Outlet, useNavigate } from "react-router-dom";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";

export const OnboardLayout: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col">
      <nav className="flex flex-row justify-between items-center border-b-2 px-8 py-2 shadow-sm">
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <InsightLiveLogoWithText />
        </div>
        <div className="flex flex-1" />
        {/* <PrimaryButton
          title="Login"
          onClick={() => {
            navigate(ROUTES.LOGIN.path);
          }}
        /> */}
        {/* <Link
          to="/login"
          className="inline-flex justify-end items-center gap-2.5 py-[15px] px-9 rounded-buttons border border-vid-purple bg-vid-purple text-white text-md transform -rotate-[0.485deg]"
        >
          Login
        </Link> */}
      </nav>
      <div className="flex-1 flex flex-col bg-vid-purple items-center py-24">
        <Outlet />
      </div>
    </div>
  );
};
