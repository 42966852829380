import { format } from "date-fns";
import type { HcInvoiceDetailETO } from "shared/be/convex/Sessions/Invoice/Invoice.Types";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "../../components/avatar.tc";
import { SimpleTable } from "../../components/table";

export const InvoiceDetailView: React.FC<{ invoice: HcInvoiceDetailETO }> = ({
  invoice,
}) => {
  const { sessionInfo, from, to, issueDate } = invoice;
  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-2 gap-8 mb-8">
        <PersonInfo label="FROM" person={from} />
        <PersonInfo label="TO" person={to} />
      </div>

      <div className="grid grid-cols-3 gap-4 mb-8">
        <SessionInfoItem
          label="Issue date"
          value={format(issueDate, "MMM dd, yyyy")}
        />
        <SessionInfoItem label="Session number" value={sessionInfo.number} />
        <SessionInfoItem
          label="Session duration"
          value={sessionInfo.durationStr}
        />
      </div>

      <LineItemsView lineItems={invoice.lineItems} />
    </div>
  );
};

const PersonInfo = ({
  label,
  person,
}: {
  label: "FROM" | "TO";
  person: SimpleUserWithProfilePhoto;
}) => {
  return (
    <div className="flex items-center space-x-4 border rounded-lg p-4">
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(person.profilePhoto)}
        size={56}
      />
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500">{label}</div>
        <span className="text-[24px]">{person.name}</span>
      </div>
    </div>
  );
};

const SessionInfoItem = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => {
  return (
    <div className="p-4 border rounded-lg">
      <div className="text-vid-black-900 mb-1">{label}</div>
      <div className="font-medium text-[24px]">{value}</div>
    </div>
  );
};

const LineItemsView: React.FC<{
  lineItems: { description: string; amount: string }[];
}> = ({ lineItems }) => {
  return (
    <SimpleTable
      columns={[
        { key: "description", header: "Description" },
        { key: "amount", header: "Amount" },
      ]}
      data={lineItems}
    />
  );
};
