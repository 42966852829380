import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation } from "convex/react";
import { pipe } from "fp-ts/function";
import { useEffect, useInsertionEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RD } from "shared/base-prelude";
import { api } from "shared/be/convex/_generated/api";
import { isRight } from "shared/util";
import { useToastVM, useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const RegisterNewClientForm: React.FC = () => {
  const { register, handleSubmit } = useForm<{ email: string }>();
  const webDisplayVM = useWebGlobalDisplayVM();
  const [submitResult, setSubmitResult] = useState<
    RD.RemoteData<unknown, { msg: string }>
  >(RD.initial);

  const inviteClientFn = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.handleSubmitAddClient
  );

  return (
    <div className="py-16 flex flex-col mt-8 gap-8">
      <form
        className="flex flex-col gap-8"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setSubmitResult(RD.pending);

          inviteClientFn({
            clientEmail: data.email,
            autoApproveIfClientExists: true,
          }).then((er) => {
            if (isRight(er)) {
              webDisplayVM.globalSheetVM.closeSheet();

              setTimeout(() => {
                webDisplayVM.toastVM.showToast({
                  id: "register-new-client-form",
                  title: er.right.msg,
                  duration: 4000,
                  action: {
                    label: "Ok",
                    onClick: () => {},
                  },
                });
              }, 300);
            }
          });
        })}
      >
        <h5>
          {`Enter your client's email address. We'll send them an email with a link to register and notify you when they approve.`}
        </h5>
        <input
          type="email"
          placeholder="email"
          className="text-input"
          autoCapitalize="off"
          autoCorrect="off"
          {...register("email", { required: true })}
        />
        <button disabled={RD.isPending(submitResult)} className="btn-light">
          {pipe(
            submitResult,
            RD.fold(
              () => <div>Add client</div>,
              () => <FullContainerLoadingSpinner />,
              (e: unknown) => <div>{JSON.stringify(e)}</div>,
              () => <div>Successfly added client</div>
            )
          )}
        </button>
      </form>
      {RD.isFailure(submitResult) && (
        <div className="text-red-500">{JSON.stringify(submitResult.error)}</div>
      )}
    </div>
  );
};
