import React from "react";

export type QuizQuestion = {
  question: string;
  options: string[];
  correctOptionIndex: number;
};

export const Quiz: React.FC<{
  questions: QuizQuestion[];
}> = ({ questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = React.useState<Set<number>>(
    new Set()
  );

  const currentQuestion = questions[currentQuestionIndex];

  const toggleOption = (optionIndex: number) => {
    const newSelected = new Set(selectedOptions);
    if (newSelected.has(optionIndex)) {
      newSelected.delete(optionIndex);
    } else {
      newSelected.clear();
      newSelected.add(optionIndex);
    }
    setSelectedOptions(newSelected);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOptions(new Set());
    }
  };

  return (
    <div className="max-w-4xl">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-vid-black-900 mb-2">
          {currentQuestion.question}
        </h2>
      </div>

      <div className="flex flex-col gap-3">
        {currentQuestion.options.map((option, index) => (
          <button
            key={index}
            onClick={() => toggleOption(index)}
            className={`flex items-center p-4 border rounded-lg transition-colors ${
              selectedOptions.has(index)
                ? "border-vid-purple bg-vid-purple/5"
                : "border-vid-black-200 hover:border-vid-black-300"
            }`}
          >
            <div className="w-8 h-8 flex items-center justify-center mr-4">
              <div
                className={`w-6 h-6 rounded border ${
                  selectedOptions.has(index)
                    ? "border-vid-purple bg-vid-purple"
                    : "border-vid-black-300"
                }`}
              >
                {selectedOptions.has(index) && (
                  <svg
                    className="w-4 h-4 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <span className="text-vid-black-400 mr-3">{index + 1}</span>
              <span className="text-vid-black-900">{option}</span>
            </div>
          </button>
        ))}
      </div>

      {currentQuestionIndex < questions.length - 1 && (
        <button
          onClick={nextQuestion}
          className="mt-6 px-6 py-2 bg-vid-purple text-white rounded-lg hover:bg-darkish-purple"
        >
          Next Question
        </button>
      )}
    </div>
  );
};
