// useAppSession.ts
import { useMutation } from "convex/react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";

const UPDATE_LAST_ACTIVE_INTERVAL_MS = 120 * 1000; // 2 minutes

export function useAppSession({ platform }: { platform: "WEB" | "MOBILE" }) {
  const startSession = useMutation(api.AppSessions.AppSessionFns.startSession);
  const updateActivity = useMutation(
    api.AppSessions.AppSessionFns.updateSessionActivity
  );

  const [sessionId, setSessionId] = useState<Id<"userAppSessions"> | null>(
    null
  );

  useEffect(() => {
    if (!sessionId) return;

    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      updateActivity({ sessionId, now }).catch((error) => {
        console.error("Failed to update session activity:", error);
      });
    }, UPDATE_LAST_ACTIVE_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [updateActivity, sessionId]);

  useEffect(() => {
    async function initSession() {
      const session = await startSession({
        platform,
      });
      setSessionId(session);
    }

    initSession();
  }, [platform]);
}
