import { useConvex } from "convex/react";
import { pipe } from "fp-ts/lib/function";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { TE } from "shared/base-prelude";
import { api } from "shared/be/convex/_generated/api";
import { FullCalendar } from "web-shared/src/domains/scheduling/calendar/full-calendar";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const CpDashboardCalendarPage = () => {
  const webDisplayVM = useWebGlobalDisplayVM();
  const webConvexReact = useConvex();
  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr({
        fetchApptsTE: (dayInFocus) =>
          pipe(
            TE.fromTask(() =>
              webConvexReact.query(
                api.Screens.Hp.Dashboard.CalendarScreenFns // TODO: Make for cp endpoint
                  .getMyNearbyMonthCalendarAppts,
                {
                  now: dayInFocus.getTime(),
                }
              )
            ),
            TE.chainFirst((r) =>
              TE.fromIO(() => {
                console.log("fetchApptsTE result", r);
              })
            )
          ),
      })
  );
  return (
    <FullCalendar
      stateMgr={stateMgr}
      fetchOnMount
      onApptClick={(appt) => {
        console.log("appt clicked", appt);
      }}
      onDayClick={(day) => {
        console.log(day);
      }}
      newApptButton={{
        onClick: () => {
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "New appointment",
              type: {
                tag: "create",
              },
            },
            <div>TODO: Schedule an appointment</div>
          );
        },
        title: "New appointment",
      }}
    />
  );
};
