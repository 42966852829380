import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useQuery } from "convex/react";
import React from "react";
import { api } from "shared/be/convex/_generated/api";

export const HpMyDashboardInvoicesPage: React.FC = () => {
  const myInvoices = useQuery(
    api.Screens.Hp.Dashboard.InvoiceScreenFns.getMyInvoices
  );

  if (myInvoices === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col h-full">
      <EmptyState />
      <DisclaimerFooter />
    </div>
  );
};

const EmptyState: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h1 className="font-outfit font-semibold text-2xl text-center lg:text-4xl text-default-purple leading-5 mb-4">
        You don't have any invoices yet
      </h1>
      <p className="font-sans font-light text-xs lg:text-base text-center">
        Invoices will appear here automatically after your sessions.
      </p>
    </div>
  );
};

const DisclaimerFooter: React.FC = () => {
  return (
    <div className="w-full hidden lg:flex justify-center">
      <div className="w-fit flex text-xs justify-center items-center rounded-[10px] bg-vid-black-100 p-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
            stroke="#5500C2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16V11"
            stroke="#5500C2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0059 8H11.9969"
            stroke="#5500C2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="mx-2 text-[#3A3A3A]">
          Sessions with clients are protected with our auto-pay system. Once a
          session ends your client they will be automatically billed.
        </p>
        <p className="hover:cursor-pointer font-semibold text-default-purple">
          Learn more
        </p>
      </div>
    </div>
  );
};
