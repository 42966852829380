import type { DragEndEvent } from "@dnd-kit/core";
import { useMutation } from "convex/react";
import {
  combine,
  createEffect,
  createEvent,
  type Store,
  type StoreWritable,
} from "effector";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { createState, useOnce, useQueryStore } from "frontend-shared/src/util";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { DraftBreakoutRoom } from "shared/be/convex/Sessions/Rooms/Room.Types";
import { isNullOrUndefined } from "shared/util";

export interface BrSessionActivityInfo {
  activityId: Id<"rtcRoomActivity">;
  brSession: {
    _id: Id<"breakoutRoomSessions">;
    status: "DRAFT" | "ACTIVE";
  };
}

export function useSetupBreakoutRoomsFormVM():
  | BrSessionActivityInfo
  | undefined {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const [brSessionActivityInfo, setBrSessionActivityInfo] = useState<
    BrSessionActivityInfo | undefined
  >(undefined);

  const getOrCreateNew = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns
      .getOrCreateNewSetupBreakoutRoomsActivity
  );

  useEffect(() => {
    getOrCreateNew({
      baseSessionId,
      roomId,
    }).then(({ activityId, brSession }) => {
      setBrSessionActivityInfo({ activityId, brSession });
    });
  }, [baseSessionId, roomId]);

  return brSessionActivityInfo;
}

export function useBreakoutRoomsVM(p: {
  activityId: Id<"rtcRoomActivity">;
}): SetupBreakoutRoomsVM {
  const { baseSessionId } = useLiveRoomVM();

  const usersToAssign = useQueryStore(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.getUsersToAssign,
    {
      activityId: p.activityId,
    }
  );

  const usersInRooms = useQueryStore(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.getUsersInRooms,
    {
      activityId: p.activityId,
    }
  );

  console.log("RUNNING useBreakoutRoomsVM", p.activityId);

  const setUsersInRooms = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.setUsersInRooms
  ).withOptimisticUpdate((localStore, args) => {
    const curUsersInRooms = localStore.getQuery(
      api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.getUsersInRooms,
      {
        activityId: p.activityId,
      }
    );

    if (isNullOrUndefined(curUsersInRooms)) return;

    const newUsersInRooms = curUsersInRooms.map((room) => {
      const found = args.usersInRooms.find(
        (u) => u.brSessionRoomId === room.brSessionRoomId
      );
      if (found) {
        return { ...room, users: found.users };
      }
      return room;
    });

    localStore.setQuery(
      api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.getUsersInRooms,
      {
        activityId: p.activityId,
      },
      newUsersInRooms
    );
  });

  const onSetNumberOfBreakoutRooms = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns
      .onSetNumberOfBreakoutRoomsClick
  );

  const submit = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns.onSubmitSetup
  );

  const endActivity = useMutation(
    api.Sessions.Rooms.Activity.SetupBreakoutRoomsActivityFns
      .onEndSetupBreakoutRooms
  );

  const vm = useOnce(
    () =>
      new SetupBreakoutRoomsVM({
        usersToAssign: usersToAssign.store,
        usersInRooms: usersInRooms.store,
        setNumberOfBreakoutRooms: (numBreakoutRooms) =>
          onSetNumberOfBreakoutRooms({
            activityId: p.activityId,
            numBreakoutRooms,
          }),
        setUsersInRoomsOnServer: (usersInRooms) =>
          setUsersInRooms({
            usersInRooms,
            activityId: p.activityId,
          }),
        cancel: async () => {
          await endActivity({ activityId: p.activityId });
        },
        submit: () =>
          submit({
            baseSessionId,
            activityId: p.activityId,
          }),
      })
  );

  return vm;
}

class SetupBreakoutRoomsVM {
  $usersInRooms: StoreWritable<DraftBreakoutRoom[] | null>;
  $usersToAssign: StoreWritable<Id<"users">[] | null>;
  $unassignedUsers: Store<Id<"users">[] | null>;
  $activeUser = createState<Id<"users"> | null>(null);

  dragEndEvt = createEvent<DragEndEvent>();

  submitFx = createEffect<void, void>();
  cancelFx = createEffect<void, void>();
  setUsersInRoomsFx = createEffect<
    { brSessionRoomId: string; users: Id<"users">[] }[],
    void
  >();
  setNumberOfBreakoutRoomsFx = createEffect<number, null>();

  moveUserToUnassigned = createEvent<Id<"users">>();
  moveUserToRoom = createEvent<{ userId: Id<"users">; roomId: string }>();

  constructor(args: {
    usersToAssign: StoreWritable<Id<"users">[] | null>;
    usersInRooms: StoreWritable<DraftBreakoutRoom[] | null>;
    setNumberOfBreakoutRooms: (numBreakoutRooms: number) => Promise<null>;
    setUsersInRoomsOnServer: (
      usersInRooms: { brSessionRoomId: string; users: Id<"users">[] }[]
    ) => void;
    submit: () => Promise<Id<"rtcLiveRooms">[]>;
    cancel: () => Promise<void>;
  }) {
    this.$usersInRooms = args.usersInRooms;
    this.$usersToAssign = args.usersToAssign;
    this.setUsersInRoomsFx.use(args.setUsersInRoomsOnServer);
    this.setNumberOfBreakoutRoomsFx.use(args.setNumberOfBreakoutRooms);
    this.submitFx.use(async () => {
      await args.submit();
    });

    this.cancelFx.use(async () => {
      await args.cancel();
    });

    this.$unassignedUsers = combine(
      this.$usersToAssign,
      this.$usersInRooms,
      (usersToAssign, usersInRooms) => {
        if (!usersToAssign || !usersInRooms) return [];

        const assignedUsers = new Set(
          usersInRooms.flatMap((room) => room.users)
        );

        return usersToAssign.filter((userId) => !assignedUsers.has(userId));
      }
    );

    this.moveUserToRoom.watch(({ userId, roomId }) => {
      const usersInRooms = this.$usersInRooms.getState();

      console.log("MOVE USER TO ROOM", userId, roomId, usersInRooms);
      if (!usersInRooms) return;

      // First, remove the user from all rooms
      const cleanedRooms = usersInRooms.map((room) => ({
        ...room,
        users: room.users.filter((id) => id !== userId),
      }));

      // Then add the user to the target room
      const newUsersInRooms = cleanedRooms.map((room) => {
        if (room.brSessionRoomId === roomId) {
          return { brSessionRoomId: roomId, users: [...room.users, userId] };
        }
        return { brSessionRoomId: room.brSessionRoomId, users: room.users };
      });

      this.setUsersInRoomsFx(newUsersInRooms);
    });
  }

  setActiveUser(user: Id<"users"> | null) {
    this.$activeUser.event(user);
  }

  submit() {
    this.submitFx();
  }

  cancel() {
    this.cancelFx();
  }
}
