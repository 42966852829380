import { pipe } from "fp-ts/function";
import { useSetupCalendarVM } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import React, { useEffect } from "react";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Arr } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";
import { NewHCSessionForm } from "src/domains/sessions/hc-session/schedule-hc-session.form";
import { FullCalendar } from "web-shared/src/domains/scheduling/calendar/full-calendar";
import { O } from "web-shared/src/prelude";
import { useUMgr, useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { useHpState } from "../../hp.webstatemgr";

export const HpDashboardCalendarPage: React.FC = () => {
  const queryParams = useTypedSearchParams(UROUTES.HP.MY.DASHBOARD.CALENDAR);
  const hpState = useHpState();
  const uMgr = useUMgr();
  const webDisplayVM = useWebGlobalDisplayVM();
  const { vm } = useSetupCalendarVM();

  useEffect(() => {
    if (queryParams.length > 0) {
      const [{ action, clientId, clientName, requestApptId }] = queryParams;
      console.log("queryParams", action);

      switch (action) {
        case "new-appointment":
          const client = pipe(
            [clientId, clientName],
            Arr.map(O.fromNullable),
            Arr.sequence(O.option),
            O.map(([clientId, clientName]) => ({
              id: clientId,
              name: clientName,
            })),
            O.toUndefined
          );
          // webDisplayVM.globalSheetVM.openSheet(
          //   {
          //     title: "New appointment",
          //     type: { tag: "create" },
          //   },
          //   <NewHCSessionForm />
          // );
          break;
        case "view-appointment":
          console.log("view-appointment", requestApptId);
          break;
        default:
          break;
      }
    }
  }, [queryParams]);

  return (
    <div className="flex-1 flex flex-col max-w-[1100px]">
      <FullCalendar
        stateMgr={vm}
        fetchOnMount
        onDayClick={(day) => {
          console.log(day);
        }}
        onApptClick={(appt) => {
          console.log("appt clicked", appt);
        }}
        newApptButton={{
          onClick: () => {
            webDisplayVM.globalSheetVM.openSheet(
              {
                title: "New appointment",
                type: { tag: "create" },
              },
              <NewHCSessionForm
                onSuccessSubmit={() => {
                  hpState.dashboardState.showBottomToast({
                    msg: "Session scheduled successfully",
                    reload: true,
                    closeRightNav: true,
                  });
                  webDisplayVM.globalSheetVM.closeSheet();
                }}
              />
            );
          },
          title: "New appointment",
        }}
      />
    </div>
  );
};
