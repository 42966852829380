import { Input } from "web-shared/src/components/ui/input";
import { useMutation, useQuery } from "convex/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { Doc } from "shared/be/convex/_generated/dataModel";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { InfoSection } from "web-shared/src/components/info-section";
import { Alert, AlertDescription } from "web-shared/src/components/ui/alert";
import { Card, CardContent } from "web-shared/src/components/ui/card";
import { Switch } from "web-shared/src/components/ui/switch";
import { useUMgr } from "web-shared/src/web-context";

export const CommunityAdminDashboardPage: React.FC = () => {
  const { communitySlug } = useParams<{ communitySlug: string }>();
  const updatePricing = useMutation(
    api.Community.CommunityPricingFns.updateCommunityPricing
  );
  const community = useQuery(api.Community.CommunityFns.getCommunityInfo, {
    communitySlug: communitySlug!,
  });

  const handleSave = async (data: {
    isPaidEnabled: boolean;
    monthlyPrice: number;
  }) => {
    await updatePricing({
      communitySlug: communitySlug!,
      enabled: data.isPaidEnabled,
      monthlyPrice: data.monthlyPrice,
    });
  };

  if (community === undefined) return null;

  return (
    <div className="flex flex-col gap-6 p-8 w-[60%]">
      <CommunityPricingSetup
        communityName={community.name}
        pricingConfig={community.pricingConfig}
        onSave={handleSave}
      />
    </div>
  );
};

const CommunityPricingSetup: React.FC<{
  communityName: string;
  pricingConfig: Doc<"communityInfo">["pricingConfig"];
  onSave: (data: {
    isPaidEnabled: boolean;
    monthlyPrice: number;
  }) => Promise<void>;
}> = ({ communityName, pricingConfig, onSave }) => {
  const uMgr = useUMgr();
  const [isPaidEnabled, setIsPaidEnabled] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  // Platform fee percentage
  const PLATFORM_FEE = 0.0; // 10%

  useEffect(() => {
    if (pricingConfig) {
      setIsPaidEnabled(pricingConfig.enabled);
      const monthlyPriceInDollars = pricingConfig.monthlyPriceInCents / 100;
      setMonthlyPrice(monthlyPriceInDollars.toFixed(2));
    }
  }, [pricingConfig]);

  const calculateEstimatedEarnings = (price: string) => {
    if (!price) return { monthly: 0, yearly: 0 };
    const priceNum = parseFloat(price);
    const monthlyAfterFees = priceNum * (1 - PLATFORM_FEE);
    return {
      monthly: monthlyAfterFees,
      yearly: monthlyAfterFees * 12,
    };
  };

  const earnings = calculateEstimatedEarnings(monthlyPrice);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave({
        isPaidEnabled,
        monthlyPrice: parseFloat(monthlyPrice),
      });
    } finally {
      setIsSaving(false);
      uMgr.toastVM.showToastEvt({
        id: "pricing-updated",
        title: "Pricing updated",
        description: "Your pricing has been updated",
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <InfoSection
        header={{
          _tag: "STANDARD",
          title: "Community Payment Settings",
        }}
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <h3 className="font-medium text-base">Enable Paid Membership</h3>
              <p className="text-sm text-gray-500">
                Require payment to join {communityName}
              </p>
            </div>
            <Switch
              checked={isPaidEnabled}
              onCheckedChange={setIsPaidEnabled}
            />
          </div>
          {isPaidEnabled && (
            <>
              <div className="flex flex-col gap-4 pt-4 border-t border-gray-100">
                <div className="flex flex-col gap-2">
                  <label className="text-sm font-medium">
                    Monthly Membership Price
                  </label>
                  <div className="flex gap-2 items-center">
                    <div className="relative">
                      <span className="absolute left-3 top-2 text-gray-500">
                        $
                      </span>
                      <Input
                        type="number"
                        min="0"
                        step="0.01"
                        value={monthlyPrice}
                        onChange={(e) => setMonthlyPrice(e.target.value)}
                        className="pl-8 w-32"
                        placeholder="0.00"
                      />
                    </div>
                    <span className="text-sm text-gray-500">per month</span>
                  </div>
                </div>

                <Card className="bg-gray-50">
                  <CardContent className="pt-6">
                    <h4 className="font-medium mb-4">Estimated Earnings</h4>
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-between items-center">
                        <span className="text-sm te text-gray-600">
                          Monthly earnings after fees
                        </span>
                        <span className="font-medium">
                          ${earnings.monthly.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">
                          Yearly earnings after fees
                        </span>
                        <span className="font-medium">
                          ${earnings.yearly.toFixed(2)}
                        </span>
                      </div>
                      <div className="text-xs text-gray-500 mt-2">
                        Platform fee: {PLATFORM_FEE * 100}%
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Alert className="mt-2">
                  <AlertDescription>
                    Members will be charged ${monthlyPrice || "0.00"} monthly to
                    access your community. You'll receive{" "}
                    {(1 - PLATFORM_FEE) * 100}% of each payment.
                  </AlertDescription>
                </Alert>
              </div>

              <div className="flex justify-end gap-3 pt-4">
                <InversePrimaryButton
                  onClick={() => {
                    setIsPaidEnabled(false);
                    setMonthlyPrice("");
                  }}
                  title="Cancel"
                />
                <PrimaryButton
                  title={isSaving ? "Saving..." : "Save Changes"}
                  onClick={handleSave}
                  disabled={!monthlyPrice || isSaving}
                  isLoading={isSaving}
                />
              </div>
            </>
          )}
        </div>
      </InfoSection>
    </div>
  );
};
