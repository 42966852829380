import { useQuery } from "convex/react";
import { Match } from "effect";
import { useUnit } from "effector-react";
import { useObservableEagerState } from "observable-hooks";
import * as Rx from "rxjs";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { ChatRoomSimple } from "shared/be/convex/Chat/Chat.Types";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { cn } from "../../utils/utils";
import { ChatRoom } from "./chat-room";
import type { GlobalMessengerVM } from "./messenger.vm";

export const Messenger: React.FC<{
  vm: GlobalMessengerVM;
  onRoomClick: (roomId: Id<"chatRooms">) => void;
  roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
}> = ({ vm, onRoomClick, roomsIBelongTo$ }) => {
  const state = useUnit(vm.$openState);

  const containerClasses = cn(
    "fixed bottom-0 right-4 bg-white rounded-lg shadow-lg transition-all duration-300 z-50",
    {
      "h-[597px] w-[345px] translate-y-0": state === "OPEN",
      "h-[88px] w-[345px] translate-y-0": state === "TOP_TEASE",
      "translate-y-full opacity-0": state === "HIDDEN",
    }
  );

  return (
    <div
      className={`${containerClasses} transform transition-transform duration-300 ease-out`}
    >
      <div className="flex flex-col h-full">
        <header className="flex items-center justify-between p-4 h-[88px] border-b bg-vid-purple text-white">
          <h2 className="font-semibold">Chat Messenger</h2>
          <div className="flex gap-2">
            <button
              onClick={() =>
                vm.setOpenStateEvt(state === "OPEN" ? "TOP_TEASE" : "OPEN")
              }
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              {state === "OPEN" ? "−" : "+"}
            </button>
            <button
              onClick={() => vm.setOpenStateEvt("HIDDEN")}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              ×
            </button>
          </div>
        </header>

        {state === "OPEN" && (
          <div className="flex flex-col flex-1 p-4">
            <OpenMessengerView
              vm={vm}
              roomsIBelongTo$={roomsIBelongTo$}
              onRoomClick={onRoomClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const OpenMessengerView: React.FC<{
  vm: GlobalMessengerVM;
  roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
  onRoomClick: (roomId: Id<"chatRooms">) => void;
}> = ({ vm, roomsIBelongTo$, onRoomClick }) => {
  const viewState = useUnit(vm.$viewState);

  return Match.value(viewState).pipe(
    Match.when({ _tag: "ALL_CHATS_LIST" }, () => (
      <MessengerRoomsList
        roomsIBelongTo$={roomsIBelongTo$}
        onRoomClick={onRoomClick}
      />
    )),
    Match.when({ _tag: "CHAT_ROOM" }, ({ roomId }) => (
      <ChatRoom roomId={roomId} />
    )),
    Match.exhaustive
  );
};

const MessengerRoomsList: React.FC<{
  roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
  onRoomClick: (roomId: Id<"chatRooms">) => void;
}> = ({ roomsIBelongTo$, onRoomClick }) => {
  const roomsIBelongTo = useObservableEagerState(roomsIBelongTo$);

  if (roomsIBelongTo === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-2">
      {roomsIBelongTo.map((room) => (
        <RoomTilePreview room={room} onClick={() => onRoomClick(room.id)} />
      ))}
    </div>
  );
};

const RoomTilePreview: React.FC<{
  room: ChatRoomSimple;
  onClick: () => void;
}> = ({ room, onClick }) => {
  const lastUnreadMessage = useQuery(
    api.Chat.ChatRoomFns.getLatestRoomMessage,
    {
      roomId: room.id,
    }
  );

  return (
    <div
      className="flex items-center gap-2 border-b p-4 cursor-pointer"
      onClick={() => {
        onClick();
      }}
    >
      <div className="w-10 h-10 bg-vid-purple rounded-full"></div>
      <div className="flex flex-col gap-1">
        <div className="font-medium">{room.name}</div>
        {lastUnreadMessage && (
          <div className="text-sm">{lastUnreadMessage.content}</div>
        )}
      </div>
    </div>
  );
};
