import { useAuthActions } from "@convex-dev/auth/react";
import { PortalWebStateMgr } from "@pages/u/hp/hp.webstatemgr";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { DashboardLayout } from "web-shared/src/components/dashboard/dashboard-layout";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";
import { useUMgr } from "web-shared/src/web-context";

export const CpDashboardLayout: React.FC<{}> = () => {
  const { signOut } = useAuthActions();
  const uMgr = useUMgr();
  const stateMgr = useMemo(() => new PortalWebStateMgr(uMgr), [uMgr]);
  const location = useLocation();

  return (
    <DashboardLayout
      stateMgr={stateMgr.dashboardState}
      leftMenu={{
        logoWithText: <InsightLiveLogoWithText />,
        onLogoClick: () => {
          window.location.href = UROUTES.CP.DASHBOARD.HOME.path;
        },
        topLinks: [
          {
            name: "Home",
            to: UROUTES.CP.DASHBOARD.HOME.path,
          },
          {
            name: "Calendar",
            to: UROUTES.CP.DASHBOARD.CALENDAR.path,
          },
          {
            name: "Settings",
            to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          },
        ],
      }}
      navbar={{
        currentDashboardPage: getCurrentDashboardPageFromUrl(location.pathname),
        middleSection: <></>,
        hamburger: {
          links: [],
          onSignout: () => {
            signOut().then(() => {
              window.location.href = "/";
            });
          },
        },
        profileButton: {
          to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          profilePhoto: null,
        },
      }}
      mainContent={<Outlet />}
    />
  );
};

function getCurrentDashboardPageFromUrl(path: string): string {
  /*
  /cp/dashboard/settings => Settings
  /cp/dashboard/home => Home
  /cp/dashboard/portal/1 => Portal
  */

  const segments = path.split("/");
  const lastSeg = segments[segments.length - 1]; // Returns the last segment
  return lastSeg.charAt(0).toUpperCase() + lastSeg.slice(1);
}
