import { FullContainerLoadingSpinner } from "@/src/loading";
import { RxO, type Rx } from "web-shared/src/prelude";
import { Match } from "effect";
import { useUnit } from "effector-react";
import meditation from "frontend-shared/src/resources/meditation.mp3";
import metalClink from "frontend-shared/src/resources/metal-clink.mp3";
import { EmdrBallVisVM } from "frontend-shared/src/sessions/emdr/emdr-ball-vis.vm";
import {
  useKeyOfObservableAsState,
  useOnce,
  useQuery$,
} from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SoundType } from "shared/be/convex/Sessions/Emdr/Emdr.Types";
import type { EmdrSoundType } from "shared/be/convex/Sessions/Rooms/Activity/Activity.Types";
import { isNotNullOrUndefined } from "shared/util";
import { getColor, type ColorKey } from "shared/utils/color.utils";
import { useResizeObserver } from "usehooks-ts";

interface EmdrState {
  ballFrequency: number;
  ballColor: ColorKey;
  playState:
    | "playing"
    | "paused"
    | "preparing"
    | "finished"
    | "stopped"
    | "off";
  soundType: SoundType;
}

interface EMDRComponentProps {
  state$: Rx.Observable<EmdrState>;
  onPass?: () => void;
}

function mp3ForSoundType(soundType: SoundType) {
  console.log("mp3 for soundType", soundType);
  switch (soundType) {
    case "click":
      return metalClink;
    case "beep":
      return meditation;
    case "white-noise":
      return meditation;
    default:
      return meditation;
  }
}

export const EmdrBallRtcContent: React.FC<{
  roomId: Id<"rtcLiveRooms">;
}> = ({ roomId }) => {
  const curActivity$ = useQuery$(
    api.Sessions.Rooms.Activity.EmdrActivityFns.getCurEmdrActivity,
    {
      roomId,
    }
  );
  const curActivity = useObservableEagerState(curActivity$);

  useEffect(() => {
    console.log("curActivity", curActivity);
  }, [curActivity]);

  const state$ = useOnce(() =>
    curActivity$.pipe(
      RxO.filter(isNotNullOrUndefined),
      RxO.map(
        ({ activity, emdrConfig }) =>
          ({
            ballFrequency: emdrConfig.ballFrequency,
            ballColor: emdrConfig.ballColor as ColorKey,
            playState: Match.value(activity.playState).pipe(
              Match.when("playing", () => "playing" as const),
              Match.when("paused", () => "paused" as const),
              Match.when("preparing", () => "preparing" as const),
              Match.when("finished", () => "off" as const),
              Match.when("stopped", () => "off" as const),
              Match.when(null, () => "off" as const),
              Match.exhaustive
            ),
            soundType: emdrConfig.soundType as SoundType,
          }) satisfies EmdrState
      )
    )
  );

  if (curActivity === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <EMDRBallStageViewCode state$={state$} />;
};

export const EMDRBallStageViewCode: React.FC<EMDRComponentProps> = (props) => {
  const [passCount, setPassCount] = useState(0);

  return (
    <div className="w-full h-full overflow-hidden flex items-center justify-center relative rounded-[12px] border border-vid-black-800">
      <DotBackground
        className="absolute inset-0 z-0"
        dotSpacing={30}
        dotSize={2}
        dotColor="rgba(107, 107, 128, 0.2)"
        backgroundColor="#1a1625"
      />
      <div className="absolute top-4 left-4 z-20 text-white/80 font-medium">
        Passes: {passCount}
      </div>
      <div className="relative z-10 w-full h-full flex items-center justify-center">
        <EMDRBallVideoCode
          {...props}
          onPass={() => setPassCount((c) => c + 1)}
        />
      </div>
    </div>
  );
};

export const EMDRBallVideoCode: React.FC<EMDRComponentProps> = ({ state$ }) => {
  const frequency = useKeyOfObservableAsState(state$, "ballFrequency", 3);
  const color = useKeyOfObservableAsState(state$, "ballColor", "purple");
  const soundType = useKeyOfObservableAsState(
    state$,
    "soundType",
    "beep"
  ) as EmdrSoundType;

  const audioContext = useRef<AudioContext | null>(null);
  const audioBuffer = useRef<AudioBuffer | null>(null);

  const playSound = useCallback(
    (soundType: SoundType, gain: number, duration: number) => {
      if (!soundType || !audioContext.current || !audioBuffer.current) return;

      const source = audioContext.current.createBufferSource();
      source.buffer = audioBuffer.current;

      const gainNode = audioContext.current.createGain();
      source.connect(gainNode);
      gainNode.connect(audioContext.current.destination);

      gainNode.gain.setValueAtTime(gain, audioContext.current.currentTime);
      gainNode.gain.linearRampToValueAtTime(
        0,
        audioContext.current.currentTime + duration
      );

      source.start(0, 0.1);
      source.stop(audioContext.current.currentTime + duration);
    },
    []
  );

  const vm = useOnce(
    () =>
      new EmdrBallVisVM({
        playSound: (args) =>
          playSound(args.soundType, args.gain, args.soundDuration),
      })
  );
  const [position, isPlaying] = useUnit([vm.$position, vm.$isPlaying]);

  // Handle audio setup
  useEffect(() => {
    if (!soundType) return;
    vm.setSoundType(soundType);

    audioContext.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    loadAudio(soundType);

    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, [soundType]);

  useEffect(() => {
    state$.pipe(RxO.map((s) => s.playState)).subscribe((playState) => {
      console.log("SETTING VM PLAY STATE! ", playState);
      vm.setPlayState(playState === "playing");
    });

    state$.pipe(RxO.map((s) => s.ballFrequency)).subscribe((frequency) => {
      vm.setFrequency(frequency);
    });
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const size = useResizeObserver({ ref: containerRef });

  useEffect(() => {
    if (size.width) {
      vm.setContainerWidth(size.width);
    }
  }, [size.width]);

  const config = {
    ballSize: 24,
    containerWidth: size.width || 600,
    transitionDuration: 4000 / frequency,
  };

  const loadAudio = async (soundType: SoundType) => {
    console.log("LOADING AUDIO! ", soundType, audioContext.current);
    if (!audioContext.current) return;

    try {
      const mp3 = mp3ForSoundType(soundType);
      const response = await fetch(mp3);
      const arrayBuffer = await response.arrayBuffer();
      audioBuffer.current =
        await audioContext.current.decodeAudioData(arrayBuffer);
      console.log("AUDIO BUFFER! ", audioBuffer.current);
    } catch (error) {
      console.error("Failed to load audio:", error);
    }
  };

  return (
    <div
      className="w-full bg-vid-black-900 rounded-lg relative box-border"
      ref={containerRef}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <Ball
          {...config}
          size={config.ballSize}
          vm={vm}
          {...{ isPlaying, position, color: color as ColorKey }}
        />
      </div>
    </div>
  );
};

const Ball: React.FC<{
  vm: EmdrBallVisVM;
  size: number;
  color: ColorKey;
  containerWidth: number;
  isPlaying: boolean;
  transitionDuration: number;
}> = ({ vm, size, color, containerWidth, isPlaying, transitionDuration }) => {
  const position = useUnit(vm.$position);
  return (
    <div className="relative h-16" style={{ width: containerWidth }}>
      <div
        className="absolute top-1/2 rounded-full"
        style={{
          width: size,
          height: size,
          left: position,
          transform: "translateY(-50%)",
          transition: isPlaying
            ? "none"
            : `all ${transitionDuration}ms ease-in-out`,
          backgroundColor: getColor(color as ColorKey),
        }}
      />
    </div>
  );
};

const DotBackground = ({
  dotSpacing = 20,
  dotSize = 1,
  dotColor = "rgba(107, 107, 128, 0.2)",
  backgroundColor = "#1a1625",
  className = "",
}) => {
  return (
    <div className={`w-full h-full ${className}`}>
      <svg
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
      >
        <rect width="100%" height="100%" fill={backgroundColor} />
        <defs>
          <pattern
            id="dotPattern"
            x="0"
            y="0"
            width={dotSpacing}
            height={dotSpacing}
            patternUnits="userSpaceOnUse"
          >
            <circle
              cx={dotSpacing / 2}
              cy={dotSpacing / 2}
              r={dotSize}
              fill={dotColor}
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dotPattern)" />
      </svg>
    </div>
  );
};
