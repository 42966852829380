import type { ReactNode } from "react";
import { O, Rx } from "web-shared/src/prelude";

export type TopAlert = {
  msg: string;
  onClose?: () => void;
  actionContent?: React.ReactNode;
  durationSeconds?: number;
};

export interface RightNavBaseInfo {
  topView: ReactNode;
  content: ReactNode;
}

export type ShowBottomToastConfig = {
  msg: string;
  duration?: { _tag: "INFINITE" } | { _tag: "SECONDS"; seconds: number };
  reload?: boolean;
  onUndo?: () => void;
  confirmButton?: {
    label: string;
    onClick: () => void;
    autoCloseOnClick: boolean;
  };
  closeRightNav?: boolean;
};

export class DashboardStateMgr {
  showReloading$ = new Rx.BehaviorSubject(false);
  showTopAlert$ = new Rx.BehaviorSubject<O.Option<TopAlert>>(O.none);
  showBottomToast$ = new Rx.BehaviorSubject<O.Option<ShowBottomToastConfig>>(
    O.none
  );

  constructor() {}

  showTopAlert = (alert: TopAlert) => {
    this.showTopAlert$.next(O.some(alert));
  };

  hideTopAlert = () => {
    this.showTopAlert$.next(O.none);
  };

  setShowReloading = (show: boolean) => {
    this.showReloading$.next(show);
  };

  showBottomToast = (toast: ShowBottomToastConfig) => {
    this.showBottomToast$.next(O.some(toast));
  };

  hideBottomToast = () => {
    this.showBottomToast$.next(O.none);
  };
}
