import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import type { ConvexReactClient } from "convex/react";
import { Effect, Option } from "effect";
import { createEvent, createStore, sample } from "effector";
import { useAppSession } from "frontend-shared/src/app-sessions/use-app-session";
import { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { mkLiveRoomStreamContextAndHook } from "frontend-shared/src/sessions/live-room.vm";
import { ToastVM } from "frontend-shared/src/toasts/toast.vm";
import { useSetupMyProfileForm } from "frontend-shared/src/users/my-profile-form.vm";
import { createContextAndHook, createState } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { ChatRoomSimple } from "shared/be/convex/Chat/Chat.Types";
import type { InputProfileConfig } from "shared/be/convex/Onboard/Onboard.Types";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import {
  domSelectFile,
  FileUploadUtils,
} from "web-shared/src/utils/file.utils";
import { Rx } from "./prelude";
import { RightDrawerVM } from "./right-drawer.vm";
import { WebRtcMgr } from "./web-rtc.mgr";

class GlobalSearchCommandVM {
  clickSearchbarEvt = createEvent<void>();
  closeSearchEvt = createEvent<void>();

  $isSearching = createStore<boolean>(false);
  $searchInput = createState<string>("");

  constructor() {
    sample({
      clock: this.clickSearchbarEvt,
      fn: () => true,
      target: this.$isSearching,
    });

    sample({
      clock: this.closeSearchEvt,
      fn: () => false,
      target: this.$isSearching,
    });
  }
}

export type SheetType =
  | { tag: "edit" }
  | { tag: "create"; icon?: "profile-add" }
  | { tag: "view" }
  | { tag: "image"; imageUrl: string };

export type SheetConfig = {
  title: string;
  type: SheetType;
};

class SheetVM {
  openVM = createState<{
    config: SheetConfig;
    content: React.ReactNode;
  } | null>(null);

  openSheet(config: SheetConfig, content: React.ReactNode) {
    this.openVM.event({ config, content });
  }

  closeSheet() {
    this.openVM.event(null);
  }
}

export class WebGlobalDisplayVM {
  globalDialogVM = new DialogVM();
  globalSearchCommandVM = new GlobalSearchCommandVM();
  toastVM = new ToastVM();
  globalSheetVM = new SheetVM();

  dashreload$ = new Rx.Subject<void>();

  showToast(p: {
    id: string;
    title: string;
    duration: number;
    reload?: boolean;
  }) {
    this.toastVM.showToast(p);
    if (p.reload) {
      this.dashreload$.next();
    }
  }
}

export const [WebGlobalVmContext, useWebGlobalDisplayVM] =
  createContextAndHook<WebGlobalDisplayVM>();

export function useDialogVM() {
  const rootWebStateMgr = useWebGlobalDisplayVM();
  return rootWebStateMgr.globalDialogVM;
}

export function useToastVM() {
  const rootWebStateMgr = useWebGlobalDisplayVM();
  return rootWebStateMgr.toastVM;
}

export class UWebStateMgr extends UserStateMgr<
  Call,
  StreamVideoClient,
  WebRtcMgr
> {
  baseUserId: Id<"users">;

  rightDrawerVM = new RightDrawerVM();
  toastVM: ToastVM;

  constructor(p: {
    me: SimpleUserWithProfilePhoto;
    baseUserId: Id<"users">;
    convex: ConvexReactClient;
    toastVM: ToastVM;
    roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
    rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
  }) {
    console.log("P.ME!!! ", p.me);
    super({
      ...p,
      rtcCreds$: p.rtcCreds$,
      roomsIBelongTo$: p.roomsIBelongTo$,
      mkRtcMgr: (p) => new WebRtcMgr(p),
    });
    this.baseUserId = p.baseUserId;
    this.toastVM = p.toastVM;
  }
}

export const [UWebStateMgrContext, useUMgr] =
  createContextAndHook<UWebStateMgr>();

export function useMbRtcMgr(): WebRtcMgr | null {
  const uMgr = useUMgr();
  const rtcMgr = useObservableEagerState(uMgr.rtcMgr$);

  return rtcMgr;
}

export function useAssumedRtcMgr(): WebRtcMgr {
  const mbRtcMgr = useMbRtcMgr();

  if (mbRtcMgr === null) {
    throw new Error("No RTC MGR");
  }

  return mbRtcMgr;
}

export function useAssumedCurrentChannel(): Call {
  const rtcMgr = useAssumedRtcMgr();

  const mbChannelMgr = useObservableEagerState(rtcMgr.mbCurrentChannelMgr$);

  if (Option.isNone(mbChannelMgr)) {
    throw new Error("No current channel");
  }

  return mbChannelMgr.value;
}

export function useMe() {
  const uMgr = useUMgr();
  const me = useObservableEagerState(uMgr.me$);
  return me;
}

export function useMyId() {
  const uMgr = useUMgr();
  return uMgr.baseUserId;
}

export function useWebAppSession() {
  return useAppSession({
    platform: "WEB",
  });
}

export class DialogVM {
  openVM = createState<React.ReactNode | null>(null);

  openDialogue(node: React.ReactNode) {
    this.openVM.event(node);
  }

  closeDialogue() {
    this.openVM.event(null);
  }
}

export function useWebSetupMyProfileForm(p: {
  formConfig: InputProfileConfig;
}) {
  return useSetupMyProfileForm({
    formConfig: p.formConfig,
    selectPhoto: domSelectFile,
    uploadFileLikeToCloudinary: (file, uploadUrl) =>
      Effect.runPromise(
        FileUploadUtils.uploadFileLikeToCloudinary({
          secureUploadUrl: uploadUrl,
          file,
        })
      ),
  });
}

export const [LiveRoomStreamVMContext, useLiveRoomStreamVM] =
  mkLiveRoomStreamContextAndHook();
