import { useConvex, useQuery } from "convex/react";
import {
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
  type Store,
} from "effector";
import {
  createState,
  useOnce,
  type EffectorState,
} from "frontend-shared/src/util";
import { createContext, useContext, useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { AuthedDestination } from "shared/be/convex/User/User.Types";
import type { AuthMode, AuthSvcI, SigninResult } from "./onboard.types";

export function useGetMyInitialAuthedDestination():
  | AuthedDestination
  | undefined {
  const convex = useConvex();
  const [destination, setDestination] = useState<AuthedDestination | undefined>(
    undefined
  );

  useEffect(() => {
    console.log("GETTING MY DESTINATION");
    convex
      .query(api.User.UserFns.getMyAuthedDestination)
      .then((destination) => {
        console.log("GOT MY DESTINATION", destination);
        setDestination(destination);
      });
  }, []);

  return destination;
}

type OnboardStep =
  | { _tag: "AUTHENTICATE" }
  | { _tag: "DO_YOU_SEE_CLIENTS" }
  | { _tag: "INPUT_PROFILE_DATA"; viewState: InputProfileViewState };

export type InputProfileViewState =
  | { _tag: "MANUAL_ENTRY"; showPsychTodayUrlInput: boolean }
  | { _tag: "ENTER_PSYCH_TODAY_URL" };

export class OnboardAuthinVM {
  authinVM: AuthinVM;

  onboardStepVM = createState<OnboardStep>({ _tag: "AUTHENTICATE" });

  constructor(
    readonly p: {
      setEmailForLocalStorage: (email: string) => void;
      initialAuthMode: AuthMode;
      emailVerifyRedirectUrl: string;
    }
  ) {
    this.authinVM = new AuthinVM({
      setEmailForLocalStorage: p.setEmailForLocalStorage,
      initialAuthMode: p.initialAuthMode,
      emailVerifyRedirectUrl: p.emailVerifyRedirectUrl,
    });
  }
}

export const OnboardVMContext = createContext<OnboardAuthinVM | null>(null);

export function useOnboardVM() {
  const ctx = useContext(OnboardVMContext);
  if (!ctx) {
    throw new Error("OnboardVMContext not found");
  }
  return ctx;
}

export function useSetupAuthinVM(p: {
  setEmailForLocalStorage: (email: string) => void;
  emailVerifyRedirectUrl: string;
}) {
  const authinVM = useOnce(
    () =>
      new AuthinVM({
        initialAuthMode: "SIGNUP",
        emailVerifyRedirectUrl: p.emailVerifyRedirectUrl,
        setEmailForLocalStorage: p.setEmailForLocalStorage,
      })
  );
  return authinVM;
}

export function useSetupOnboardVM(p: {
  setEmailForLocalStorage: (email: string) => void;
  emailVerifyRedirectUrl: string;
}) {
  const vm = useOnce(
    () =>
      new OnboardAuthinVM({
        initialAuthMode: "SIGNUP",
        emailVerifyRedirectUrl: p.emailVerifyRedirectUrl,
        setEmailForLocalStorage: p.setEmailForLocalStorage,
      })
  );

  return vm;
}

export class AuthinVM {
  authModeVM: EffectorState<AuthMode>;

  registrationCompleteEvt = createEvent<void>();
  loginSuccessEvt = createEvent<void>();
  authinSuccessEvt = createEvent<void>();

  $errorMsg = createStore<string | null>(null);

  readonly $showEmailForm = createStore(false);
  readonly toggleEmailForm = createEvent<boolean>();

  constructor(
    readonly p: {
      setEmailForLocalStorage: (email: string) => void;
      initialAuthMode: AuthMode;
      emailVerifyRedirectUrl: string;
    }
  ) {
    this.authModeVM = createState(p.initialAuthMode);

    merge([this.loginSuccessEvt, this.registrationCompleteEvt]).watch(() => {
      console.log("WATCH AUTHIN SUCCESS FROM MERGE!");
      this.authinSuccessEvt();
    });

    this.$showEmailForm.on(this.toggleEmailForm, (_, show) => show);
  }
}

export type SigninWithEmailLinkMsg = { text: string; type: "error" | "info" };

export class SigninWithEmailLinkVM {
  clickSubmitSendEvt = createEvent();

  $msgToDisplay = createStore<SigninWithEmailLinkMsg | null>(null);

  emailVM = createState<string | null>(null);

  $isEmailValid: Store<boolean>;
  $isButtonDisabled: Store<boolean>;

  sendEmailLinkFx = createEffect<
    {
      email: string;
      setEmailForLocalStorage: (email: string) => void;
      redirectUrl: string;
    },
    void
  >();

  constructor(p: {
    setEmailForLocalStorage: (email: string) => void;
    redirectUrl: string;
    sendEmailLinkToSignin: (p: {
      email: string;
      setEmailForLocalStorage: (email: string) => void;
      redirectUrl: string;
    }) => Promise<void>;
  }) {
    this.sendEmailLinkFx.use(async (args) => {
      return p.sendEmailLinkToSignin(args);
    });

    this.$isEmailValid = this.emailVM.store.map(
      (email) => email !== null && email.includes("@")
    );

    this.$isButtonDisabled = this.$isEmailValid.map((isValid) => !isValid);

    sample({
      clock: this.clickSubmitSendEvt,
      source: this.emailVM.store,
      filter: (email) => {
        console.log("filter", email);
        return email !== null;
      },
      fn: (email) => ({
        email: email!,
        setEmailForLocalStorage: p.setEmailForLocalStorage,
        redirectUrl: p.redirectUrl,
      }),
      target: this.sendEmailLinkFx,
    });

    sample({
      clock: this.clickSubmitSendEvt,
      source: this.emailVM.store,

      filter: (email) => email === null,
      fn: () =>
        ({
          text: "Email is required",
          type: "error",
        }) satisfies SigninWithEmailLinkMsg,
      target: this.$msgToDisplay,
    });

    sample({
      clock: this.sendEmailLinkFx.doneData,
      source: this.emailVM.store,
      fn: (email) =>
        ({
          text: `Verification email sent to ${email}`,
          type: "info",
        }) satisfies SigninWithEmailLinkMsg,
      target: this.$msgToDisplay,
    });
  }
}

export class SigninWithGoogleVM {
  clickEvt = createEvent<void>();

  signinWithGoogleFx = createEffect<void, SigninResult>();

  constructor(p: { signinWithGoogle: () => Promise<SigninResult> }) {
    this.signinWithGoogleFx.use(async () => {
      return p.signinWithGoogle();
    });

    sample({
      clock: this.clickEvt,
      target: this.signinWithGoogleFx,
    });
  }
}

export class SigninWithAppleVM {
  clickEvt = createEvent<void>();

  signinWithAppleFx = createEffect<void, SigninResult>();

  constructor(readonly authSvcI: AuthSvcI) {
    this.signinWithAppleFx.use(async () => {
      return this.authSvcI.signinWithApple();
    });

    sample({
      clock: this.clickEvt,
      target: this.signinWithAppleFx,
    });
  }
}
