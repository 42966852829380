import { useMutation } from "convex/react";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { api } from "shared/be/convex/_generated/api";
import type { GroupBackstageQuickReview } from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { InversePrimaryButton } from "web-shared/src/components/button";
import { SimpleTable } from "web-shared/src/components/table";

export const BackstageGroupSession: React.FC<{
  groupBackstageInfo: GroupBackstageQuickReview;
}> = ({ groupBackstageInfo }) => {
  const { baseSessionId } = useLiveRoomVM();
  const inviteToSession = useMutation(
    api.Sessions.Base.SessionFns.onInviteUserToSessionClick
  );

  return (
    <div className="flex flex-col gap-4 max-w-[600px]">
      <SimpleTable
        columns={[
          {
            key: "invite",
            header: "Invite",
            render: (member: {
              userInfo: SimpleUser;
              hasBeenInvited: boolean;
            }) => (
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <AvatarCircle
                    mbProfilePhoto={ImageSrc.fromMbUrl(
                      member.userInfo.profilePhoto
                    )}
                    size={72}
                  />
                  <div className="text-[20px]">{member.userInfo.name}</div>
                </div>
                <InversePrimaryButton
                  icon={member.hasBeenInvited ? <PurpleCheck /> : undefined}
                  title={
                    member.hasBeenInvited ? "Invite sent" : "Invite to Session"
                  }
                  disabled={member.hasBeenInvited}
                  onClick={() => {
                    inviteToSession({
                      baseSessionId,
                      userId: member.userInfo.id,
                    });
                  }}
                />
              </div>
            ),
          },
        ]}
        data={groupBackstageInfo.membersToInvite}
        roundedTop
      />
    </div>
  );
};

const PurpleCheck: React.FC = () => (
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
    <path
      d="M19.1983 0.806737L19.1984 0.806796C19.3889 1.00699 19.5 1.2835 19.5 1.57665C19.5 1.86981 19.3889 2.14632 19.1984 2.34651L7.94932 14.1626L7.94928 14.1626C7.84586 14.2713 7.7244 14.3561 7.59254 14.4135C7.46075 14.4708 7.32042 14.5 7.17934 14.5C7.03826 14.5 6.89793 14.4708 6.76614 14.4135C6.63428 14.3561 6.51283 14.2713 6.4094 14.1626L6.40933 14.1626L0.820286 8.29285L0.82034 8.2928L0.814273 8.28664C0.717524 8.18849 0.639063 8.06974 0.584642 7.93667C0.530208 7.80356 0.50125 7.65956 0.50004 7.51327C0.498829 7.36698 0.525401 7.22241 0.577667 7.08822C0.62992 6.95407 0.706482 6.83377 0.801714 6.73374C0.896895 6.63376 1.00871 6.55611 1.12993 6.50401C1.25108 6.45195 1.37996 6.4261 1.50929 6.42728C1.63863 6.42846 1.76714 6.45666 1.88753 6.51098C2.008 6.56534 2.11865 6.64511 2.21228 6.74694L2.21222 6.747L2.2182 6.75328L6.8167 11.5836L7.17886 11.964L7.54099 11.5835L17.7996 0.806833L17.7997 0.806734C17.8938 0.707784 18.0043 0.630673 18.124 0.578548C18.2437 0.526453 18.371 0.5 18.499 0.5C18.627 0.5 18.7543 0.526453 18.874 0.578548C18.9937 0.630671 19.1042 0.707781 19.1983 0.806737Z"
      fill="#690DFF"
      stroke="#690DFF"
    />
  </svg>
);
