import React from "react";
import { useLocation } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { HpDashboardSettingsBillingDash } from "web-shared/src/domains/hp/settings/billing-settings-dash.js";

export const HpDashboardSettingsBillingPage: React.FC<{}> = () => {
  const { pathname } = useLocation();
  return (
    <HpDashboardSettingsBillingDash
      pathname={pathname}
      settingsBillingPath={UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING.path}
    />
  );
};
