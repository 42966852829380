import { useUnit } from "effector-react";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce, useQuery$ } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import { isNotNullOrUndefined } from "shared/util";
import { SearchableDropdownV2 } from "web-shared/src/components/searchable-dropdown";
import { CreateCommunityPostFormCool } from "./create-community-post.form";

export const SelectCommunityAndThenPostForm: React.FC<{
  onSuccessSubmit: (p: { communitySlug: string }) => void;
}> = ({ onSuccessSubmit }) => {
  const allCommunities$ = useQuery$(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesICanPostTo
  );

  const vm = useOnce(() => new BaseSearchableDropdownVM());

  const selectedCommunity = useUnit(vm.$selectedItem);

  useEffect(() => {
    allCommunities$.subscribe((allCommunities) => {
      if (allCommunities === undefined) return;
      vm.itemsSet(
        allCommunities.map((c) => ({
          id: c.slug,
          label: c.name,
        }))
      );
    });
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-12">
      <div className="mt-8">
        <SearchableDropdownV2 vm={vm} label="Select a community" />
      </div>
      {isNotNullOrUndefined(selectedCommunity) && (
        <CreateCommunityPostFormCool
          communitySlug={selectedCommunity.id}
          onSuccessSubmit={onSuccessSubmit}
        />
      )}
    </div>
  );
};
