import { Data, Equal } from "effect";
import { Rx, RxO } from "web-shared/src/prelude";

type GlobalErrorSeverity = "error" | "warning" | "info";
type GlobalErrorToast = {
  message: string;
  title?: string;
  severity?: GlobalErrorSeverity;
  durationSeconds?: number;
};

export class GlobalErrorToastMgr {
  private internalShowGlobalErrorToast$ =
    new Rx.BehaviorSubject<GlobalErrorToast | null>(null);

  showGlobalErrorToast$ = this.internalShowGlobalErrorToast$.pipe(
    RxO.distinctUntilChanged((a, b) => Equal.equals(a, b))
  );

  showToast(toast: GlobalErrorToast) {
    this.internalShowGlobalErrorToast$.next(Data.struct(toast));

    if (toast.durationSeconds) {
      setTimeout(() => {
        this.hideToast();
      }, toast.durationSeconds * 1000);
    }
  }

  hideToast() {
    this.internalShowGlobalErrorToast$.next(null);
  }
}

export const globalErrorToastMgr = new GlobalErrorToastMgr();

interface GlobalTopInfoToast {
  title: string;
  severity?: GlobalErrorSeverity;
  durationSeconds?: number;
  description?: string;
}

class GlobalTopInfoToastMgr {
  private showToastTrigger$ = new Rx.BehaviorSubject<GlobalTopInfoToast | null>(
    null
  );

  showToast$ = this.showToastTrigger$.pipe(
    RxO.distinctUntilChanged((a, b) => Equal.equals(a, b)),
    RxO.startWith(null)
  );

  showToast(toast: GlobalTopInfoToast) {
    this.showToastTrigger$.next(Data.struct(toast));

    setTimeout(
      () => {
        this.hideToast();
      },
      toast.durationSeconds ? toast.durationSeconds * 1000 : 5000
    );
  }

  hideToast() {
    this.showToastTrigger$.next(null);
  }

  showCopiedToClipboardToast() {
    this.showToast({ title: "Copied to clipboard" });
  }
}

export const globalTopInfoToastMgr = new GlobalTopInfoToastMgr();
