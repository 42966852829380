// hooks/useNotificationToasts.ts
import { useMutation, useQuery } from "convex/react";
import { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import { ToastVM } from "./toast.vm";
import { useUnit } from "effector-react";
import { useOnce } from "../util";

export function useNotificationToasts(vm: ToastVM) {
  const notifications = useQuery(
    api.Notifications.NotificationFns.getUnreadNotifications
  );

  const markInAppNotificationStatus = useMutation(
    api.Notifications.NotificationFns.markInAppNotificationStatus
  );

  useEffect(() => {
    vm.markNotificationReadFx.use(async (notificationIds) => {
      await markInAppNotificationStatus({
        notificationIds,
        status: "READ",
      });
    });
  }, []);

  const { showToast, dismissWithAction } = useUnit({
    showToast: vm.showToastEvt,
    dismissWithAction: vm.dismissWithActionEvt,
  });

  useEffect(() => {
    if (!notifications?.length) return;

    notifications.forEach((notification) => {
      const toastId = Math.random().toString(36).substr(2, 9);

      showToast({
        id: toastId,
        title: notification.title,
        description: notification.message,
        duration: notification.appearance?.duration,
        requiresAcknowledgment: Boolean(notification.appearance?.action),
        notificationId: notification._id,
        ...(notification.appearance?.action && {
          action: {
            label: notification.appearance.action.label,
            onClick: () => {
              dismissWithAction({
                toastId,
                notificationIds: [notification._id],
              });
            },
          },
        }),
      });
    });
  }, [notifications, showToast, dismissWithAction]);

  return null;
}
