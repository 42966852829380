import { JoinCommunityModal } from "@/src/domains/communities/community/components/join-community-modal";
import { MemberCard } from "@/src/domains/communities/community/members/member-card";
import { InviteToCommunityForm } from "@/src/domains/communities/invite-to-community.form";
import {
  CommunityDashLayout,
  CommunityDashTabs,
} from "@/src/domains/communities/shared/dash.components";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useQuery } from "convex/react";
import {
  CommunityDashVMProvider,
  useCommunityDashVM,
  useSetupCommunityDash,
  type CommunityDashVM,
} from "frontend-shared/src/domains/community/community-dash.vm";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";
import { isNotNullOrUndefined } from "shared/util";
import { AvatarCirclesWithCountInfo } from "web-shared/src/components/avatar.tc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { MyCommunitiesProfileCard } from "web-shared/src/domains/user/my-profile-card";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const HpDashboardCommunityDash: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const vm = useSetupCommunityDash({ communitySlug });

  if (vm === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedView vm={vm} />;
};

const LoadedView: React.FC<{ vm: CommunityDashVM }> = ({ vm }) => {
  return (
    <CommunityDashVMProvider.Provider value={vm}>
      <HpDashboardCommunityTabLayout />
    </CommunityDashVMProvider.Provider>
  );
};

const HpDashboardCommunityTabLayout: React.FC = () => {
  const location = useLocation();
  const isEventsTab = location.pathname.toLowerCase().includes("events");
  const [maxHeight, setMaxHeight] = useState("500px");
  const [opacity, setOpacity] = useState(1);
  const [showJoinModal, setShowJoinModal] = useState(false);

  useEffect(() => {
    if (isEventsTab) {
      setOpacity(0);
      setMaxHeight("0px");
    } else {
      setOpacity(1);
      setMaxHeight("500px");
    }
  }, [isEventsTab]);

  const hpState = useHpState();
  const webDisplayVM = useWebGlobalDisplayVM();
  const vm = useCommunityDashVM();
  const nav = useNavigate();

  const community = vm.communityInfo;
  const topLiveEvent = vm.topLiveEvent;

  const joinRequirements = useObservableEagerState(
    vm.communityJoinRequirements$
  );

  const handleJoinClick = async () => {
    if (community.amIJoined) {
      // Handle unjoin directly
      await vm.onJoinButtonClick();
      webDisplayVM.toastVM.showToast({
        id: "unjoined",
        title: "Unjoined",
        duration: 1000,
      });
      return;
    }

    if (joinRequirements?.requiresPayment) {
      setShowJoinModal(true);
    } else {
      // Regular join flow
      await vm.onJoinButtonClick();
      hpState.dashboardState.showBottomToast({
        msg: "Joined",
        duration: { _tag: "SECONDS", seconds: 1 },
        reload: true,
      });
    }
  };

  if (joinRequirements === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <CommunityDashLayout
      topBanner={{
        maxHeight,
        opacity,
        props: {
          isPrivate: vm.communityInfo.isPrivate,
          communityColorName: vm.communityInfo.color,
          circleImageUrl: null,
          title: { _tag: "TEXT", text: vm.communityInfo.name },
          nextToTitleFc: <TopBannerNextToTitleView />,
          rightView: (
            <div className="flex items-center gap-2">
              <PrimaryButton
                title="Invite"
                dims={{ width: 171 }}
                onClick={() => {
                  webDisplayVM.globalSheetVM.openSheet(
                    {
                      title: "Invite members to this community",
                      type: { tag: "create", icon: "profile-add" },
                    },
                    <InviteToCommunityForm
                      communitySlug={vm.communityInfo.slug}
                    />
                  );
                }}
              />

              <InversePrimaryButton
                title={community.amIJoined ? "Joined" : "Join"}
                onClick={handleJoinClick}
              />
            </div>
          ),
        },
      }}
      extraTopView={
        isNotNullOrUndefined(topLiveEvent) && isEventsTab ? (
          <TopLiveBanner
            topLiveEvent={topLiveEvent}
            onJoin={() => {
              if (topLiveEvent.mbSessionId === null) {
                return; // TODO: Report error to sentry maybe?
              }
              nav(
                UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                  sessionId: topLiveEvent.mbSessionId!,
                })
              );
            }}
          />
        ) : (
          <></>
        )
      }
      isFullWidth={isEventsTab}
    >
      <div className="flex flex-col gap-8">
        <div className="flex-1 flex gap-8 self-stretch">
          <div className="flex-1 flex flex-col min-w-0 gap-6">
            <CommunityDashTabs communitySlug={community.slug} />
            <div className="flex-1 flex flex-col">
              {community.amIJoined ? <Outlet /> : <UnjoinedContentView />}
            </div>
          </div>
          {!location.pathname.includes("events") && <MyProfile />}
        </div>
      </div>

      {joinRequirements !== null && !vm.communityInfo.amIJoined && (
        <JoinCommunityModal
          isOpen={showJoinModal}
          communityName={community.name}
          monthlyPriceInCents={joinRequirements.monthlyPriceInCents}
          trialPeriodDays={joinRequirements.trialPeriodDays}
          onConfirm={async () => {
            await vm.onJoinButtonClick({
              hasAgreedToPayment: true,
            });
            setShowJoinModal(false);
            hpState.dashboardState.showBottomToast({
              msg: "Joined",
              duration: { _tag: "SECONDS", seconds: 1 },
              reload: true,
            });
          }}
          onCancel={() => {
            setShowJoinModal(false);
          }}
        />
      )}
    </CommunityDashLayout>
  );
};

const UnjoinedContentView: React.FC = () => {
  const hpState = useHpState();
  const vm = useCommunityDashVM();
  return (
    <div
      className="basis-[300px] grow-0 shrink-0 flex flex-col py-8 items-center underline cursor-pointer text-vid-purple text-xl"
      onClick={() => {
        vm.onJoinButtonClick().then((_) => {
          hpState.dashboardState.showBottomToast({
            msg: "Joined",
            duration: { _tag: "SECONDS", seconds: 1 },
            reload: true,
          });
        });
      }}
    >
      Join to view the discussion
    </div>
  );
};

const TopLiveBanner: React.FC<{
  topLiveEvent: { title: string };
  onJoin: () => void;
}> = ({ topLiveEvent, onJoin }) => {
  return (
    <div className="bg-vid-purple py-4 px-6 rounded-[10px] mb-10 flex items-center justify-between">
      <div className="text-white font-medium font-roboto-flex">
        {`${topLiveEvent.title} is live`}
      </div>
      <button
        className="bg-white text-vid-black-900 px-6 py-3 rounded-[35px] text-sm"
        onClick={onJoin}
      >
        Join
      </button>
    </div>
  );
};

const TopBannerNextToTitleView: React.FC = () => {
  const vm = useCommunityDashVM();
  const communityMembers = useObservableEagerState(vm.allMembers$);

  if (communityMembers === undefined) {
    return null;
  }

  return (
    <Link
      to={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
        { community: vm.communityInfo.slug }
      )}
    >
      <AvatarCirclesWithCountInfo
        sources={communityMembers.slice(0, 3).map((m) => m.profilePhoto)}
        countText={`${communityMembers.length} ${communityMembers.length === 1 ? "Member" : "Members"}`}
        size={40}
      />
    </Link>
  );
};

export const CommunityMembersTab: React.FC = () => {
  const vm = useCommunityDashVM();

  const allMembers = useObservableEagerState(vm.allMembers$);

  if (allMembers === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <AllMembersTabContent members={allMembers} />;
};

const AllMembersTabContent: React.FC<{
  members: SimpleUserWithProfilePhoto[];
}> = ({ members }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-4 flex-wrap">
        {members.map((m, index) => {
          return (
            <MemberCard
              key={m.id}
              index={index}
              userId={m.id}
              name={m.name!}
              profilePhoto={m.profilePhoto}
              isAvailable={false}
              modalities={[]}
            />
          );
        })}
      </div>
    </div>
  );
};

const MyProfile: React.FC = () => {
  const globalProfile = useQuery(api.User.Hp.HpFns.getMyGlobalProfile);

  return (
    <div>
      <MyCommunitiesProfileCard globalProfile={globalProfile} />
    </div>
  );
};
