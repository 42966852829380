import { Match } from "effect";
import type { AuthedDestination } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";

export function getRouteForAuthedDestination(
  currentHref: string,
  destination: AuthedDestination
) {
  if (
    currentHref.includes("/u/courses") ||
    currentHref.includes("/u/sessions") ||
    currentHref.includes("/u/communities")
  ) {
    return currentHref;
  }

  return Match.value(destination).pipe(
    Match.when({ tag: "ONBOARD" }, ({ onboardState }) =>
      Match.value(onboardState).pipe(
        Match.when(
          "DO_YOU_SEE_CLIENTS",
          () => UROUTES.ONBOARD.DO_YOU_SEE_CLIENTS.path
        ),
        Match.when("SET_PROFILE", () => UROUTES.ONBOARD.INPUT_PROFILE.path),
        Match.when("COMPLETED", () => UROUTES.HP.ONBOARD_COMPLETE.path),
        Match.exhaustive
      )
    ),
    Match.when({ tag: "DASHBOARD", dashboard: "HP" }, () => {
      if (currentHref.includes("/u/hp/my/dashboard")) {
        return currentHref;
      } else {
        return UROUTES.HP.MY.DASHBOARD.CLIENTS.path;
      }
    }),
    Match.when({ tag: "DASHBOARD", dashboard: "CP" }, () => {
      if (currentHref.includes("/u/cp/dashboard")) {
        return currentHref;
      } else {
        return UROUTES.CP.DASHBOARD.HOME.path;
      }
    }),
    Match.exhaustive
  );
}
