import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";
import { useDialogVM } from "web-shared/src/web-context";
import { useNavigate } from "react-router-dom";
import { MockAvatarCircles } from "web-shared/src/components/avatar.tc";
import { PrimaryButton } from "web-shared/src/components/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "web-shared/src/components/ui/dialog";
import { AuthinForm } from "web-shared/src/domains/auth/authin.form";

export const CreateAProfileDialog: React.FC<{
  trigger: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}> = ({ trigger, open, onOpenChange }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent>
        <div className="flex flex-col py-4 justify-center items-center">
          <CreateAProfileCard />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const CreateAProfileCard: React.FC = () => {
  const dialogVM = useDialogVM();
  const nav = useNavigate();
  return (
    <div
      className="w-[345px] h-[555px] flex flex-col gap-4 justify-center items-center px-8 rounded-[12px]"
      style={{
        backgroundColor: "#F5F1FD",
      }}
    >
      <MockAvatarCircles size={44} />
      <div className="flex justify-center items-center rounded-full px-6 py-[10px] text-sm bg-white border">
        Trusted by 1000+ practitioners
      </div>
      <h4 className="font-medium text-[36px] text-vid-purple text-center text-leading">
        Create a profile
      </h4>
      <span className="text-center text-sm text-black-26">
        {`InsightLive makes it easier for you to connect with fellow practitioners and clients globally.`}
      </span>
      <PrimaryButton
        title="Sign up"
        onClick={() => {
          dialogVM.openDialogue(
            <AuthinForm
              onAuthinSuccess={() => {
                dialogVM.closeDialogue();
              }}
              logoIcon={<InsightLiveLogoWithText />}
            />
          );
        }}
      />
    </div>
  );
};
