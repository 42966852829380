import { InversePrimaryButton } from "web-shared/src/components/button";
import { Match } from "effect";
import { getColor, type ColorKey } from "shared/utils/color.utils";

type HeaderBackgroundType =
  | { _tag: "INDEXED"; index: number }
  | { _tag: "EVENT_PURPLE" }
  | { _tag: "FIXED"; color: string }
  | {
      _tag: "CUSTOM";
      color: string;
      content: React.ReactNode;
      hideCircleThings: boolean;
    };

function communityColorCardItemBgColor(idx: number) {
  switch (idx) {
    case 0:
      return "#23CE24";
    case 1:
      return "#690DFF";
    case 2:
      return "#FFA8A4";
    case 3:
      return "#FFCF26";
    default:
      return "#23CE24";
  }
}

function headerBackgroundColor(type: HeaderBackgroundType): string {
  return Match.value(type).pipe(
    Match.tag("INDEXED", ({ index }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${communityColorCardItemBgColor(
        index
      )}`;
    }),
    Match.tag("EVENT_PURPLE", () => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), var(--Vidalify-Purple, #690DFF)`;
    }),
    Match.tag("FIXED", ({ color }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${getColor(
        color as ColorKey
      )}`;
    }),
    Match.tag("CUSTOM", ({ color }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${getColor(
        color as ColorKey
      )}`;
    }),
    Match.exhaustive
  );
}

type CommunityCardHeight = "small" | "medium" | "large" | "xlarge" | "auto";

function matchHeightPx(height: CommunityCardHeight): string | undefined {
  return Match.value(height).pipe(
    Match.when("small", () => "288px"),
    Match.when("medium", () => "380px"),
    Match.when("large", () => "432px"),
    Match.when("xlarge", () => "570px"),
    Match.when("auto", () => undefined),
    Match.exhaustive
  );
}

const CARD_CONSTANTS = {
  headerHeight: 123,
  minHeight: "288px",
  maxHeight: "640px",
} as const;

interface CommonCardContainerProps {
  width: string;
  height?: CommunityCardHeight;
  innerCircleComponent?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const CommonCardContainer: React.FC<
  CommonCardContainerProps & { children: React.ReactNode }
> = ({ width, height = "large", children, className = "", onClick }) => {
  const heightPx = matchHeightPx(height);

  return (
    <div
      className={`flex flex-col border-vid-black-200 rounded-[12px] border border-vid-black-200
        ${onClick ? "cursor-pointer" : ""}
        ${className}`}
      style={{
        minWidth: width,
        width,
        height: heightPx,
        minHeight: height !== "auto" ? heightPx : 0,
        // maxHeight: height === "auto" ? CARD_CONSTANTS.maxHeight : undefined,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const CommunityCardContainerSkeleton: React.FC<{
  widthPx: string;
  height?: CommunityCardHeight;
  innerCircleComponent?: React.ReactNode;
  button?: boolean;
}> = ({ widthPx, height }) => {
  return (
    <CommonCardContainer
      width={widthPx}
      height={height}
      className="animate-pulse overflow-hidden"
    >
      {/* Header skeleton */}
      <div
        className={`border-tr-1 relative ${CARD_CONSTANTS.headerHeight} border rounded-tr-[12px] rounded-tl-[12px] bg-gray-200`}
      />

      {/* Rest of the skeleton content ... */}
    </CommonCardContainer>
  );
};

export const CommunityCardContainer: React.FC<{
  title?: string;
  content: React.ReactNode;
  headerBackgroundType: HeaderBackgroundType;
  button: {
    title: string;
    onClick?: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  } | null;
  isLoading?: boolean;
  innerCircleComponent?: React.ReactNode;
  headerHeight?: number;
  width?: string;
  height?: "small" | "medium" | "large" | "xlarge" | "auto";
  onClick?: () => void;
}> = ({
  innerCircleComponent,
  title,
  content,
  button,
  isLoading,
  headerBackgroundType,
  headerHeight = CARD_CONSTANTS.headerHeight,
  width = "220px",
  onClick,
  height = "large",
}) => {
  if (isLoading) {
    return (
      <CommunityCardContainerSkeleton
        widthPx={width}
        height={height}
        innerCircleComponent={innerCircleComponent}
        button={!!button}
      />
    );
  }

  const showCircleThings =
    (headerBackgroundType._tag === "CUSTOM" &&
      !headerBackgroundType.hideCircleThings) ||
    headerBackgroundType._tag === "INDEXED" ||
    headerBackgroundType._tag === "FIXED";

  return (
    <CommonCardContainer width={width} height={height} onClick={onClick}>
      <div
        className="relative rounded-tr-[12px] rounded-tl-[12px]"
        style={{
          background: headerBackgroundColor(headerBackgroundType),
          height: `${headerHeight}px`,
        }}
      >
        {headerBackgroundType._tag === "CUSTOM" && (
          <div className="absolute inset-0 flex flex-col">
            {headerBackgroundType.content}
          </div>
        )}
        {showCircleThings && (
          <>
            <div className="absolute bottom-0 right-0">
              <BottomRightHeaderCircleSvg />
            </div>
            <div className="absolute top-0 left-0">
              <TopLeftHeaderCircleSvg />
            </div>
          </>
        )}
        <div className="absolute left-0 right-0 bottom-0 translate-y-1/2 flex justify-center items-center">
          {innerCircleComponent}
        </div>
      </div>
      <div
        className={`
      flex-1 flex flex-col text-xs lg:text-base 
      px-4 pb-4
      ${innerCircleComponent ? "pt-[56px]" : "pt-[18px]"}
      `}
      >
        {title && (
          <div className="w-full self-center font-semibold text-center px-2 break-words">
            {title}
          </div>
        )}
        <div className="flex-1 flex flex-col">{content}</div>
        {button && (
          <div className="flex-1 flex flex-col justify-end self-center">
            <InversePrimaryButton
              onClick={() => {
                button.onClick?.();
              }}
              title={button.title}
              isLoading={button.isLoading}
            />
          </div>
        )}
      </div>
    </CommonCardContainer>
  );
};

const BottomRightHeaderCircleSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={93}
      height={70}
      viewBox="0 0 93 70"
      fill="none"
    >
      <circle cx={99.5} cy={99.5} r={99.5} fill="white" fillOpacity={0.2} />
    </svg>
  );
};

const TopLeftHeaderCircleSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99"
      height="87"
      viewBox="0 0 99 87"
      fill="none"
    >
      <circle cx="-0.5" cy="-12.5" r="99.5" fill="white" fillOpacity="0.2" />
    </svg>
  );
};
