import { FullContainerLoadingSpinner } from "@/src/loading";
import { useUMgr, useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { useCommunityDashVM } from "frontend-shared/src/domains/community/community-dash.vm";
import { useNavigate } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { CourseThumbETO } from "shared/be/convex/Learning/Courses/Courses.Types";
import { UROUTES } from "shared/routes/u.routes";
import { MockAvatarCircles } from "web-shared/src/components/avatar.tc";
import { CourseCreationForm } from "../../learning/courses/create-new-course.form";
import { InversePrimaryButton } from "web-shared/src/components/button";

export const CommunityLearningTabContent: React.FC = () => {
  const vm = useCommunityDashVM();
  const courses = useQuery(
    api.Screens.Hp.Dashboard.Community.CommunityLearningScreenFns.getCourses,
    {
      communitySlug: vm.communityInfo.slug,
    }
  );

  if (courses === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex items-center gap-4 flex-wrap">
        {courses.map((course) => (
          <CourseCard key={course._id} course={course} />
        ))}
        <CreateNewCourseCard communitySlug={vm.communityInfo.slug} />
      </div>
    </div>
  );
};

const CourseCardContainer: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
}> = ({ children, onClick }) => {
  return (
    <div
      className="h-[432px] w-[220px] flex flex-col gap-2 border border-vid-black-200 rounded-[12px] cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const CreateNewCourseCard: React.FC<{ communitySlug: string }> = ({
  communitySlug,
}) => {
  const webDisplayVM = useWebGlobalDisplayVM();

  const onSubmitClick = useMutation(
    api.Screens.Hp.Dashboard.Community.CommunityLearningScreenFns
      .onSubmitCreateCourseClick
  );

  return (
    <CourseCardContainer
      onClick={() => {
        webDisplayVM.globalSheetVM.openSheet(
          {
            title: "Create a course",
            type: { tag: "create" },
          },
          <CourseCreationForm
            communitySlug={communitySlug}
            onSubmitClick={(submitArgs) => {
              onSubmitClick({
                ...submitArgs,
                communitySlug,
              }).then(() => {
                webDisplayVM.globalSheetVM.closeSheet();
              });
            }}
          />
        );
      }}
    >
      <div className="flex-1 flex flex-col items-center justify-center gap-4 bg-vid-black-100 rounded-[12px] cursor-pointer">
        <button className="text-vid-purple text-lg font-medium bg-white flex items-center justify-center rounded-[8px] px-10 py-3 cursor-pointer">
          Create course
        </button>
      </div>
    </CourseCardContainer>
  );
};

const CourseCard: React.FC<{ course: CourseThumbETO }> = ({ course }) => {
  const navigate = useNavigate();
  const uMgr = useUMgr();

  const myCourseCta = useQuery(api.Learning.Courses.CoursesFns.getMyCourseCta, {
    courseId: course._id,
  });

  const enrollInCourse = useMutation(
    api.Learning.Courses.CoursesFns.enrollInCourse
  );

  if (myCourseCta === undefined) {
    return null;
  }

  return (
    <CourseCardContainer onClick={() => {}}>
      {/* header with thumbnail bg */}
      <div className="h-[123px] relative">
        {course.thumbnail ? (
          <img
            src={course.thumbnail}
            alt={course.title}
            className="absolute inset-0 object-cover rounded-t-[12px]"
          />
        ) : (
          <div className="absolute inset-0 bg-vid-gray-200"></div>
        )}
        <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
          <img src={"/Play.svg"} className="w-12 h-12" />
        </div>
      </div>

      {/* body */}
      <div className="flex-1 flex flex-col pt-8 pb-4 px-4">
        <div className="flex flex-col gap-2">
          <h6 className="text-sm font-bold">Learning</h6>
          <h4 className="">{course.title}</h4>
          <p className="text-vid-black-400 text-[14px]">{course.description}</p>
          <div className="flex-1" />
        </div>
        <div className="flex-1 flex flex-col-reverse items-center gap-2">
          {Match.value(myCourseCta).pipe(
            Match.when("ENROLL", () => (
              <InversePrimaryButton
                title="Enroll"
                onClick={() => {
                  enrollInCourse({
                    courseId: course._id,
                  }).then(() => {
                    uMgr.toastVM.showToastEvt({
                      id: "enrolled-in-course",
                      title: "Enrolled in course",
                      type: "success",
                    });
                    // navigate(
                    //   UROUTES.COURSES.COURSE_ID.COURSE_APP.buildPath({
                    //     courseId: course._id,
                    //   })
                    // );
                  });
                }}
                width={"100%"}
              />
            )),
            Match.when("START_COURSE", () => (
              <div>
                <InversePrimaryButton
                  title="Start course"
                  onClick={() => {
                    navigate(
                      UROUTES.COURSES.COURSE_ID.COURSE_APP.buildPath({
                        courseId: course._id,
                      })
                    );
                  }}
                  width={"100%"}
                />
              </div>
            )),
            Match.exhaustive
          )}
          <div className="flex flex-col items-center justify-center gap-1">
            <MockAvatarCircles size={24} />
            <span className="text-sm text-vid-black-400">87 taken</span>
          </div>
        </div>
      </div>
    </CourseCardContainer>
  );
};
