import { Match } from "effect";
import { useState } from "react";
import type { SpanETO } from "shared/be/convex/Sessions/Annotations/Annotation.Types";
import { getColor, type ColorKey } from "shared/utils/color.utils";

interface SavedBookmarkItemProps {
  color: string;
  label: string;
  secondsIntoSession: number;
  onClick:
    | { tag: "EDIT"; onSave: (newLabel: string) => void }
    | { tag: "ACTION"; action: (b: { Sis: number }) => void };
}

export const SavedBookmarksCard: React.FC<{
  bookmarks: SavedBookmarkItemProps[];
  selectedBookmarkSis?: number;
}> = ({ bookmarks, selectedBookmarkSis }) => {
  return (
    <MomentCardContainer title="Bookmarks">
      {bookmarks.map((b) => (
        <SavedBookmarkItem
          key={b.secondsIntoSession}
          {...b}
          isSelected={selectedBookmarkSis === b.secondsIntoSession}
        />
      ))}
    </MomentCardContainer>
  );
};

export const SavedBookmarkItem: React.FC<
  SavedBookmarkItemProps & { isSelected?: boolean }
> = ({ color, label, secondsIntoSession, onClick, isSelected }) => {
  const minutes = Math.floor(secondsIntoSession / 60);
  const seconds = secondsIntoSession % 60;
  const [isEditing, setIsEditing] = useState(false);
  const [editedLabel, setEditedLabel] = useState(label);

  const handleSave = () => {
    setIsEditing(false);

    if (onClick.tag === "EDIT") {
      onClick.onSave(editedLabel);
    }
  };

  return (
    <div
      className={`flex gap-2 items-center justify-between py-[7px] rounded-[12px] 
        px-3 transition-all duration-200 ease-in-out transform 
        cursor-pointer
        ${
          isSelected
            ? "shadow-[0px_3.141px_13.12px_0px_rgba(99,100,113,0.27)] scale-[1.02]"
            : "scale-100"
        }`}
      onClick={() => {
        switch (onClick.tag) {
          case "EDIT":
            setIsEditing(true);
            break;
          case "ACTION":
            onClick.action({ Sis: secondsIntoSession });
            break;
        }
      }}
    >
      <div className="flex gap-2 items-center">
        <BookmarkIcon color={getColor(color as ColorKey)} />
        {isEditing ? (
          <input
            type="text"
            value={editedLabel}
            onChange={(e) => setEditedLabel(e.target.value)}
            onBlur={handleSave}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
            }}
            className="text-vid-black-900 border rounded px-1"
            autoFocus
          />
        ) : (
          <div className="text-vid-black-900 text-[14px] cursor-pointer">
            {label}
          </div>
        )}
      </div>
      <div className="text-vid-black-500">
        {`${Math.floor(minutes)}:${Math.floor(seconds).toString().padStart(2, "0")}`}
      </div>
    </div>
  );
};

const MomentCardContainer: React.FC<{
  title: string;
  children: React.ReactNode;
  maxWidth?: number;
}> = ({ title, children, maxWidth }) => {
  return (
    <div
      className="flex flex-col gap-2 p-4 border rounded-[12px] border-vid-black-200"
      style={{
        maxWidth,
      }}
    >
      <div className="flex items-center justify-between">
        <div className="text-vid-black-900 ">{title}</div>
      </div>
      <div className="flex flex-col gap-2 ">{children}</div>
    </div>
  );
};

export const SavedBookendItem: React.FC<{
  bookend: SpanETO;
  isSelected: boolean;
  onClick:
    | { tag: "EDIT"; onSave: (bk: SpanETO, newLabel: string) => void }
    | { tag: "ACTION"; action: (b: SpanETO) => void };
}> = ({ bookend, isSelected, onClick }) => {
  const color = "gray";
  const [isEditing, setIsEditing] = useState(false);
  const [editedLabel, setEditedLabel] = useState(bookend.label);

  const handleSave = () => {
    setIsEditing(false);
    if (onClick.tag === "EDIT") {
      onClick.onSave(bookend, editedLabel);
    }
  };

  return (
    <div
      className={`flex gap-2 items-center justify-between py-[7px] rounded-[12px] px-3 transition-all duration-200 ease-in-out transform ${
        isSelected ? "scale-[1.02]" : "scale-100"
      }`}
      style={{
        boxShadow: isSelected
          ? "0px 3.141px 13.12px 0px rgba(99, 100, 113, 0.27)"
          : "",
      }}
    >
      <div className="flex gap-2 items-center">
        <BookendIcon color={color} />
        {isEditing ? (
          <input
            type="text"
            value={editedLabel}
            onChange={(e) => setEditedLabel(e.target.value)}
            onBlur={handleSave}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
            }}
            className="text-vid-black-900 text-[14px] border rounded px-1"
            autoFocus
          />
        ) : (
          <div
            className="text-vid-black-900 text-[14px] cursor-pointer"
            onClick={() => {
              switch (onClick.tag) {
                case "EDIT":
                  setIsEditing(true);
                  break;
                case "ACTION":
                  onClick.action(bookend);
                  break;
              }
            }}
          >
            {bookend.label}
          </div>
        )}
      </div>
      <div className="text-vid-black-500">{`${bookend.displayTimeInfo}`}</div>
    </div>
  );
};

export const RecordedSpansCard: React.FC<{
  spans: SpanETO[];
  onClick:
    | { tag: "EDIT"; onSave: (bk: SpanETO, newLabel: string) => void }
    | { tag: "ACTION"; action: (b: SpanETO) => void };
  selectedSpanId?: string;
}> = ({ spans, onClick, selectedSpanId }) => {
  return (
    <MomentCardContainer title="Recorded sections">
      <div className="flex flex-col gap-2 self-stretch">
        {spans.map((bk) => (
          <SavedBookendItem
            key={bk.momentId}
            bookend={bk}
            onClick={onClick}
            isSelected={selectedSpanId === bk.momentId}
          />
        ))}
      </div>
    </MomentCardContainer>
  );
};

export const BookmarkIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.0168 2.16602H5.98351C4.20851 2.16602 2.76685 3.61602 2.76685 5.38268V17.1244C2.76685 18.6243 3.84185 19.2577 5.15851 18.5327L9.22518 16.2744C9.65851 16.0327 10.3585 16.0327 10.7835 16.2744L14.8502 18.5327C16.1668 19.266 17.2418 18.6327 17.2418 17.1244V5.38268C17.2335 3.61602 15.7918 2.16602 14.0168 2.16602Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BookendIcon: React.FC<{ color?: string }> = ({ color = "#1D1626" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
    >
      <rect
        x="6.16699"
        y="0.833984"
        width="2.08333"
        height="18.3333"
        rx="1.04167"
        fill={color}
      />
      <rect
        x="0.333496"
        y="4.16797"
        width="2.08333"
        height="11.6667"
        rx="1.04167"
        fill={color}
      />
      <rect
        x="12"
        y="6.66797"
        width="2.08333"
        height="6.66667"
        rx="1.04167"
        fill={color}
      />
    </svg>
  );
};

function matchColor(color: string) {
  return Match.value(color).pipe(
    Match.when("red", () => "bg-red-500"),
    Match.when("blue", () => "bg-blue-500"),
    Match.when("green", () => "bg-green-500"),
    Match.when("yellow", () => "bg-yellow-500"),
    Match.when("purple", () => "bg-purple-500"),
    Match.when("orange", () => "bg-orange-500"),
    Match.when("pink", () => "bg-pink-500"),
    Match.when("teal", () => "bg-teal-500"),
    Match.orElse(() => `bg-gray-400`)
  );
}
