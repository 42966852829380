import {
  DurationPickerVM,
  DurationSelector,
} from "@/src/components/session-form.components";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { CalendarDropdown } from "@webapp/componentsform/calendar-picker.input";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation } from "convex/react";
import { combine, createEffect, createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { FormFocusVM } from "frontend-shared/src/form-focus.vm";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import React, { useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { RecurrenceFrequencyChoice } from "shared/types/calendar.types";
import { RRuleUtils } from "shared/utils/calendar.utils";
import { PrimaryButton } from "web-shared/src/components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "web-shared/src/components/ui/dropdown-menu";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

function useSetupForm(p: {
  communitySlug: string;
  onSuccessSubmit?: () => void;
}) {
  const scheduleEvent = useMutation(
    api.Calendar.CommunityCalEventFns.createCommunityCalEvent
  );

  const vm = useMemo(() => {
    return new FormVM({
      submit: async (payload) => {
        await scheduleEvent({
          communitySlug: p.communitySlug,
          title: payload.name,
          startsAt: payload.date.getTime(),
          description: null,
          durationSeconds: payload.durationInMinutes * 60,
          rrule: payload.rrule,
        });
      },
      onSuccessSubmit: p.onSuccessSubmit,
    });
  }, []);

  return vm;
}

class FormVM {
  calendarPickerVM = new CalendarPickerVM();
  durationPickerVM = new DurationPickerVM();
  recurringInputVM = new RecurringInputVM();

  submitted = createEvent();
  $name = createStore<string>("");
  setName = createEvent<string>();

  $validationError = createStore<string | null>(null);

  submitFx = createEffect<
    {
      name: string;
      date: Date;
      durationInMinutes: number;
      rrule: string | null;
    },
    void,
    Error
  >();

  constructor(p: {
    submit: (p: {
      name: string;
      date: Date;
      durationInMinutes: number;
      rrule: string | null;
    }) => Promise<void>;
    onSuccessSubmit?: () => void;
  }) {
    this.$name.on(this.setName, (_, name) => name);

    this.submitFx.use(p.submit);

    this.$validationError.on(
      combine(this.$name, this.calendarPickerVM.$picker),
      () => null
    );

    this.submitted.watch(async () => {
      const name = this.$name.getState();
      const selectedDate = this.calendarPickerVM.$selectedDate.getState();
      const durationInMinutes =
        this.durationPickerVM.$selectedDurationMins.getState();
      const recurringStatus =
        this.recurringInputVM.$selectedFrequency.getState();

      if (name && selectedDate) {
        try {
          const rrule =
            recurringStatus === "none"
              ? null
              : RRuleUtils.asRRuleString({
                  frequency: recurringStatus,
                  interval: 1,
                });

          await this.submitFx({
            name,
            date: selectedDate,
            durationInMinutes,
            rrule,
          });
          p.onSuccessSubmit?.();
        } catch {}
      }
    });

    this.$validationError.on(this.submitted, () => {
      const name = this.$name.getState();
      const pickerState = this.calendarPickerVM.$picker.getState();

      const errors: string[] = [];

      if (!name) {
        errors.push("Please enter an event name");
      }

      if (!pickerState.selectedDay) {
        errors.push("Please select a date");
      } else if (!pickerState.selectedTime) {
        errors.push("Please select a time");
      }

      return errors.length > 0 ? errors.join(", ") : null;
    });
  }
}

export const ScheduleCommunityEventForm: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const hpState = useHpState();
  const webDisplayVM = useWebGlobalDisplayVM();
  const vm = useSetupForm({
    communitySlug,
    onSuccessSubmit: () => {
      hpState.dashboardState.showBottomToast({
        msg: "Event scheduled successfully",
        reload: true,
        closeRightNav: true,
      });
      webDisplayVM.globalSheetVM.closeSheet();
    },
  });

  if (vm === undefined) return <FullContainerLoadingSpinner />;

  return <ScheduleCommunityEventFormLoaded vm={vm} />;
};

const ScheduleCommunityEventFormLoaded: React.FC<{
  vm: FormVM;
}> = ({ vm }) => {
  const isSubmitting = useUnit(vm.submitFx.pending);
  const validationError = useUnit(vm.$validationError);
  const name = useUnit(vm.$name);

  return (
    <div className="flex-1 flex flex-col gap-6">
      <div className="self-stretch">
        <input
          type="text"
          value={name}
          onChange={(e) => vm.setName(e.target.value)}
          onFocus={() =>
            FormFocusVM.getInstance().setFocusedField("event-name")
          }
          placeholder="Event name..."
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <CalendarDropdown vm={vm.calendarPickerVM} />
      <DurationSelector vm={vm.durationPickerVM} />
      <div className="">
        <RecurringInput vm={vm.recurringInputVM} />
      </div>
      <div className="flex-1" />
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex flex-col gap-4">
          {validationError && (
            <div className="text-red-500">{validationError}</div>
          )}
          <PrimaryButton
            title="Schedule event"
            onClick={() => {
              vm.submitted();
            }}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

class RecurringInputVM {
  $selectedFrequency = createStore<RecurrenceFrequencyChoice>("none");
  setFrequency = createEvent<RecurrenceFrequencyChoice>();

  constructor() {
    this.$selectedFrequency.on(this.setFrequency, (_, frequency) => frequency);
  }
}

const RecurringInput: React.FC<{ vm: RecurringInputVM }> = ({ vm }) => {
  const frequency = useUnit(vm.$selectedFrequency);
  const ALL_RECURRING_STATUSES: RecurrenceFrequencyChoice[] = [
    "none",
    "daily",
    "weekly",
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={() => FormFocusVM.getInstance().setFocusedField("recurring")}
        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 flex justify-between items-center"
      >
        <span className="text-gray-700">Recurring</span>
        <span className="capitalize">{frequency}</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]">
        {ALL_RECURRING_STATUSES.map((status) => (
          <DropdownMenuItem
            key={status}
            onClick={() => vm.setFrequency(status)}
            className="capitalize"
          >
            {status}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
