import { Match } from "effect";
import { useEffect, useState } from "react";
import { FullContainerLoadingSpinner } from "./loading";

export const AppButton: React.FC<{
  onClick: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  isInvertedStyle?: boolean;
}> = ({ onClick, title, maxWidthPixels, disabled, type, isInvertedStyle }) => {
  const button = isInvertedStyle ? (
    <InversePrimaryButton onClick={onClick} title={title} disabled={disabled} />
  ) : (
    <PrimaryButton
      onClick={onClick}
      title={title}
      disabled={disabled}
      type={type}
    />
  );

  if (maxWidthPixels) {
    return <div style={{ maxWidth: `${maxWidthPixels}px` }}>{button}</div>;
  }

  return button;
};

export const PrimaryButton: React.FC<{
  onClick?: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  mode?: "primary" | "secondary" | "success" | undefined;
  isLoading?: boolean;
  dims?: { width?: number; height?: number };
  fontSize?: number;
}> = ({
  onClick,
  title,
  disabled: propDisabled,
  type,
  isLoading,
  dims,
  fontSize,
  mode,
}) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);
  const backgroundColor = isDisabled
    ? "bg-vid-black-50"
    : Match.value(mode).pipe(
        Match.when("primary", () => "bg-vid-purple"),
        Match.when("secondary", () => "bg-vid-black-50"),
        Match.when("success", () => "bg-vid-black-50"),
        Match.when(undefined, () => "bg-vid-purple"),
        Match.exhaustive
      );
  const textColor = isDisabled
    ? "text-vid-black-50"
    : Match.value(mode).pipe(
        Match.when("primary", () => "text-white"),
        Match.when("secondary", () => "text-vid-black-50"),
        Match.when("success", () => "text-vid-black-800"),
        Match.when(undefined, () => "text-white"),
        Match.exhaustive
      );
  const baseClasses = `inline-flex rounded-buttons p-buttons ${backgroundColor} ${textColor} font-medium text-[16px] justify-center items-center gap-2.5 border`;
  const disabledClasses = "cursor-not-allowed border-vid-black-50";
  const enabledClasses = "border-vid-purple";
  const className = isDisabled
    ? `${baseClasses} ${disabledClasses}`
    : `${baseClasses} ${enabledClasses}`;

  useEffect(() => {
    setIsDisabled(propDisabled);
  }, [propDisabled]);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`${className} select-none`}
      disabled={isDisabled}
      type={type}
      style={{
        height: dims?.height ? `${dims.height}px` : "49px",
        width: dims?.width ? `${dims.width}px` : "auto",
        fontSize: fontSize ? `${fontSize}px` : "16px",
        userSelect: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
    >
      {isLoading ? <FullContainerLoadingSpinner /> : <span>{title}</span>}
    </button>
  );
};

export const InversePrimaryButton: React.FC<{
  onClick: () => void;
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
  width?: string;
  icon?: React.ReactNode;
  color?: "black" | "purple" | "red";
}> = ({
  onClick,
  title,
  disabled: propDisabled,
  isLoading,
  width,
  color = "purple",
  icon,
}) => {
  const [isTemporarilyDisabled, setIsTemporarilyDisabled] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsTemporarilyDisabled(true);
      setTimeout(() => setIsTemporarilyDisabled(false), 500);
    }
  };

  const hexColor = Match.value(color).pipe(
    Match.when("black", () => "#1D1626"),
    Match.when("purple", () => "#690DFF"),
    Match.when("red", () => "#FF4343"),
    Match.exhaustive
  );

  const hoverBgColor = Match.value(color).pipe(
    Match.when("black", () => "hover:bg-vid-black-50"),
    Match.when("purple", () => "hover:bg-[#F4F0FF]"),
    Match.when("red", () => "hover:bg-[#FFF0F0]"),
    Match.exhaustive
  );

  return (
    <button
      onClick={handleClick}
      disabled={propDisabled || isTemporarilyDisabled}
      style={{
        width: width ?? "172px",
        color: hexColor,
        borderColor: hexColor,
      }}
      className={`
      bg-white border rounded-buttons py-2 px-8 lg:p-buttons
      flex justify-center items-center gap-2
      text-center font-medium leading-tight
      font-outfit transition-colors duration-200
      ${hoverBgColor}
      ${propDisabled ? "cursor-not-allowed opacity-50" : ""}
      `}
    >
      {icon}
      {isLoading ? <FullContainerLoadingSpinner /> : title}
    </button>
  );
};

export const ButtonWithIcon: React.FC<{
  content: {
    title: string;
    description: string;
    icon: React.ReactNode;
  };
  onClick: () => void;
}> = ({ content, onClick }) => {
  const { title, description, icon } = content;
  return (
    <div
      className="h-[70px] w-[312px] flex items-center rounded-[12px] px-4 border border-vid-black-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-1 h-[40px] flex items-center gap-5">
        <div className="w-[40px] h-[40px] bg-gray-200 rounded-full flex items-center justify-center">
          {icon}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-sm text-vid-black-800 ">{title}</div>
          <div className="text-xs text-vid-black-600">{description}</div>
        </div>
      </div>
    </div>
  );
};
