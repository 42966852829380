import { CalendarDropdown } from "@/src/components/form/calendar-picker.input";
import {
  ButtonWithIcon,
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import { useOnce } from "frontend-shared/src/util";
import { CalendarIcon, ClipboardListIcon } from "lucide-react";
import type React from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  KnownToolTag,
  ToolsMenuViewState,
} from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import { ChatRoom } from "web-shared/src/domains/chat/chat-room";
import { AnnotationToolsPanel } from "./tools/annotation-tools-panel";
import { SetupBreakoutRoomsToolsPanel } from "./tools/breakout-rooms-panel";
import { EmdrPanel } from "./tools/emdr/emdr-panel";
import { MeditationPanel } from "./tools/meditation-panel";

export const ToolMenu: React.FC<{
  sessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
  viewState: ToolsMenuViewState;
  setMyToolMenuViewState: (toolMenuViewState: ToolsMenuViewState) => void;
}> = ({ sessionId, roomId, viewState, setMyToolMenuViewState }) => {
  return Match.value(viewState).pipe(
    Match.when({ tag: "FULL_MENU" }, () => (
      <ToolSelectionMenu
        knownTool={[
          "NOTES",
          "MEDITATION",
          "EMDR",
          "CHAT",
          "QUICK_SCHEDULE",
          "BREAKOUT_ROOMS",
          "REVIEW",
        ]}
        onToolClick={(tool) => {
          setMyToolMenuViewState({ tag: "TOOL_SELECTION", knownTool: tool });
        }}
      />
    )),
    Match.when({ tag: "TOOL_SELECTION" }, ({ knownTool }) => (
      <SelectedToolView
        knownTool={knownTool}
        sessionId={sessionId}
        roomId={roomId}
      />
    )),
    Match.exhaustive
  );
};

const SelectedToolView: React.FC<{
  knownTool: KnownToolTag;
  sessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ knownTool, sessionId, roomId }) => {
  return (
    <div
      className="flex-1 flex flex-col gap-4 self-stretch"
      onClick={() => {
        console.log("clicked", knownTool);
      }}
    >
      {matchSpecificToolToView(knownTool, sessionId, roomId)}
    </div>
  );
};

const ToolSelectionMenu: React.FC<{
  knownTool: KnownToolTag[];
  onToolClick: (tool: KnownToolTag) => void;
}> = ({ knownTool, onToolClick }) => {
  return (
    <div className="flex flex-col gap-4">
      {knownTool.map((tool) => (
        <ToolMenuButton
          key={tool}
          knownTool={tool}
          onClick={() => {
            onToolClick(tool);
          }}
        />
      ))}
    </div>
  );
};

const ToolMenuButton: React.FC<{
  knownTool: KnownToolTag;
  onClick: () => void;
}> = ({ knownTool, onClick }) => {
  const props = matchToolMenuProps(knownTool);
  return <ToolMenuButtonView content={props} onClick={onClick} />;
};

const matchToolMenuProps = (knownTool: KnownToolTag): ToolMenuButtonViewProps =>
  Match.value(knownTool).pipe(
    Match.when("EMDR", () => ({
      title: "EMDR",
      description: "EMDR",
      icon: { tag: "name" as const, name: "emdr-balls-icon" },
    })),
    Match.when("MEDITATION", () => ({
      title: "Meditation",
      description: "Meditation",
      icon: { tag: "name" as const, name: "meditation-icon" },
    })),
    Match.when("CHAT", () => ({
      title: "Chat",
      description: "Chat",
      icon: { tag: "name" as const, name: "note" },
    })),
    Match.when("NOTES", () => ({
      title: "Notes",
      description: "Notes",
      icon: { tag: "name" as const, name: "note" },
    })),
    Match.when("QUICK_SCHEDULE", () => ({
      title: "Quick Schedule",
      description: "Quick Schedule",
      icon: {
        tag: "component" as const,
        component: <CalendarIcon color={"#3E3648"} size={20} />,
      },
    })),
    Match.when("BREAKOUT_ROOMS", () => ({
      title: "Breakout Rooms",
      description: "Breakout Rooms",
      icon: {
        tag: "component" as const,
        component: <CalendarIcon color={"#3E3648"} size={20} />,
      },
    })),
    Match.when("REVIEW", () => ({
      title: "Review",
      description: "Review",
      icon: {
        tag: "component" as const,
        component: <ClipboardListIcon color={"#3E3648"} size={20} />,
      },
    })),
    Match.exhaustive
  );

interface ToolMenuButtonViewProps {
  title: string;
  description: string;
  icon:
    | { tag: "name"; name: string }
    | { tag: "component"; component: React.ReactNode };
}

const ToolMenuButtonView: React.FC<{
  content: ToolMenuButtonViewProps;
  onClick: () => void;
}> = ({ content, onClick }) => {
  const { title, description, icon } = content;

  const iconComponent = Match.value(icon).pipe(
    Match.when({ tag: "name" }, ({ name }) => (
      <img src={`/${name}.svg`} alt={title} />
    )),
    Match.when({ tag: "component" }, ({ component }) => component),
    Match.exhaustive
  );

  return (
    <ButtonWithIcon
      content={{ title, description, icon: iconComponent }}
      onClick={onClick}
    />
  );
};

function matchSpecificToolToView(
  knownTool: KnownToolTag,
  sessionId: Id<"sessionConfig">,
  roomId: Id<"rtcLiveRooms">
): React.ReactNode {
  return Match.value(knownTool).pipe(
    Match.when("EMDR", () => (
      <EmdrPanel sessionId={sessionId} roomId={roomId} />
    )),
    Match.when("NOTES", () => <AnnotationToolsPanel />),
    Match.when("MEDITATION", () => (
      <MeditationPanel sessionId={sessionId} roomId={roomId} />
    )),
    Match.when("CHAT", () => <ChatPanel sessionId={sessionId} />),
    Match.when("QUICK_SCHEDULE", () => <QuickScheduleToolsPanel />),
    Match.when("BREAKOUT_ROOMS", () => (
      <SetupBreakoutRoomsToolsPanel sessionId={sessionId} roomId={roomId} />
    )),
    Match.when("REVIEW", () => (
      <ReviewWrapupToolsPanel sessionId={sessionId} roomId={roomId} />
    )),
    Match.exhaustive
  );
}

const ReviewWrapupToolsPanel: React.FC<{
  sessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ sessionId, roomId }) => {
  const showReviewToAll = useMutation(
    api.Sessions.Rooms.Activity.ReviewSessionActivityFns.showReviewToAll
  );

  const stopShowingReviewToAll = useMutation(
    api.Sessions.Rooms.Activity.ReviewSessionActivityFns.stopShowingReviewToAll
  );

  return (
    <div className="flex-1 flex flex-col gap-4">
      <PrimaryButton
        title="Show review to all"
        onClick={() => {
          console.log("clicked");

          showReviewToAll({
            baseSessionId: sessionId,
            roomId,
          });
        }}
      />
      <InversePrimaryButton
        title="Stop showing review to all"
        onClick={() => {
          stopShowingReviewToAll({ roomId });
        }}
      />
    </div>
  );
};

class QuickScheduleToolsPanelVM {
  calendarPickerVM = new CalendarPickerVM();
}

const QuickScheduleToolsPanel: React.FC = () => {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const vm = useOnce(() => new QuickScheduleToolsPanelVM());

  const scheduleMutation = useMutation(
    api.Sessions.Rooms.Activity.QuickScheduleActivityFns.scheduleNewHcSession
  );

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex-1">
        <CalendarDropdown vm={vm.calendarPickerVM} autoOpen={true} />
      </div>
      <PrimaryButton
        title="Schedule"
        onClick={() => {
          console.log("clicked");
          scheduleMutation({
            baseSessionId,
            roomId,
            startsAt: new Date().getTime(),
          });
        }}
      />
    </div>
  );
};

const ChatPanel: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  const chatRoom = useQuery(api.Sessions.Rooms.LiveRoomFns.getChatRoomOT, {
    baseSessionId: sessionId,
  });

  if (chatRoom === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col gap-4">
      <ChatRoom roomId={chatRoom._id} />
    </div>
  );
};
