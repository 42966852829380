import {
  createStore,
  createEvent,
  type Store,
  type EventCallable,
  sample,
} from "effector";
import {
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  isSameDay,
} from "date-fns";

interface TimeSelection {
  hours: number | null;
  minutes: number | null;
  period: "AM" | "PM" | null;
}

interface CalendarPickerState {
  currentMonth: Date;
  selectedDay: Date | null;
  selectedTime: TimeSelection | null;
}

export class CalendarPickerVM {
  // Public stores
  readonly $picker: Store<CalendarPickerState>;
  readonly $calendarDays: Store<Date[]>;

  // Derived stores
  readonly $selectedDate: Store<Date | null>; // valid when a day and time are selected
  readonly $formattedTime: Store<string>;
  readonly $dateTimeDisplayStr: Store<string>;

  // Public events
  readonly events: {
    daySelected: EventCallable<Date>;
    monthNavigated: EventCallable<"next" | "prev">;
    hoursChanged: EventCallable<number>;
    minutesChanged: EventCallable<number>;
    periodToggled: EventCallable<"AM" | "PM">;
    reset: EventCallable<void>;
  };

  constructor() {
    // Initialize events
    this.events = {
      daySelected: createEvent<Date>(),
      monthNavigated: createEvent<"next" | "prev">(),
      hoursChanged: createEvent<number>(),
      minutesChanged: createEvent<number>(),
      periodToggled: createEvent<"AM" | "PM">(),
      reset: createEvent<void>(),
    };

    // Initialize main store with event handlers
    this.$picker = createStore<CalendarPickerState>({
      currentMonth: new Date(),
      selectedDay: null,
      selectedTime: {
        hours: null,
        minutes: null,
        period: null,
      },
    })
      .on(this.events.daySelected, (state, date) => ({
        ...state,
        selectedDay: date,
      }))
      .on(this.events.monthNavigated, (state, direction) => ({
        ...state,
        currentMonth:
          direction === "next"
            ? addMonths(state.currentMonth, 1)
            : subMonths(state.currentMonth, 1),
      }))
      .on(this.events.hoursChanged, (state, hours) => ({
        ...state,
        selectedTime: {
          hours,
          minutes: state.selectedTime?.minutes ?? null,
          period: state.selectedTime?.period ?? null,
        },
      }))
      .on(this.events.minutesChanged, (state, minutes) => ({
        ...state,
        selectedTime: {
          hours: state.selectedTime?.hours ?? null,
          minutes,
          period: state.selectedTime?.period ?? null,
        },
      }))
      .on(this.events.periodToggled, (state, period) => ({
        ...state,
        selectedTime: {
          hours: state.selectedTime?.hours ?? null,
          minutes: state.selectedTime?.minutes ?? null,
          period,
        },
      }))
      .reset(this.events.reset);

    this.$selectedDate = this.$picker.map((state) =>
      this.asSelectedDateTime(state)
    );

    // Initialize derived stores
    this.$calendarDays = this.$picker.map((state) =>
      this.computeCalendarDays(state)
    );
    this.$formattedTime = this.$picker.map((state) => this.formatTime(state));
    this.$dateTimeDisplayStr = this.$selectedDate.map((date) =>
      date ? this.formatDateTime(date) : ""
    );
  }

  private computeCalendarDays(state: CalendarPickerState): Date[] {
    const monthStart = startOfMonth(state.currentMonth);
    const monthEnd = endOfMonth(state.currentMonth);
    const firstDayOfWeek = monthStart.getDay(); // 0 = Sunday, 1 = Monday, etc.

    // Get all days in the month
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

    // Add padding days at the start
    const paddingDays = Array(firstDayOfWeek).fill(null);

    return [...paddingDays, ...daysInMonth];
  }

  private formatTime(state: CalendarPickerState): string {
    if (
      !state.selectedTime ||
      state.selectedTime.hours === null ||
      state.selectedTime.minutes === null
    )
      return "";
    const { hours, minutes, period } = state.selectedTime;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
  }

  // Public methods for external access
  asSelectedDateTime(state: CalendarPickerState): Date | null {
    if (
      !state.selectedDay ||
      !state.selectedTime ||
      state.selectedTime.hours === null ||
      state.selectedTime.minutes === null ||
      state.selectedTime.period === null
    )
      return null;

    let { hours, minutes, period } = state.selectedTime;
    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    // Construct local date instead of UTC
    const localDate = new Date(
      state.selectedDay.getFullYear(),
      state.selectedDay.getMonth(),
      state.selectedDay.getDate(),
      hours,
      minutes
    );

    return localDate;
  }

  formatDateTime(date: Date): string {
    // format as e.g. April 18, 2024 | 11:30am
    return format(date, "MMMM d, yyyy | h:mma");
  }

  reset = () => {
    this.events.reset();
  };

  isCurrentMonth(date: Date): boolean {
    const state = this.$picker.getState();
    return format(date, "yyyy-MM") === format(state.currentMonth, "yyyy-MM");
  }

  isSelected(date: Date): boolean {
    const state = this.$picker.getState();
    return state.selectedDay
      ? format(date, "yyyy-MM-dd") === format(state.selectedDay, "yyyy-MM-dd")
      : false;
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return isSameDay(date, today);
  }
}
