import { useCall, VideoPreview } from "@stream-io/video-react-sdk";
import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { Match } from "effect";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { useObservableEagerState } from "observable-hooks";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { BackstageInfo } from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import type { HcSessionShortReview } from "shared/be/convex/Sessions/SessionReview.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import {
  AvatarCircle,
  AvatarCircles,
} from "web-shared/src/components/avatar.tc";
import { PrimaryButton } from "web-shared/src/components/button";
import { InfoSection } from "web-shared/src/components/info-section";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "web-shared/src/components/ui/accordion";
import { ThreeDotsMenu } from "web-shared/src/components/ui/dropdown-menu";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";
import { useLiveRoomStreamVM } from "web-shared/src/web-context";
import { ConnectingAnimation } from "../../../camera";
import { MuteAudioButton, MuteVideoButton } from "../../control-panel";
import { BackstageGroupSession } from "./backstage-group-session";

export const BackstageRoom: React.FC<{
  roomId: Id<"rtcLiveRooms">;
}> = ({ roomId }) => {
  const onChooseJoinStage = useMutation(
    api.Sessions.Rooms.LiveRoomFns.onChooseJoinStage
  );

  const { baseSessionId } = useLiveRoomVM();
  const backstageInfo = useQuery(
    api.Sessions.Rooms.LiveRoomFns.getBackstageInfo,
    {
      roomId,
      baseSessionId,
    }
  );

  if (backstageInfo === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="w-screen h-screen flex flex-col">
      <BackstageContainerView
        onBackClick={() => {}}
        leftSide={<LeftSide backstageInfo={backstageInfo} />}
        rightSide={<RightSide backstageInfo={backstageInfo} />}
        gotoSession={{
          title: "Join",
          onClick: () => {
            onChooseJoinStage({ roomId });
          },
        }}
      />
    </div>
  );
};

const LeftSide: React.FC<{
  backstageInfo: BackstageInfo;
}> = ({ backstageInfo }) => {
  const call = useCall();
  const liveRoomVM = useLiveRoomVM();
  const liveRoomStreamVM = useLiveRoomStreamVM();

  const isBackgroundBlurredSetting = useObservableEagerState(
    liveRoomStreamVM.isBackgroundBlurred$
  );
  const isVideoMuted = useObservableEagerState(liveRoomVM.isVideoMuted$);
  const isAudioMuted = useObservableEagerState(liveRoomVM.isAudioMuted$);

  const isBackgroundBlurred =
    isBackgroundBlurredSetting === undefined
      ? false
      : isBackgroundBlurredSetting;

  return (
    <div className="flex flex-col items-end justify-center">
      <div className="flex flex-col gap-2">
        <div className="w-[598px] h-[430px] bg-vid-black-900 rounded-[12px] flex flex-col ">
          {!call ? (
            <ConnectingAnimation isConnected={false} />
          ) : (
            <div className="flex-1 w-full relative rounded-[12px] overflow-hidden ">
              <VideoPreview
                DisabledVideoPreview={DisabledVideoPreview}
                // NoCameraPreview={<></>}
                // StartingCameraPreview={CustomParticipantViewUI}
                className="str-video__preview"
              />
              <div className="absolute top-4 right-4">
                <ThreeDotsMenu
                  menuItems={[
                    {
                      label: isBackgroundBlurred ? "Unblur" : "Blur background",
                      onClick: () => {
                        liveRoomStreamVM.setIsBackgroundBlurred(
                          !isBackgroundBlurred
                        );
                      },
                    },
                  ]}
                />
              </div>
              <div className="absolute bottom-4 right-4 flex items-center gap-4">
                <MuteAudioButton
                  isMuted={isAudioMuted ?? false}
                  onClick={() => {
                    liveRoomVM.setIsAudioMuted(
                      isAudioMuted ? "unmute" : "mute"
                    );
                  }}
                />
                <MuteVideoButton
                  isMuted={isVideoMuted ?? false}
                  onClick={() => {
                    liveRoomVM.setIsVideoMuted(
                      isVideoMuted ? "unmute" : "mute"
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex self-center">
          {backstageInfo.membersLive.length === 0 ? (
            <div className="text-[24px]">Waiting for members to join...</div>
          ) : (
            <div className="flex items-center gap-2">
              <AvatarCircles
                sources={backstageInfo.membersLive.map((m) => m.profilePhoto)}
                size={72}
              />
              <div className="text-[24px]">{`${backstageInfo.membersLive.length} members have joined`}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DisabledVideoPreview: React.FC = () => {
  return <div>Disabled Video Preview</div>;
};

const RightSide: React.FC<{
  backstageInfo: BackstageInfo;
}> = ({ backstageInfo }) => {
  return (
    <div className="flex flex-col">
      {Match.value(backstageInfo.mode).pipe(
        Match.when({ tag: "HC" }, ({ info }) => {
          return (
            <PastSessionReviewSection
              pastSessionReviews={info.pastSessionPreviews}
            />
          );
        }),
        Match.when({ tag: "GROUP" }, ({ info }) => {
          return <BackstageGroupSession groupBackstageInfo={info} />;
        }),
        Match.exhaustive
      )}
    </div>
  );
};

const PastSessionReviewSection: React.FC<{
  pastSessionReviews: HcSessionShortReview[];
}> = ({ pastSessionReviews }) => {
  return (
    <div className="flex flex-col">
      <Accordion type="single" collapsible>
        <InfoSection header={{ _tag: "STANDARD", title: "Past Sessions" }}>
          {pastSessionReviews.map((p) => {
            return (
              <AccordionItem key={p.sessionInfo.id} value={p.sessionInfo.id}>
                <AccordionTrigger>
                  <div className="flex-1 self-stretch flex gap-4 items-center">
                    <AvatarCircle
                      mbProfilePhoto={
                        p.participantInfo.client.profilePhoto
                          ? ImageSrc.fromURL(
                              p.participantInfo.client.profilePhoto
                            )
                          : null
                      }
                      size={40}
                    />
                    <div>
                      <div className="rounded-full w-[170px] py-2 bg-vid-black-100 text-vid-black-900 text-center">
                        {format(
                          new Date(p.sessionInfo.endedAt),
                          "MMM dd, yyyy"
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div>{p.shortReviewPoints.join(", ")}</div>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </InfoSection>
      </Accordion>
    </div>
  );
};

const BackstageContainerView: React.FC<{
  onBackClick: () => void;
  leftSide: React.ReactNode;
  rightSide: React.ReactNode;
  gotoSession: {
    title: string;
    onClick: () => void;
  };
}> = ({ onBackClick, leftSide, rightSide, gotoSession }) => {
  return (
    <div className="flex flex-col h-screen">
      {/* Header (fixed height) */}
      <nav
        className="h-16 md:h-20 flex items-center justify-between px-8"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)" }}
      >
        <InsightLiveLogoWithText />
        <button className="text-vid-purple font-bold" onClick={onBackClick}>
          Back to dashboard
        </button>
      </nav>

      {/* Middle Section (flexible) */}
      <div className="flex-1 min-h-0 flex gap-2 px-2 md:px-4 py-4 md:py-24 overflow-auto bg-gray-50 justify-center">
        <div className="flex-1 min-h-0 min-w-0 flex flex-col pr-12">
          {leftSide}
        </div>
        <div className="hidden md:flex md:flex-col flex-1 min-w-0 min-h-0">
          {rightSide}
        </div>
      </div>

      {/* Footer (fixed height) */}
      <footer className="h-[150px] border-t border-vid-black-200 bg-white relative">
        {/* Background pattern */}
        <div className="absolute inset-0 w-full h-full opacity-50 pointer-events-none">
          <img
            src={"/dot-pattern.png"}
            className="w-full h-full object-cover"
            aria-hidden="true"
          />
        </div>

        {/* Content container */}
        <div className="relative z-10 h-full flex justify-between items-center px-4 md:px-48">
          <button
            className="border border-vid-black-900 text-vid-black-900 hover:bg-vid-black-900 hover:text-white bg-white h-[50px] w-[80px] rounded-lg"
            onClick={onBackClick}
          >
            Back
          </button>
          <div className="relative">
            <PrimaryButton
              title={gotoSession.title}
              onClick={gotoSession.onClick}
              fontSize={24}
              dims={{
                height: 80,
                width: 180,
              }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};
