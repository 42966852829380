import { useMutation, useQuery } from "convex/react";
import { useCallback, useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";

export function useOnChatRoomMount(roomId: Id<"chatRooms">) {
  const messages = useQuery(api.Chat.ChatRoomFns.getLastTenMessages, {
    roomId: roomId as Id<"chatRooms">,
  });
  const markMessagesAsRead = useMutation(
    api.Chat.ChatRoomFns.markRoomMessagesAsRead
  );

  const sendMessage = useMutation(api.Chat.ChatRoomFns.sendMessage);

  const registerPing = useMutation(api.Chat.ChatRoomFns.registerPing);

  useEffect(() => {
    const interval = setInterval(() => {
      registerPing({
        roomId: roomId as Id<"chatRooms">,
      }).catch((e) => {
        console.error("Error registering ping: ", e);
      });
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, [roomId]);

  useEffect(() => {
    if (messages !== undefined && messages.length > 0) {
      const lastMessage = messages.reduce<(typeof messages)[0] | null>(
        (latest, msg) =>
          !latest || msg.createdAt > latest.createdAt ? msg : latest,
        null
      );
      if (lastMessage) {
        markMessagesAsRead({
          roomId: roomId as Id<"chatRooms">,
          upToTimestamp: lastMessage.createdAt,
          messageId: lastMessage._id,
        }).then(() => {
          console.log("Marked messages as read");
        });
      }
    }
  }, [messages]);

  const onSend = useCallback((message: string) => {
    sendMessage({
      roomId: roomId as Id<"chatRooms">,
      message,
    });
  }, []);

  return {
    messages,
    onSend,
  };
}
