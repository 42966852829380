import {
  hasAudio,
  hasScreenShare,
  ParticipantView,
  type StreamVideoParticipant,
  type VideoPlaceholderProps,
} from "@stream-io/video-react-sdk";
import type React from "react";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";

export const CustomParticipantView: React.FC<{
  participant: StreamVideoParticipant;
  user: SimpleUser;
}> = ({ participant, user }) => {
  const isMuted = !hasAudio(participant);
  return (
    <ParticipantView
      participant={participant}
      trackType={
        hasScreenShare(participant) ? "screenShareTrack" : "videoTrack"
      }
      ParticipantViewUI={() => (
        <CustomParticipantViewUI user={user} isMuted={isMuted} />
      )}
      VideoPlaceholder={() => (
        <CustomVideoPlaceholder participant={participant} user={user} />
      )}
      mirror={false}
    />
  );
};

export const CustomParticipantViewUI: React.FC<{
  user: SimpleUser;
  isMuted: boolean;
}> = ({ user, isMuted }) => {
  return (
    <>
      <div className="absolute top-4 left-4">
        <div className=" text-white  font-semibold flex justify-center items-center gap-4">
          {user.name}
        </div>
      </div>
      <div className="absolute top-4 left-1/2 -translate-x-1/2">
        {isMuted && <MutedIcon />}
      </div>
    </>
  );
};

export const CustomVideoPlaceholder: React.FC<
  VideoPlaceholderProps & { user: SimpleUser }
> = ({ style, user }) => {
  return (
    <div
      className="absolute inset-0 flex-1 flex flex-col justify-center items-center gap-4 bg-vid-black-900 text-white border rounded-[20px]"
      style={style}
    >
      <AvatarCircle
        size={40}
        mbProfilePhoto={ImageSrc.fromMbUrl(user.profilePhoto)}
      />
      <h4 className="font-bold text-xl">{user.name}</h4>
    </div>
  );
};

const MutedIcon: React.FC = () => {
  return (
    <div className="bg-gray-800 text-white px-4 py-2 rounded-full flex items-center">
      <svg
        className="w-4 h-4 mr-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
          clipRule="evenodd"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"
        />
      </svg>
      <span>Muted</span>
    </div>
  );
};
