import { CommunityEventCardContainer } from "@/src/domains/communities/community/events/community-event-card";
import { ScheduleCommunityEventForm } from "@/src/domains/communities/community/events/schedule-community-event.form";
import { CommunityEventDetailsPanel } from "@/src/domains/communities/community/events/view-community-event-details";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useQuery } from "convex/react";
import { useCommunityDashVM } from "frontend-shared/src/domains/community/community-dash.vm";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { UpcomingCommunityCalEventInstanceCard } from "shared/be/convex/Screens/Community/CommunityEventsScreen.Types";
import { UROUTES } from "shared/routes/u.routes";
import { FullCalendar } from "web-shared/src/domains/scheduling/calendar/full-calendar";
import { useUMgr, useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const CommunityEventsTabLayout: React.FC = () => {
  return (
    <div className="flex flex-col gap-8">
      <Outlet />
    </div>
  );
};

export const CommunityEventsTabAll: React.FC = () => {
  const hpState = useHpState();
  const uMgr = useUMgr();
  const vm = useCommunityDashVM();
  const webDisplayVM = useWebGlobalDisplayVM();
  const [{ showEventRightNav, showEventInstanceRightNav }] =
    useTypedSearchParams(
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL
    );

  useEffect(() => {
    if (showEventInstanceRightNav !== undefined) {
      webDisplayVM.globalSheetVM.openSheet(
        {
          title: "Event details",
          type: {
            tag: "view",
          },
        },
        <CommunityEventDetailsPanel
          instanceId={showEventInstanceRightNav as Id<"calEventInstances">}
          communitySlug={vm.communityInfo.slug}
          closePanel={() => {
            hpState.dashboardState.showBottomToast({
              msg: "Deleted event",
              reload: true,
              closeRightNav: true,
            });
          }}
        />
      );
    }
  }, [showEventRightNav, showEventInstanceRightNav]);

  const now = useOnce(() => Date.now());

  const allInstances = useQuery(
    api.Screens.Community.CommunityEventsScreenFns
      .getAllCommunityEventsScreenData,
    {
      communitySlug: vm.communityInfo.slug,
      now,
    }
  );

  if (allInstances === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8 rounded-lg min-h-[500px]">
      <LiveAndUpcomingEventsSection
        cards={[...allInstances.upcomingCommunityCalEventInstances.cards]}
      />
      <FullCalendar
        stateMgr={vm.calendarMgr}
        fetchOnMount={true}
        onApptClick={(appt) => {
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "Event details",
              type: {
                tag: "image",
                imageUrl: "",
              },
            },
            <CommunityEventDetailsPanel
              instanceId={appt.instanceId as Id<"calEventInstances">}
              communitySlug={vm.communityInfo.slug}
              closePanel={() => {
                hpState.dashboardState.showBottomToast({
                  msg: "Deleted event",
                  reload: true,
                  closeRightNav: true,
                });
              }}
            />
          );
        }}
        onDayClick={() => {
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "Create an event",
              type: {
                tag: "create",
              },
            },
            <ScheduleCommunityEventForm communitySlug={vm.communityInfo.slug} />
          );
        }}
        newApptButton={{
          title: "Create event",
          onClick: () => {
            webDisplayVM.globalSheetVM.openSheet(
              {
                title: "Create an event",
                type: {
                  tag: "create",
                },
              },
              <ScheduleCommunityEventForm
                communitySlug={vm.communityInfo.slug}
              />
            );
          },
        }}
      />
      {/* <GoLiveNowButton /> */}
    </div>
  );
};

export const LiveAndUpcomingEventsSection: React.FC<{
  cards: (typeof UpcomingCommunityCalEventInstanceCard.Encoded)[];
}> = ({ cards }) => {
  return (
    <div className="flex flex-col gap-4 relative">
      <h3 className="text-[24px]">Live and upcoming</h3>
      {cards.length === 0 ? (
        <div className="flex justify-center items-center min-h-[100px] text-gray-500">
          No live or upcoming events
        </div>
      ) : (
        <div className="relative h-[400px]">
          <div className="absolute inset-x-0 flex gap-4 overflow-x-auto">
            {cards.map((evt) => (
              <CommunityEventCardContainer
                key={evt.instance._id}
                initialCardData={evt}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
