import { useUnit } from "effector-react";
import type {
  BaseSearchableDropdownVM,
  SearchableEntity,
} from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { Check } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "web-shared/src/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "web-shared/src/components/ui/popover";
import { cn } from "../utils/utils";
import { FormFieldContainer } from "./form-field.container";

export interface SearchableDropdownItem {
  id: string;
  label: string;
}

interface SearchableDropdownPropsV2<T extends SearchableEntity> {
  vm: BaseSearchableDropdownVM<T>;
  label: string;
  placeholder?: string;
  className?: string;
}

export const SearchableDropdownV2 = <T extends SearchableEntity>({
  vm,
  label,
  placeholder = "Search...",
  className = "",
}: SearchableDropdownPropsV2<T>) => {
  const isOpen = useUnit(vm.$isOpen);
  const items = useUnit(vm.$filteredItems);
  const selectedItem = useUnit(vm.$selectedItem);
  const searchText = useUnit(vm.$searchText);

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => (open ? vm.menuOpened() : vm.menuClosed())}
    >
      <PopoverTrigger asChild>
        <button type="button" className="w-full text-left">
          <FormFieldContainer label={label}>
            <div className={cn("text-sm text-left", className)}>
              <span className="text-black">
                {selectedItem ? selectedItem.label : placeholder}
              </span>
            </div>
          </FormFieldContainer>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={placeholder}
            value={searchText}
            onValueChange={vm.searchTextChanged}
          />
          <CommandList>
            <CommandEmpty>Nothing found.</CommandEmpty>
            <CommandGroup>
              {items.slice(0, 5).map((item) => (
                <CommandItem
                  key={item.id}
                  value={item.label}
                  onSelect={() => {
                    vm.itemSelected(item);
                    vm.menuClosed();
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedItem?.id === item.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
