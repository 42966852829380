import { useUnit } from "effector-react";
import React from "react";
import ReactMarkdown from "react-markdown";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  LessonWithEnrollmentProgressETO,
  ModuleWithLessonsAndProgressETO,
} from "shared/be/convex/Learning/Courses/Courses.Types";
import { Quiz } from "../../components/quiz.component";
import { useSetupLeftNavStepsCourseTemplateVM } from "./left-nav-steps.course-template.vm";

export const LeftNavStepsCourseTemplate: React.FC<{
  courseId: Id<"courseInfo">;
}> = ({ courseId }) => {
  const vm = useSetupLeftNavStepsCourseTemplateVM(courseId);
  const { activeLesson, modules, currentLesson, expandedModuleIds } = useUnit({
    activeLesson: vm.$activeLesson,
    modules: vm.$modules,
    currentLesson: vm.$currentLesson,
    expandedModuleIds: vm.$expandedModuleIds,
  });

  if (!modules) return <div>Loading...</div>;

  return (
    <div className="flex h-screen bg-vid-black-50">
      <LeftNavigation
        modules={modules}
        activeLesson={activeLesson}
        expandedModuleIds={expandedModuleIds}
        onLessonClick={vm.setActiveLessonEvt}
        onModuleToggle={vm.toggleModuleEvt}
      />
      <MainContent
        currentLesson={currentLesson}
        onMarkComplete={vm.markLessonCompletedEvt}
      />
    </div>
  );
};

export const LeftNavigation: React.FC<{
  modules: ModuleWithLessonsAndProgressETO[];
  activeLesson: Id<"courseLessons"> | null;
  expandedModuleIds: Set<Id<"courseModules">>;
  onLessonClick: (lessonId: Id<"courseLessons">) => void;
  onModuleToggle: (moduleId: Id<"courseModules">) => void;
}> = ({
  modules,
  activeLesson,
  expandedModuleIds,
  onLessonClick,
  onModuleToggle,
}) => {
  return (
    <div className="w-80 bg-white border-r border-vid-black-200 overflow-y-auto">
      <div className="p-6">
        <h1 className="text-2xl font-semibold text-vid-black-900">
          Relational Leadership Academy
        </h1>
        <div className="mt-2 text-vid-black-500">
          {Math.round(
            (modules.reduce((acc, m) => acc + m.completedLessons, 0) /
              modules.reduce((acc, m) => acc + m.totalLessons, 0)) *
              100
          )}
          % complete
        </div>
      </div>

      <div className="flex flex-col gap-2">
        {modules.map((module) => (
          <ModuleItem
            key={module.id}
            module={module}
            isExpanded={expandedModuleIds.has(module.id)}
            activeLesson={activeLesson}
            onLessonClick={onLessonClick}
            onModuleToggle={onModuleToggle}
          />
        ))}
      </div>
    </div>
  );
};

export const ModuleItem: React.FC<{
  module: ModuleWithLessonsAndProgressETO;
  isExpanded: boolean;
  activeLesson: Id<"courseLessons"> | null;
  onLessonClick: (lessonId: Id<"courseLessons">) => void;
  onModuleToggle: (moduleId: Id<"courseModules">) => void;
}> = ({ module, isExpanded, activeLesson, onLessonClick, onModuleToggle }) => {
  return (
    <div className="px-4">
      <button
        onClick={() => onModuleToggle(module.id)}
        className="w-full flex items-center justify-between p-4 hover:bg-vid-black-50 rounded-lg"
      >
        <div className="flex flex-col">
          <span className="text-left font-medium text-vid-black-900">
            {module.title}
          </span>
          <span className="text-sm text-vid-black-500">
            {module.completedLessons}/{module.totalLessons} lessons
          </span>
        </div>
        <div className="text-vid-black-400">{isExpanded ? "▼" : "▶"}</div>
      </button>

      {isExpanded && (
        <div className="ml-4 border-l-2 border-vid-black-200">
          {module.lessons.map((lesson: LessonWithEnrollmentProgressETO) => (
            <button
              key={lesson.id}
              onClick={() => onLessonClick(lesson.id)}
              className={`w-full flex items-center p-4 hover:bg-vid-black-50 rounded-lg ${
                activeLesson === lesson.id ? "bg-vid-black-50" : ""
              }`}
            >
              <div className="w-4 h-4 mr-3 rounded-full border-2 border-vid-black-400">
                {lesson.isCompleted && (
                  <div className="w-full h-full bg-vid-purple rounded-full" />
                )}
              </div>
              <div className="flex flex-col items-start">
                <span className="text-sm font-medium text-vid-black-900">
                  {lesson.title}
                </span>
                <span className="text-xs text-vid-black-500">
                  {lesson.duration}
                </span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const isYoutubeUrl = (url: string) => url.includes("youtube.com");
const isThinkificUrl = (url: string) => url.includes("thinkific.com");

export const MainContent: React.FC<{
  currentLesson: LessonWithEnrollmentProgressETO | null;
  onMarkComplete: (params: {
    lessonId: Id<"courseLessons">;
    completed: boolean;
    nextLessonId: Id<"courseLessons"> | null;
  }) => void;
}> = ({ currentLesson, onMarkComplete }) => {
  if (!currentLesson) return null;

  const renderVideo = (url: string) => {
    if (isYoutubeUrl(url)) {
      // Convert YouTube watch URL to embed URL
      const embedUrl = url.replace("watch?v=", "embed/").split("&")[0];
      return (
        <iframe
          className="w-full h-full rounded-lg"
          src={embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    }

    if (isThinkificUrl(url)) {
      // Thinkific videos typically need to be embedded via iframe
      return (
        <iframe
          className="w-full h-full rounded-lg"
          src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    }

    // Fallback for regular video files
    return <video src={url} controls className="w-full h-full" />;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 p-8 overflow-y-auto">
        <div className="flex flex-col gap-8 max-w-4xl mx-auto">
          <h1 className="text-3xl font-semibold text-vid-black-900 mb-4">
            {currentLesson.title}
          </h1>

          {currentLesson.sections
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((section, index) => {
              switch (section.type) {
                case "QUIZ":
                  return section.content.quizQuestions ? (
                    <Quiz
                      key={index}
                      questions={section.content.quizQuestions}
                    />
                  ) : null;
                case "VIDEO":
                  return section.content.videoUrl ? (
                    <div className="aspect-video bg-vid-black-900 rounded-lg mb-8">
                      {renderVideo(section.content.videoUrl)}
                    </div>
                  ) : null;
                case "TEXT":
                  return section.content.markdownContent ? (
                    <div
                      key={index}
                      className="prose max-w-none text-vid-black-700"
                    >
                      <ReactMarkdown>
                        {section.content.markdownContent}
                      </ReactMarkdown>
                    </div>
                  ) : null;
                case "INTERACTIVE":
                  return section.content.interactiveConfig ? (
                    <div key={index} className="prose text-vid-black-700">
                      {/* TODO: Implement interactive content rendering based on interactiveConfig */}
                      <p>Interactive content coming soon...</p>
                    </div>
                  ) : null;
                default:
                  return null;
              }
            })}
        </div>
      </div>
      <div className="border-t border-vid-black-200 bg-white p-4">
        <div className="max-w-4xl mx-auto flex justify-between">
          <button
            onClick={() =>
              onMarkComplete({
                lessonId: currentLesson.id,
                completed: false,
                nextLessonId: currentLesson.nextLessonId,
              })
            }
            className="px-4 py-2 text-vid-black-700 hover:bg-vid-black-50 rounded-lg"
          >
            Mark Incomplete
          </button>
          <button
            onClick={() =>
              onMarkComplete({
                lessonId: currentLesson.id,
                completed: true,
                nextLessonId: currentLesson.nextLessonId,
              })
            }
            className="px-4 py-2 bg-vid-purple text-white hover:bg-darkish-purple rounded-lg"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
