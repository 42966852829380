import { useQuery } from "convex/react";
import { useUnit } from "effector-react";
import { SelectModalitiesVM } from "frontend-shared/src/users/hp/select-modality.vm";
import { Check, ChevronsUpDown } from "lucide-react";
import React from "react";
import { api } from "shared/be/convex/_generated/api";
import { Button } from "web-shared/src/components/ui/button";
import { Checkbox } from "web-shared/src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "web-shared/src/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "web-shared/src/components/ui/popover";

type ModalityPillProps = {
  name: string;
};

const ModalityPill: React.FC<ModalityPillProps> = ({ name }) => {
  return (
    <div className="px-6 py-3 border border-vid-black-200 text-sm rounded-full flex justify-center items-center">
      {name}
    </div>
  );
};

export const ModalitySelectorForm: React.FC<{
  vm: SelectModalitiesVM;
}> = ({ vm }) => {
  const allKnownModalities = useQuery(api.User.Hp.HpFns.listAllKnownModalities);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (allKnownModalities !== undefined) {
      console.log("SETTING ALL KNOWN MODALITIES! ", allKnownModalities);
      vm.allKnownModalitiesLoaded([...allKnownModalities.modalities]);
    }
  }, [allKnownModalities]);

  const selectedModalities = useUnit(vm.$selectedModalities);
  const availableModalities = useUnit(vm.$allKnownModalities);

  return (
    <>
      {open && (
        <div className="fixed inset-0 bg-black/30 transition-opacity z-40" />
      )}
      <div className="w-full relative z-50">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-full h-auto justify-between px-3 py-3 bg-white rounded-xl border border-vid-black-200 hover:bg-white"
            >
              <div className="flex flex-wrap items-center gap-2 min-w-0 max-h-[120px] overflow-y-auto pr-2">
                {selectedModalities && selectedModalities.length > 0 ? (
                  selectedModalities.map((modality) => (
                    <ModalityPill key={modality.slug} name={modality.name} />
                  ))
                ) : (
                  <span>Select Modalities</span>
                )}
              </div>
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-[var(--radix-popover-trigger-width)] min-w-full p-0 bg-white rounded-xl border border-vid-black-200 shadow-lg"
            align="start"
            side="bottom"
            sideOffset={4}
            avoidCollisions={false}
            sticky="always"
          >
            <Command className="rounded-lg">
              <CommandInput
                placeholder="Search modalities..."
                className="h-12"
              />
              <CommandEmpty>No modality found.</CommandEmpty>
              <CommandGroup className="max-h-[300px] overflow-auto">
                {availableModalities.map((modality) => (
                  <CommandItem
                    key={modality.slug}
                    onSelect={() => vm.selectModality(modality.slug)}
                    className={`flex items-center gap-2 px-4 py-2 ${
                      vm.isSelected(modality.slug)
                        ? "bg-vid-purple-100 text-vid-purple-900"
                        : ""
                    }`}
                  >
                    <Checkbox
                      id={modality.slug}
                      checked={vm.isSelected(modality.slug)}
                      onCheckedChange={() => vm.selectModality(modality.slug)}
                    />
                    <span>{modality.name}</span>
                    {vm.isSelected(modality.slug) && (
                      <Check className="ml-auto h-4 w-4 text-vid-purple-500" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};
