import { getRouteForAuthedDestination } from "@/src/domains/locations/route-maps";
import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { useConvex, useConvexAuth, useQuery } from "convex/react";
import { Option } from "effect";
import { useUnit } from "effector-react";
import { useSetupUStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { useGetMyInitialAuthedDestination } from "frontend-shared/src/onboard/authin.vm";
import { useNotificationToasts } from "frontend-shared/src/toasts/use-notifications-toast";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type {
  AuthedDestination,
  SimpleUserWithProfilePhoto,
} from "shared/be/convex/User/User.Types";
import { ROUTES } from "shared/routes/routes";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "web-shared/src/components/ui/command";
import {
  DefaultSheetHeader,
  Sheet,
  SheetContent,
} from "web-shared/src/components/ui/sheet";
import {
  ToastProvider,
  ToastViewport,
} from "web-shared/src/components/ui/toast";
import { Messenger } from "web-shared/src/domains/chat/messenger";
import {
  useWebAppSession,
  useWebGlobalDisplayVM,
  UWebStateMgr,
  UWebStateMgrContext,
} from "web-shared/src/web-context";
import { WebRtcMgr } from "web-shared/src/web-rtc.mgr";

export const ULayout: React.FC = () => {
  const nav = useNavigate();
  const { isLoading, isAuthenticated } = useConvexAuth();

  useEffect(() => {
    console.log("IS LOADING IN U LAYOUT! ", isLoading);
    console.log("IS AUTHENTICATED IN U LAYOUT! ", isAuthenticated);
    if (!isLoading && !isAuthenticated) {
      nav(ROUTES.AUTH.AUTHIN.path);
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || !isAuthenticated) {
    return <FullScreenLoadingSpinner />;
  }

  return <AuthorizedView />;
};

const AuthorizedView: React.FC = () => {
  const convexCli = useConvex();
  const [me, setMe] = useState<SimpleUserWithProfilePhoto | undefined>(
    undefined
  );

  useEffect(() => {
    convexCli.query(api.User.UserFns.getAssumedSimpleMe, {}).then(setMe);
  }, []);

  const destination = useGetMyInitialAuthedDestination();

  if (me === undefined || destination === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return <LoadedAuthorizedView destination={destination} me={me} />;
};

const LoadedAuthorizedView: React.FC<{
  destination: AuthedDestination;
  me: SimpleUserWithProfilePhoto;
}> = ({ destination, me }) => {
  const nav = useNavigate();

  useWebAppSession();

  const webStateMgr = useWebGlobalDisplayVM();
  const isSearching = useUnit(webStateMgr.globalSearchCommandVM.$isSearching);

  const [mbOpenSheet] = useUnit([webStateMgr.globalSheetVM.openVM.store]);

  const mbUStateMgr = useSetupUStateMgr<
    Call,
    StreamVideoClient,
    WebRtcMgr,
    UWebStateMgr
  >(
    me,
    (p) =>
      new UWebStateMgr({
        ...p,
        baseUserId: me.id,
        toastVM: webStateMgr.toastVM,
      })
  );

  console.log("MB U STATE MGR! ", mbUStateMgr);

  useNotificationToasts(webStateMgr.toastVM);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const nextRoute = getRouteForAuthedDestination(currentPath, destination);
    console.log("NEXT ROUTE! ", nextRoute, destination);
    nav(nextRoute);
  }, [destination]);

  if (Option.isNone(mbUStateMgr)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <UWebStateMgrContext.Provider value={mbUStateMgr.value}>
      <ToastProvider>
        <Sheet open={mbOpenSheet !== null}>
          {mbOpenSheet && (
            <SheetContent
              className="h-full flex flex-col"
              onClose={() => webStateMgr.globalSheetVM.closeSheet()}
            >
              <div className="flex-1 flex flex-col gap-4">
                <DefaultSheetHeader
                  title={mbOpenSheet!.config.title}
                  type={mbOpenSheet!.config.type}
                />
                <div className="flex-1 flex flex-col pt-8">
                  {mbOpenSheet!.content}
                </div>
              </div>
            </SheetContent>
          )}
        </Sheet>
        <Outlet />
        <Messenger
          vm={mbUStateMgr.value.messengerVM}
          roomsIBelongTo$={mbUStateMgr.value.p.roomsIBelongTo$}
          onRoomClick={(roomId) => {
            mbUStateMgr.value.messengerVM.setViewStateEvt({
              _tag: "CHAT_ROOM",
              roomId,
            });
          }}
        />
        <ToastViewport vm={webStateMgr.toastVM} />
        {isSearching && (
          <div className="absolute inset-0 z-50 bg-black/50 flex flex-col items-center py-8 px-8">
            <div className="flex justify-end w-full">
              <button
                onClick={() => {
                  webStateMgr.globalSearchCommandVM.closeSearchEvt();
                }}
                className="text-white hover:text-gray-200 p-4 border rounded-full "
              >
                <X size={48} />
              </button>
            </div>
            <ContextMenuOverlay />
          </div>
        )}
      </ToastProvider>
    </UWebStateMgrContext.Provider>
  );
};

const ContextMenuOverlay: React.FC = () => {
  const nav = useNavigate();
  const webStateMgr = useWebGlobalDisplayVM();
  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients
  );

  const allPublicCommunities = useQuery(
    api.Community.CommunityFns.getAllPublicCommunities
  );

  if (myActiveClients === undefined || allPublicCommunities === undefined) {
    return null;
  }

  return (
    <div className="w-[700px] min-h-[500px]">
      <Command className="rounded-lg border shadow-md">
        <CommandInput
          placeholder="Search for clients and communities..."
          className="h-16 text-xl px-4 placeholder:text-black-400"
          autoFocus
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Clients">
            {myActiveClients.clients.map((client) => (
              <CommandItem
                key={client.clientId}
                onSelect={() => {
                  nav(
                    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.PAST_SESSIONS.buildPath(
                      {
                        clientId: client.clientId,
                      }
                    )
                  );
                  webStateMgr.globalSearchCommandVM.closeSearchEvt();
                }}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <AvatarCircle
                    mbProfilePhoto={ImageSrc.fromMbUrl(
                      client.profilePhoto?.medium ?? null
                    )}
                    size={48}
                  />
                  <div className="flex flex-col">
                    <div className="text-sm font-medium">{client.name}</div>
                    <div className="text-xs text-gray-500">{client.email}</div>
                  </div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Communities">
            {allPublicCommunities.map((community) => (
              <CommandItem
                key={community._id}
                onSelect={() => {
                  nav(
                    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                      {
                        community: community.slug,
                      }
                    )
                  );
                  webStateMgr.globalSearchCommandVM.closeSearchEvt();
                }}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <div className="text-lg font-medium">{community.name}</div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  );
};
