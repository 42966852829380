import { DoYouSeeClientsForm } from "@/src/domains/onboard/input-user-type";
import { useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";

export const DoYouSeeClientsPage: React.FC = () => {
  const nav = useNavigate();
  return (
    <div className="bg-vid-purple flex h-screen w-screen items-center justify-center">
      <div className="w-[600px] p-12 rounded-[24px] flex bg-white">
        <DoYouSeeClientsForm
          onNext={() => {
            nav(UROUTES.ONBOARD.INPUT_PROFILE.path);
          }}
        />
      </div>
    </div>
  );
};
