import { useState } from "react";
import { PrimaryButton } from "web-shared/src/components/button";
import { Checkbox } from "web-shared/src/components/ui/checkbox";
import { Dialog } from "web-shared/src/components/ui/dialog";

export const JoinCommunityModal: React.FC<{
  communityName: string;
  monthlyPriceInCents: number;
  trialPeriodDays: number;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}> = ({
  communityName,
  monthlyPriceInCents,
  trialPeriodDays,
  onConfirm,
  onCancel,
  isOpen,
}) => {
  const [hasAgreed, setHasAgreed] = useState(false);
  const monthlyPrice = (monthlyPriceInCents / 100).toFixed(2);

  return (
    <Dialog open={isOpen} onOpenChange={(open: boolean) => !open && onCancel()}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-4">Join {communityName}</h2>

          <div className="space-y-4">
            <div className="text-lg font-medium">${monthlyPrice}/month</div>

            {trialPeriodDays > 0 && (
              <div className="text-sm text-gray-600">
                Includes {trialPeriodDays}-day free trial
              </div>
            )}

            <div className="space-y-2">
              <div className="text-sm text-gray-600">
                By joining, you'll get immediate access to:
              </div>
              <ul className="text-sm text-gray-600 list-disc pl-5 space-y-1">
                <li>All community events and courses</li>
                <li>Exclusive community content</li>
                <li>Member-only discussions</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-3 rounded-lg">
              <div className="flex items-center space-x-3">
                <div className="h-8 w-12 bg-gray-200 rounded"></div>
                <div className="text-sm">
                  <div className="font-medium">Payment Method</div>
                  <div className="text-gray-500">Visa ending in •••• 4242</div>
                </div>
              </div>
            </div>

            <div className="text-sm text-gray-600">
              You will be charged ${monthlyPrice} monthly to access this
              community. You can cancel anytime.
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="terms"
                checked={hasAgreed}
                onCheckedChange={(checked: boolean) => setHasAgreed(checked)}
              />
              <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I agree to pay ${monthlyPrice} monthly for access to{" "}
                {communityName}
              </label>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <PrimaryButton
              title="Join Community"
              onClick={onConfirm}
              disabled={!hasAgreed}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
