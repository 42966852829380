import * as Sentry from "@sentry/react";
import { useConvexAuth } from "convex/react";
import { useUnit } from "effector-react";
import { useOnce } from "frontend-shared/src/util";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { Dialog, DialogContent } from "web-shared/src/components/ui/dialog";
import { globalErrorToastMgr, globalTopInfoToastMgr } from "./global.state";
import { FullScreenLoadingSpinner } from "./loading";
import {
  WebGlobalDisplayVM,
  WebGlobalVmContext,
  type DialogVM,
} from "web-shared/src/web-context";

export const IndexLayout: React.FC = () => {
  const nav = useNavigate();
  const { isAuthenticated, isLoading } = useConvexAuth();
  const mbShowGlobalErrorToast = useObservableState(
    globalErrorToastMgr.showGlobalErrorToast$,
    null
  );
  const mbShowGlobalTopInfoToast = useObservableState(
    globalTopInfoToastMgr.showToast$,
    null
  );

  const rootWebStateMgr = useOnce(() => new WebGlobalDisplayVM());

  useEffect(() => {
    const isRootUrl = location.pathname === "/";

    if (isLoading) {
      return;
    }

    if (isRootUrl) {
      if (isAuthenticated) {
        nav(ROUTES.U.path);
      } else {
        nav(ROUTES.AUTH.AUTHIN.path);
      }
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <WebGlobalVmContext.Provider value={rootWebStateMgr}>
        <div className="w-screen h-screen flex flex-col justify-center relative">
          <Outlet />
          {mbShowGlobalErrorToast && (
            <div className="absolute top-0 left-0 right-0 h-[100px] flex flex-col justify-center items-center bg-red-500 z-50">
              <div className="text-white p-4 rounded-md">
                {mbShowGlobalErrorToast.title}
              </div>
              <div className="text-white p-4 rounded-md">
                {mbShowGlobalErrorToast.message}
              </div>
            </div>
          )}
          {mbShowGlobalTopInfoToast && (
            <div className="absolute top-0 left-0 right-0 h-[100px] flex justify-center items-center">
              <div className="bg-vid-black-800 text-white p-4 rounded-md animate-slide-down-top-info-toast">
                {mbShowGlobalTopInfoToast.title}
              </div>
            </div>
          )}
          <AppDialog vm={rootWebStateMgr.globalDialogVM} />
        </div>
      </WebGlobalVmContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

const AppDialog: React.FC<{
  vm: DialogVM;
}> = ({ vm }) => {
  const [mbOpen] = useUnit([vm.openVM.store]);

  return (
    <Dialog open={mbOpen !== null}>
      <DialogContent
        onClose={() => {
          vm.openVM.event(null);
        }}
        className="max-w-2xl h-[700px] flex flex-col"
      >
        {mbOpen}
      </DialogContent>
    </Dialog>
  );
};

export const ErrorPage: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-vid-black-50">
      <div className="flex flex-col items-center gap-6 p-8 rounded-lg bg-white shadow-default-card max-w-md">
        <img src="/il-logo.svg" alt="Logo" className="w-16 h-16" />
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-medium text-vid-black-900">
            Something went wrong
          </h1>
          <p className="text-vid-black-700">
            An unexpected error has occurred. Please try refreshing the page or
            contact support if the problem persists.
          </p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="bg-vid-purple hover:bg-btn-purple text-white rounded-buttons px-6 py-3 transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};
