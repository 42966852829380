import { createEvent, createStore, sample } from "effector";
import type { SoundType } from "shared/be/convex/Sessions/Emdr/Emdr.Types";
import type { EmdrSoundType } from "shared/be/convex/Sessions/Rooms/Activity/Activity.Types";

export interface EmdrBallConfig {
  ballSize: number;
  containerWidth: number;
  frequency: number;
}

export interface AudioConfig {
  soundType: SoundType;
  soundDuration: number;
}

interface PlaySoundArgs {
  gain: number;
  soundType: SoundType;
  soundDuration: number;
}

export class EmdrBallVisVM {
  readonly $position = createStore(0);
  readonly $direction = createStore(1);
  readonly $isPlaying = createStore(false);
  readonly $frequency = createStore(1);
  readonly $ballSize = createStore(24);
  readonly $containerWidth = createStore(600);
  readonly $soundType = createStore<EmdrSoundType>("click");
  readonly $soundDuration = createStore(0.07);
  readonly $gain = createStore(1);

  // Events
  readonly setPosition = createEvent<number>();
  readonly setFrequency = createEvent<number>();
  readonly setBallSize = createEvent<number>();
  readonly setContainerWidth = createEvent<number>();
  readonly tick = createEvent();
  readonly configChanged = createEvent<EmdrBallConfig>();
  readonly playStateChanged = createEvent<boolean>();
  readonly setSoundType = createEvent<EmdrSoundType>();
  readonly setSoundDuration = createEvent<number>();
  readonly audioRequested = createEvent();
  readonly loadAudioRequested = createEvent<SoundType>();

  // Public events that components can subscribe to
  readonly passMade = createEvent();
  readonly playSoundEvt = createEvent<PlaySoundArgs>();

  private intervalId: NodeJS.Timer | null = null;

  constructor(cargs: { playSound: (args: PlaySoundArgs) => void }) {
    // First sample handles position updates
    sample({
      clock: this.tick,
      source: {
        position: this.$position,
        direction: this.$direction,
        frequency: this.$frequency,
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: this.$isPlaying,
      fn: ({ position, direction, frequency, ballSize, containerWidth }) => {
        const frameTime = 1000 / 60;
        const distancePerFrame =
          (containerWidth / (4000 / frameTime)) * frequency;
        const range = containerWidth - ballSize;
        const newPos = position + distancePerFrame * direction;

        return newPos >= range ? range : newPos <= 0 ? 0 : newPos;
      },
      target: this.$position,
    });

    // Add this new sample to handle direction changes
    sample({
      clock: this.$position,
      source: {
        direction: this.$direction,
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: (_, pos) => pos <= 0 || pos >= _.containerWidth - _.ballSize,
      fn: ({ direction }) => direction * -1,
      target: this.$direction,
    });

    // Separate sample to trigger passMade when hitting boundaries
    sample({
      clock: this.$position,
      source: {
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: (_, pos) => pos >= _.containerWidth - _.ballSize || pos <= 0,
      target: this.passMade,
    });

    sample({
      clock: this.passMade,
      source: {
        gain: this.$gain,
        soundType: this.$soundType,
        soundDuration: this.$soundDuration,
      },
      fn: ({ gain, soundType, soundDuration }) => ({
        gain,
        soundType,
        soundDuration,
      }),
      target: this.playSoundEvt,
    });

    this.playSoundEvt.watch(({ gain, soundType, soundDuration }) => {
      cargs.playSound({ gain, soundType, soundDuration });
    });

    this.$isPlaying.on(this.playStateChanged, (_, isPlaying) => isPlaying);
    this.$frequency.on(this.setFrequency, (_, frequency) => frequency);
    this.$ballSize.on(this.setBallSize, (_, ballSize) => ballSize);
    this.$containerWidth.on(
      this.setContainerWidth,
      (_, containerWidth) => containerWidth
    );

    this.$isPlaying.watch((isPlaying) => {
      if (isPlaying) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    });

    // Audio-related logic
    this.$soundType.on(this.setSoundType, (_, soundType) => soundType);
    this.$soundDuration.on(this.setSoundDuration, (_, duration) => duration);

    // Update gain when sound type changes
    sample({
      clock: this.$soundType,
      fn: (soundType) => {
        switch (soundType) {
          case "click":
            return 5;
          case "beep":
            return 7;
          default:
            return 1;
        }
      },
      target: this.$gain,
    });

    sample({
      clock: this.$soundType,
      fn: (soundType) => this.durationForSoundType(soundType),
      target: this.$soundDuration,
    });

    // Trigger audio when passing boundaries
    sample({
      clock: this.passMade,
      target: this.audioRequested,
    });
  }

  private startAnimation = () => {
    const frameTime = 1000 / 60; // 60 FPS
    this.intervalId = setInterval(() => {
      this.tick();
    }, frameTime);
  };

  private stopAnimation = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  // Public methods
  updateConfig(config: EmdrBallConfig) {
    this.configChanged(config);
  }

  setPlayState(isPlaying: boolean) {
    this.playStateChanged(isPlaying);
  }

  destroy() {
    this.stopAnimation();
  }

  durationForSoundType(soundType: SoundType): number {
    switch (soundType) {
      case "click":
        return 0.07;
      case "beep":
        return 0.3;
      default:
        return 0.07;
    }
  }

  getAudioSource(soundType: SoundType): string {
    console.log("audio source for soundType", soundType);
    switch (soundType) {
      case "click":
        return "metal-clink.mp3";
      case "beep":
        return "meditation.mp3";
      case "white-noise":
      default:
        return "meditation.mp3";
    }
  }
}
