import { useMutation, useQuery } from "convex/react";
import { Effect, Match } from "effect";
import { useObservableEagerState } from "observable-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Rx from "rxjs";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  CurrentOpenMenu,
  QuickActionToolTag,
  SelectedContent,
  SettingsMenuViewState,
  StageViewLayout,
  StreamConfig,
  TitleDisplaySection,
  ToolsMenuViewState,
} from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import { isNotNullOrUndefined } from "shared/util";
import type { AbstractAudioPlayer } from "../mgrs/media-player.statemgr";
import type {
  GenericRtcChannelMgr,
  GenericRtcEngine,
  GenericRtcMgr,
} from "../mgrs/state-mgrs/rtc.statemgr";
import { createContextAndHook, useOnce, useQuery$ } from "../util";

export function useSetupLiveRoom<
  RtcEngine extends GenericRtcEngine<ChannelMgr>,
  ChannelMgr extends GenericRtcChannelMgr,
>(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
  rtcClient: GenericRtcMgr<RtcEngine, ChannelMgr>;
  audioPlayer: AbstractAudioPlayer;
}): {
  currentOpenMenu: CurrentOpenMenu | null;
  stageViewLayout: StageViewLayout | undefined;
  quickActionTools: QuickActionToolTag[] | undefined;
  streamConfig: StreamConfig | undefined;
  channelMgr: ChannelMgr | undefined;
  isBackstage: boolean | undefined;
  liveRoomVM: LiveRoomVM;
} {
  console.log("SETUP LIVE ROOM! : ", p.roomId);
  const { baseSessionId, roomId, rtcClient } = p;
  const [streamConfig, setStreamConfig] = useState<StreamConfig | undefined>(
    undefined
  );
  const [channelMgr, setChannelMgr] = useState<ChannelMgr | undefined>(
    undefined
  );

  const registerPing = useMutation(api.Sessions.Rooms.LiveRoomFns.registerPing);

  const onEnterLiveRoom = useMutation(
    api.Sessions.Rooms.LiveRoomFns.onEnterLiveRoom
  );

  const onJoinedStream = useMutation(
    api.Sessions.Rooms.LiveRoomFns.onJoinedStream
  );

  const isVideoMute$ = useQuery$(
    api.Sessions.Rooms.Live.ControlPanelFns.isParticipantStreamVideoMuted,
    {
      baseSessionId,
      roomId,
    }
  );

  const isAudioMute$ = useQuery$(
    api.Sessions.Rooms.Live.ControlPanelFns.isParticipantStreamAudioMuted,
    {
      baseSessionId,
      roomId,
    }
  );

  const isBackstage = useQuery(
    api.Sessions.Rooms.Live.ControlPanelFns.isParticipantBackstage,
    {
      baseSessionId,
      roomId,
    }
  );

  const titleDisplaySection$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getTitleDisplaySection,
    {
      roomId,
    }
  );

  const selectedContent$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getSelectedContent,
    {
      roomId,
    }
  );

  useEffect(() => {
    let unsubVid: Rx.Subscription | undefined;
    let unsubAudio: Rx.Subscription | undefined;
    if (rtcClient && channelMgr) {
      unsubVid = isVideoMute$.subscribe((isVideoMute) => {
        if (!isVideoMute) {
          channelMgr.camera.enable().then();
        } else {
          channelMgr.camera.disable().then();
        }
      });

      unsubAudio = isAudioMute$.subscribe((isAudioMute) => {
        console.log("IS AUDIO MUTE: ", isAudioMute);
        if (!isAudioMute) {
          channelMgr.microphone.enable().then();
        } else {
          channelMgr.microphone.disable().then();
        }
      });
    }

    return () => {
      unsubVid?.unsubscribe();
      unsubAudio?.unsubscribe();
    };
  }, [rtcClient, channelMgr]);

  useEffect(() => {
    if (isNotNullOrUndefined(rtcClient)) {
      onEnterLiveRoom({ baseSessionId, roomId }).then((res) => {
        setStreamConfig(res.streamConfig);

        Effect.runPromise(rtcClient.lazyJoinChannel(res.streamConfig)).then(
          (channelMgr) => {
            console.log("JOINED STREAM! ", res.streamConfig);
            setChannelMgr(channelMgr);
            onJoinedStream({ baseSessionId, roomId });
          }
        );
      });

      setInterval(() => {
        registerPing({ baseSessionId, roomId });
      }, 10000);
    }
  }, [onEnterLiveRoom, baseSessionId, roomId, rtcClient]);

  const stageViewLayout = useQuery(
    api.Sessions.Rooms.LiveRoomFns.getStageViewLayout,
    { baseSessionId, roomId }
  );

  const isStageViewMaximized$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getIsStageViewMaximized,
    {
      roomId,
    }
  );

  const toggleStageViewMaximized = useMutation(
    api.Sessions.Rooms.LiveRoomFns.toggleStageViewMaximized
  ).withOptimisticUpdate((localStore) => {
    const curState = localStore.getQuery(
      api.Sessions.Rooms.LiveRoomFns.getIsStageViewMaximized,
      { roomId }
    );
    if (curState === undefined) {
      return;
    }
    localStore.setQuery(
      api.Sessions.Rooms.LiveRoomFns.getIsStageViewMaximized,
      { roomId },
      !curState
    );
  });

  const currentOpenMenu = useQuery(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.getCurrentOpenMenu,
    { roomId }
  );

  const quickActionTools = useQuery(
    api.Sessions.Rooms.LiveRoomFns.getQuickActionTools,
    { baseSessionId, roomId }
  );

  const toggleParticipantStreamMute = useMutation(
    api.Sessions.Rooms.Live.ControlPanelFns.toggleParticipantStreamMute
  ).withOptimisticUpdate((localStore, args) => {
    Match.value(args.mediaChannel).pipe(
      Match.when("audio", () => {
        localStore.setQuery(
          api.Sessions.Rooms.Live.ControlPanelFns.isParticipantStreamAudioMuted,
          {
            baseSessionId,
            roomId,
          },
          args.action === "mute"
        );
      }),
      Match.when("video", () => {
        localStore.setQuery(
          api.Sessions.Rooms.Live.ControlPanelFns.isParticipantStreamVideoMuted,
          {
            baseSessionId,
            roomId,
          },
          args.action === "mute"
        );
      }),
      Match.exhaustive
    );
  });

  const leaveChannel: () => Promise<void> = useCallback(() => {
    return channelMgr?.leave() ?? Promise.resolve();
  }, [channelMgr]);

  const liveRoomVM = useMemo(() => {
    return new LiveRoomVM({
      baseSessionId,
      roomId,
      isVideoMuted$: isVideoMute$,
      isAudioMuted$: isAudioMute$,
      selectedContent$,
      stageView: {
        isStageViewMaximized$,
        toggleStageViewMaximized: () => {
          toggleStageViewMaximized({
            roomId,
          });
        },
        titleDisplaySection$,
      },
      audioPlayer: p.audioPlayer,
      muteAudioOnServer: (muteAction) => {
        return toggleParticipantStreamMute({
          baseSessionId,
          roomId,
          action: muteAction,
          mediaChannel: "audio",
        });
      },
      muteVideoOnServer: (muteAction) => {
        console.log("MUTING VIDEO ON SERVER: ", muteAction);
        return toggleParticipantStreamMute({
          baseSessionId,
          roomId,
          action: muteAction,
          mediaChannel: "video",
        });
      },
      leaveChannel,
    });
  }, [
    baseSessionId,
    roomId,
    isVideoMute$,
    isAudioMute$,
    toggleStageViewMaximized,
    leaveChannel,
  ]);

  return {
    currentOpenMenu: currentOpenMenu ?? null,
    stageViewLayout,
    quickActionTools,
    streamConfig,
    channelMgr,
    isBackstage,
    liveRoomVM,
  };
}

export function useGetClockInfo(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}) {
  const { baseSessionId, roomId } = p;
  const mainCountdownClockInfo$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getMainCountdownClock,
    {
      baseSessionId,
    }
  );

  const specialClockDisplay$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getSpecialClockDisplay,
    {
      roomId,
    }
  );

  return {
    mainCountdownClockInfo$,
    specialClockDisplay$,
  };
}

export function useSetupLiveRoomStreamVM<
  ChannelMgr extends GenericRtcChannelMgr,
>(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
  channelMgr: ChannelMgr | undefined;
  applyBackgroundBlurFilterToStream: (blurIntensity: any) => void;
  disableBackgroundBlurFilter: () => void;
}): {
  liveRoomStreamVM: LiveRoomStreamVM;
} {
  const isBackgroundBlurred$ = useQuery$(
    api.Sessions.Rooms.LiveRoomFns.getIsBackgroundBlurred,
    {
      baseSessionId: p.baseSessionId,
      roomId: p.roomId,
    }
  );

  const setIsBackgroundBlurred = useMutation(
    api.Sessions.Rooms.LiveRoomFns.setIsBackgroundBlurred
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Sessions.Rooms.LiveRoomFns.getIsBackgroundBlurred,
      { baseSessionId: p.baseSessionId, roomId: p.roomId },
      args.isBackgroundBlurred
    );
  });

  // for now we just mock these locally with rxjs
  // TODO: add server side logic for screenshare
  const isScreenshareEnabled$ = useOnce(
    () => new Rx.BehaviorSubject<boolean | undefined>(undefined)
  );
  const setIsScreenshareEnabled = (isScreenshareEnabled: boolean) => {
    isScreenshareEnabled$.next(isScreenshareEnabled);
  };

  useEffect(() => {
    let unsubBackgroundBlur: Rx.Subscription | undefined;
    unsubBackgroundBlur = isBackgroundBlurred$.subscribe(
      (isBackgroundBlurred) => {
        console.log("IS BACKGROUND BLURRED: ", isBackgroundBlurred);
        if (isBackgroundBlurred) {
          p.applyBackgroundBlurFilterToStream(1);
        } else {
          p.disableBackgroundBlurFilter();
        }
      }
    );
  }, []);

  useEffect(() => {
    let unsubScreenshare: Rx.Subscription | undefined;
    if (isNotNullOrUndefined(p.channelMgr)) {
      unsubScreenshare = isScreenshareEnabled$.subscribe(
        (isScreenshareEnabled) => {
          console.log("IS SCREENSHARE ENABLED: ", isScreenshareEnabled);
          if (isScreenshareEnabled) {
            p.channelMgr!.screenShare.enable();
          } else {
            p.channelMgr!.screenShare.disable();
          }
        }
      );
    }

    return () => {
      unsubScreenshare?.unsubscribe();
    };
  }, [p.channelMgr, isScreenshareEnabled$]);

  const liveRoomStreamVM = useOnce(() => {
    return new LiveRoomStreamVM({
      isBackgroundBlurred$,
      applyBackgroundBlurFilterOnServer: (isBackgroundBlurred) => {
        return setIsBackgroundBlurred({
          baseSessionId: p.baseSessionId,
          isBackgroundBlurred,
        });
      },
      isScreenshareEnabled$,
      applyScreenshareFilterOnServer: (isScreenshareEnabled) => {
        return setIsScreenshareEnabled(isScreenshareEnabled);
      },
    });
  });

  return {
    liveRoomStreamVM,
  };
}

export const mkLiveRoomStreamContextAndHook = () =>
  createContextAndHook<LiveRoomStreamVM>();

interface StageViewInfo {
  isStageViewMaximized$: Rx.BehaviorSubject<boolean | undefined>;
  toggleStageViewMaximized: () => void;
  titleDisplaySection$: Rx.BehaviorSubject<TitleDisplaySection | undefined>;
}

export class LiveRoomStreamVM {
  isBackgroundBlurred$: Rx.BehaviorSubject<boolean | undefined>;
  isScreenshareEnabled$: Rx.BehaviorSubject<boolean | undefined>;

  constructor(
    readonly p: {
      applyBackgroundBlurFilterOnServer: (isBackgroundBlurred: boolean) => void;
      isBackgroundBlurred$: Rx.BehaviorSubject<boolean | undefined>;
      applyScreenshareFilterOnServer: (isScreenshareEnabled: boolean) => void;
      isScreenshareEnabled$: Rx.BehaviorSubject<boolean | undefined>;
    }
  ) {
    this.isBackgroundBlurred$ = p.isBackgroundBlurred$;
    this.isScreenshareEnabled$ = p.isScreenshareEnabled$;
  }

  setIsBackgroundBlurred(isBackgroundBlurred: boolean) {
    this.p.applyBackgroundBlurFilterOnServer(isBackgroundBlurred);
  }

  setIsScreenshareEnabled(isScreenshareEnabled: boolean) {
    this.p.applyScreenshareFilterOnServer(isScreenshareEnabled);
  }
}

export class LiveRoomVM {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;

  isVideoMuted$: Rx.BehaviorSubject<boolean | undefined>;
  isAudioMuted$: Rx.BehaviorSubject<boolean | undefined>;

  audioPlayer: AbstractAudioPlayer;

  selectedContent$: Rx.BehaviorSubject<SelectedContent | null | undefined>;

  stageView: StageViewInfo;

  leaveChannel: () => Promise<void>;

  constructor(
    readonly p: {
      baseSessionId: Id<"sessionConfig">;
      roomId: Id<"rtcLiveRooms">;
      audioPlayer: AbstractAudioPlayer;
      selectedContent$: Rx.BehaviorSubject<SelectedContent | null | undefined>;
      isVideoMuted$: Rx.BehaviorSubject<boolean | undefined>;
      isAudioMuted$: Rx.BehaviorSubject<boolean | undefined>;
      muteAudioOnServer: (action: "mute" | "unmute") => Promise<null>;
      muteVideoOnServer: (action: "mute" | "unmute") => Promise<null>;
      stageView: StageViewInfo;
      leaveChannel: () => Promise<void>;
    }
  ) {
    this.baseSessionId = p.baseSessionId;
    this.roomId = p.roomId;
    this.isVideoMuted$ = p.isVideoMuted$;
    this.isAudioMuted$ = p.isAudioMuted$;
    this.audioPlayer = p.audioPlayer;
    this.selectedContent$ = p.selectedContent$;
    this.stageView = p.stageView;
    this.leaveChannel = p.leaveChannel;
  }

  setIsVideoMuted(muteAction: "mute" | "unmute") {
    this.p.muteVideoOnServer(muteAction).catch();
  }

  setIsAudioMuted(muteAction: "mute" | "unmute") {
    this.p.muteAudioOnServer(muteAction).catch();
  }
}

export const [LiveRoomVMContext, useLiveRoomVM] =
  createContextAndHook<LiveRoomVM>();

export interface LiveRoomControlPanelProps {
  isAudioMute: boolean;
  isVideoMute: boolean;
  onEndCallButtonClick: () => void;
  onMagicPenClick: () => void;
  onSettingsClick: () => void;
}

export function useSetupControlPanel(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}): LiveRoomControlPanelProps {
  const { baseSessionId, roomId } = p;
  const liveRoomVM = useLiveRoomVM();

  const isAudioMute = useObservableEagerState(liveRoomVM.isAudioMuted$);
  const isVideoMute = useObservableEagerState(liveRoomVM.isVideoMuted$);

  const registerEndCallButtonClick = useMutation(
    api.Sessions.Rooms.Live.ControlPanelFns.onEndCallButtonClick
  );

  const openToolsMenuForMe = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.openToolsMenuForMe
  ).withOptimisticUpdate((localStore, _) => {
    localStore.setQuery(
      api.Sessions.Rooms.Live.LiveRoomMenuFns.getCurrentOpenMenu,
      { roomId },
      "TOOLS"
    );
  });

  const openSettingsMenuForMe = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.openSettingsMenu
  ).withOptimisticUpdate((localStore, _) => {
    localStore.setQuery(
      api.Sessions.Rooms.Live.LiveRoomMenuFns.getCurrentOpenMenu,
      { roomId },
      "SETTINGS"
    );
  });

  const onEndCallButtonClick = async () => {
    await liveRoomVM.leaveChannel();
    await registerEndCallButtonClick({ baseSessionId, roomId });
  };

  return {
    isAudioMute: isAudioMute ?? true,
    isVideoMute: isVideoMute ?? true,
    onEndCallButtonClick,
    onMagicPenClick: () => openToolsMenuForMe({ roomId }),
    onSettingsClick: () => openSettingsMenuForMe({ roomId }),
  };
}

export function useSetupMenuVM(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
  currentOpenMenu: CurrentOpenMenu | null;
}): {
  closeMenu: () => void;
  toolMenuViewState: ToolsMenuViewState | undefined;
  setMyToolMenuViewState: (viewState: ToolsMenuViewState) => void;
  settingsMenuViewState$: Rx.BehaviorSubject<SettingsMenuViewState | undefined>;
  setMySettingsMenuViewState: (viewState: SettingsMenuViewState) => void;
} {
  const { roomId } = p;

  const toolMenuViewState = useQuery(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.getMyToolMenuViewState,
    { roomId }
  );

  const setMyToolMenuViewState = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.setMyToolMenuViewState
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Sessions.Rooms.Live.LiveRoomMenuFns.getMyToolMenuViewState,
      { roomId },
      args.toolMenuViewState
    );
  });

  const settingsMenuViewState$ = useQuery$(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.getMySettingsMenuViewState,
    { roomId }
  );

  const setMySettingsMenuViewState = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.setMySettingsMenuViewState
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Sessions.Rooms.Live.LiveRoomMenuFns.getMySettingsMenuViewState,
      { roomId },
      args.settingsMenuViewState
    );
  });

  const closeMenu = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.closeMenuForMe
  ).withOptimisticUpdate((localState, _) => {
    localState.setQuery(
      api.Sessions.Rooms.Live.LiveRoomMenuFns.getCurrentOpenMenu,
      { roomId },
      null
    );
  });

  return {
    closeMenu: () => closeMenu({ roomId }),
    toolMenuViewState,
    setMyToolMenuViewState: (toolMenuViewState) =>
      setMyToolMenuViewState({
        roomId,
        toolMenuViewState,
      }),
    settingsMenuViewState$,
    setMySettingsMenuViewState: (settingsMenuViewState) =>
      setMySettingsMenuViewState({
        roomId,
        settingsMenuViewState,
      }),
  };
}

export function useSetupBackstageVM(p: {
  baseSessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}) {
  const { baseSessionId, roomId } = p;
  const [isJoining, setIsJoining] = useState(false);

  const backstageInfo = useQuery(
    api.Sessions.Rooms.LiveRoomFns.getBackstageInfo,
    { roomId, baseSessionId }
  );

  const onChooseJoinStage = useMutation(
    api.Sessions.Rooms.LiveRoomFns.onChooseJoinStage
  );

  const onChooseJoinStageClick = useCallback(() => {
    setIsJoining(true);
    onChooseJoinStage({ roomId })
      .finally(() => setIsJoining(false))
      .catch();
  }, [onChooseJoinStage, roomId]);

  return {
    backstageInfo,
    onChooseJoinStageClick,
    isJoining,
  };
}
