import { createEvent, createStore, sample } from "effector";
import type { Id } from "shared/be/convex/_generated/dataModel";

type GlobalMessengerViewState =
  | { _tag: "ALL_CHATS_LIST" }
  | { _tag: "CHAT_ROOM"; roomId: Id<"chatRooms"> };

type OpenState = "OPEN" | "TOP_TEASE" | "HIDDEN";

export class GlobalMessengerVM {
  setViewStateEvt = createEvent<GlobalMessengerViewState>();
  setOpenStateEvt = createEvent<OpenState>();
  setHiddenEvt = createEvent();

  $openState = createStore<OpenState>("HIDDEN");
  $viewState = createStore<GlobalMessengerViewState>({
    _tag: "ALL_CHATS_LIST",
  });

  constructor() {
    sample({
      clock: this.setViewStateEvt,
      target: this.$viewState,
    });

    sample({
      clock: this.setOpenStateEvt,
      target: this.$openState,
    });
  }
}
