import { useScrollToAnchor } from "../../../utils/utils.js";
import { Match } from "effect";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTypedHash } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import { SettingsContainer } from "./components/settings.components.js";

export const HpDashboardSettingsBillingDash: React.FC<{
  pathname: string;
  settingsBillingPath: string;
}> = ({ pathname, settingsBillingPath }) => {
  useScrollToAnchor();

  useEffect(() => {
    if (pathname === settingsBillingPath) {
      window.location.hash = "billing-method";
    }
  }, [pathname]);

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex gap-8">
        <Sidebar />
        <div className="flex flex-col gap-4 w-[50%]">
          <BillingMethodView />
          <TaxDocumentsView />
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
      <div>
        <SidebarTab tab="billing-method" />
      </div>
      <div>
        <SidebarTab tab="tax-documents" />
      </div>
    </div>
  );
};

type SideTabHashes = "billing-method" | "tax-documents";

function titleForTab(tab: SideTabHashes): string {
  return Match.value(tab).pipe(
    Match.when("billing-method", () => "Billing Method"),
    Match.when("tax-documents", () => "Tax Documents"),
    Match.exhaustive
  );
}

const SidebarTab: React.FC<{ tab: SideTabHashes }> = ({ tab }) => {
  const curHash = useTypedHash(UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING);

  return (
    <Link
      to={UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING.buildPath(
        {},
        undefined,
        tab
      )}
      className="flex items-center gap-2"
    >
      <div
        className={`rounded-lg pr-4 pl-4 py-2 font-sans ${
          curHash !== undefined && curHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </Link>
  );
};

const BillingMethodView: React.FC = () => {
  return (
    <SettingsContainer id="billing-method" title="Billing Method">
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Manage your payment methods and billing preferences here.
        </p>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p>No payment methods set up</p>
        </div>
      </div>
    </SettingsContainer>
  );
};

const TaxDocumentsView: React.FC = () => {
  return (
    <SettingsContainer id="tax-documents" title="Tax Documents">
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Access and download your tax-related documents.
        </p>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p>No tax documents</p>
        </div>
      </div>
    </SettingsContainer>
  );
};
