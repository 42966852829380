import { useMutation, useQuery } from "convex/react";
import { useState } from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { api } from "shared/be/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { Link } from "react-router-dom";

type Section = {
  type: "VIDEO" | "TEXT" | "QUIZ" | "INTERACTIVE";
  content: {
    videoUrl?: string;
    markdownContent?: string;
    quizQuestions?: any[];
    interactiveConfig?: any;
  };
};

export const EditCoursePage: React.FC = () => {
  const params = useTypedParams(UROUTES.COURSES.COURSE_ID);
  const courseId = params.courseId as Id<"courseInfo">;
  const [showForm, setShowForm] = useState(false);
  const [showModuleForm, setShowModuleForm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [moduleId, setModuleId] = useState<Id<"courseModules"> | null>(null);
  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");
  const [sections, setSections] = useState<Section[]>([
    { type: "VIDEO", content: { videoUrl: "" } },
  ]);

  const modules = useQuery(
    api.Learning.Courses.CoursesFns.getModulesWithLessons,
    {
      courseId,
    }
  );

  const createLesson = useMutation(
    api.Learning.Courses.LessonsFns.createLesson
  );

  const createModule = useMutation(
    api.Learning.Courses.CoursesFns.createModule
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!moduleId) return;

    await createLesson({
      moduleId,
      title,
      description,
      sections,
    });

    setTitle("");
    setDescription("");
    setSections([{ type: "VIDEO", content: { videoUrl: "" } }]);
    setShowForm(false);
  };

  const handleModuleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await createModule({
      courseId,
      title: moduleTitle,
      description: moduleDescription,
    });

    setModuleTitle("");
    setModuleDescription("");
    setShowModuleForm(false);
  };

  const updateSection = (index: number, updates: Partial<Section>) => {
    setSections((current) =>
      current.map((section, i) =>
        i === index ? { ...section, ...updates } : section
      )
    );
  };

  const addSection = () => {
    setSections((current) => [
      ...current,
      { type: "TEXT", content: { markdownContent: "" } },
    ]);
  };

  const removeSection = (index: number) => {
    setSections((current) => current.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col p-4 gap-4">
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Course Content</h1>
          <div className="flex gap-2">
            <button
              onClick={() => setShowModuleForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Module
            </button>
            <button
              onClick={() => setShowForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Lesson
            </button>
          </div>
        </div>

        {showModuleForm && (
          <form
            onSubmit={handleModuleSubmit}
            className="flex flex-col gap-4 max-w-md mt-8"
          >
            <div className="flex flex-col gap-2">
              <label htmlFor="moduleTitle">Module Title</label>
              <input
                id="moduleTitle"
                value={moduleTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setModuleTitle(e.target.value)
                }
                className="border rounded px-2 py-1"
                required
              />
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="moduleDescription">Description (optional)</label>
              <textarea
                id="moduleDescription"
                value={moduleDescription}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setModuleDescription(e.target.value)
                }
                className="border rounded px-2 py-1"
              />
            </div>

            <div className="flex gap-2">
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Create Module
              </button>
              <button
                type="button"
                onClick={() => setShowModuleForm(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        )}

        {modules?.map((module) => (
          <div key={module.id} className="flex flex-col gap-2">
            <h2 className="text-xl font-medium">{module.title}</h2>
            {module.description && (
              <p className="text-gray-600">{module.description}</p>
            )}
            <div className="flex flex-col gap-2 pl-4">
              {module.lessons.map((lesson) => (
                <Link
                  key={lesson.id}
                  to={UROUTES.COURSES.COURSE_ID.ADMIN.LESSON_ID.EDIT_LESSON.buildPath(
                    {
                      courseId: courseId,
                      lessonId: lesson.id,
                    }
                  )}
                  className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded"
                >
                  <div className="flex-1">
                    <span className="font-medium">{lesson.title}</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 max-w-md mt-8"
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="title">Title</label>
            <input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border rounded px-2 py-1"
              required
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="description">Description (optional)</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border rounded px-2 py-1"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="moduleId">Module</label>
            <select
              id="moduleId"
              value={moduleId || ""}
              onChange={(e) =>
                setModuleId(e.target.value as Id<"courseModules">)
              }
              className="border rounded px-2 py-1"
              required
            >
              <option value="">Select a module</option>
              {modules?.map((module) => (
                <option key={module.id} value={module.id}>
                  {module.title}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <h3 className="font-medium">Content Sections</h3>
              <button
                type="button"
                onClick={addSection}
                className="bg-blue-500 text-white px-3 py-1 rounded text-sm"
              >
                Add Section
              </button>
            </div>

            {sections.map((section, index) => (
              <div
                key={index}
                className="flex flex-col gap-2 border p-3 rounded"
              >
                <div className="flex justify-between">
                  <select
                    value={section.type}
                    onChange={(e) =>
                      updateSection(index, {
                        type: e.target.value as Section["type"],
                        content: {},
                      })
                    }
                    className="border rounded px-2 py-1"
                  >
                    <option value="VIDEO">Video</option>
                    <option value="TEXT">Text</option>
                    <option value="QUIZ">Quiz</option>
                    <option value="INTERACTIVE">Interactive</option>
                  </select>
                  {sections.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeSection(index)}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>

                {section.type === "VIDEO" && (
                  <input
                    type="url"
                    placeholder="Video URL"
                    value={section.content.videoUrl || ""}
                    onChange={(e) =>
                      updateSection(index, {
                        content: { videoUrl: e.target.value },
                      })
                    }
                    className="border rounded px-2 py-1"
                  />
                )}

                {section.type === "TEXT" && (
                  <textarea
                    placeholder="Text content"
                    value={section.content.markdownContent || ""}
                    onChange={(e) =>
                      updateSection(index, {
                        content: { markdownContent: e.target.value },
                      })
                    }
                    className="border rounded px-2 py-1 min-h-[100px]"
                  />
                )}
              </div>
            ))}
          </div>

          <div className="flex gap-2">
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Create Lesson
            </button>
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
