import { parseAppEnv } from "shared/index";

const apiUrl = import.meta.env["VITE_API_URL"];
const appEnvStr = import.meta.env["VITE_APP_ENV"];
const authAppEnvStr = import.meta.env["VITE_AUTH_ENV"];

const appEnv = parseAppEnv(appEnvStr);

const authAppEnv = parseAppEnv(authAppEnvStr);

export const AppConfig = {
  apiUrl,
  appEnv,
  authAppEnv,
};
