import { EmdrBallRtcContent } from "@/src/domains/sessions/rtc/rooms/live/menu/tools/emdr/emdr-ball-vis-code";
import { CustomParticipantView } from "@/src/domains/sessions/rtc/rooms/live/participant-view";
import {
  useCall,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { format } from "date-fns";
import { Match } from "effect";
import {
  useGetClockInfo,
  useLiveRoomVM,
} from "frontend-shared/src/sessions/live-room.vm";
import { MaximizeIcon } from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import type React from "react";
import { useEffect, useState } from "react";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  KnownVisualizationMediaFile,
  ParticipantFocusedLayout,
  SelectedContent,
  StageViewLayout,
  TitleDisplaySection,
} from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { isNullOrUndefined } from "shared/util";
import BirdseyeWaves from "frontend-shared/src/resources/meditation/birdseye-waves.mp4";
import Candle from "frontend-shared/src/resources/meditation/candle.mp4";
import WaterStream from "frontend-shared/src/resources/meditation/stream.mp4";
import {
  AvatarCircle,
  AvatarCircles,
} from "web-shared/src/components/avatar.tc";
import { SessionWrapup } from "../../../analysis/wrapup/wrapup";
import { ConnectingAnimation } from "../../camera";
import { BreakoutRoomsDnd } from "../breakout-rooms-dnd";
import { QuickActionButtonPanel } from "./quick-action-panel";

function useTempShowConnecting() {
  const [showConnecting, setShowConnecting] = useState(true);
  useEffect(() => {
    setTimeout(() => setShowConnecting(false), 1000);
  }, []);
  return showConnecting;
}

export const StageView: React.FC<{
  stageViewLayout: StageViewLayout;
  isMaximized: boolean;
}> = ({ stageViewLayout, isMaximized }) => {
  const { stageView } = useLiveRoomVM();

  if (isMaximized) {
    return (
      <ContentFocusedView
        toggleMaximized={() => {
          stageView.toggleStageViewMaximized();
        }}
      />
    );
  }

  return (
    <div className="flex-1 min-h-0 flex gap-2">
      <div className="self-stretch flex flex-col-reverse pb-2">
        <QuickActionButtonPanel />
      </div>
      <div className="flex-1 flex flex-col gap-2">
        <StageTopNav />
        <div className="flex-1 flex min-h-0">
          <div className="flex-1 flex flex-col">
            <StageMainContentView
              stageViewLayout={stageViewLayout}
              toggleMaximized={() => {
                stageView.toggleStageViewMaximized();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StageMainContentView: React.FC<{
  stageViewLayout: StageViewLayout;
  toggleMaximized: () => void;
}> = ({ stageViewLayout, toggleMaximized }) => {
  return Match.value(stageViewLayout).pipe(
    Match.when(null, () => {
      return (
        <div className="flex-1 flex flex-col gap-2">
          <div className="text-white text-lg font-bold">
            No stage view layout
          </div>
        </div>
      );
    }),
    Match.when({ tag: "PARTICIPANTS_FOCUSED" }, ({ layout }) => {
      return <ParticipantFocusedView layout={layout} />;
    }),
    Match.when({ tag: "CONTENT_FOCUSED" }, ({ participantFocusedLayout }) => {
      return (
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex-1 flex flex-col min-h-0">
            <ContentFocusedView
              toggleMaximized={() => {
                toggleMaximized();
              }}
            />
          </div>
          <div className="basis-[200px] flex flex-col min-h-0">
            <ParticipantFocusedView layout={participantFocusedLayout} />
          </div>
        </div>
      );
    }),
    Match.exhaustive
  );
};

const ParticipantFocusedView: React.FC<{
  layout: ParticipantFocusedLayout;
}> = ({ layout }) => {
  console.log("rendering participant focused view", layout);
  return Match.value(layout).pipe(
    Match.when(
      { tag: "ONE_ON_ONE" },
      ({ bigParticipant, smallParticipant }) => {
        console.log("rendering one on one", bigParticipant, smallParticipant);
        return (
          <div className="flex-1 flex flex-col relative">
            <ParticipantVideo user={bigParticipant} />
            <div className="absolute bottom-4 right-4 w-[200px] aspect-video">
              <ParticipantVideo user={smallParticipant} />
            </div>
          </div>
        );
      }
    ),
    Match.when({ tag: "SELF_VIEW" }, ({ me }) => {
      return <ParticipantVideo user={me} />;
    }),
    Match.when(
      { tag: "SPEAKER_VIEW" },
      ({ mainSpeaker, otherParticipants }) => {
        return (
          <div className="flex-1 flex flex-col relative">
            <ParticipantVideo user={mainSpeaker} />
            <div className="absolute top-4 right-4 flex gap-2">
              {otherParticipants.map((p) => (
                <div key={p.id} className="w-[160px] aspect-video">
                  <ParticipantVideo user={p} />
                </div>
              ))}
            </div>
          </div>
        );
      }
    ),
    Match.when({ tag: "GALLERY" }, ({ participants }) => {
      return <GalleryView participants={participants} />;
    }),
    Match.exhaustive
  );
};

const GalleryView: React.FC<{
  participants: SimpleUser[];
}> = ({ participants }) => {
  return (
    <div
      className={`flex-1 grid gap-2 ${getGalleryGridLayout(participants.length)}`}
    >
      {participants.map((p) => (
        <ParticipantVideo user={p} />
      ))}
    </div>
  );
};

function getGalleryGridLayout(participantCount: number): string {
  switch (participantCount) {
    case 1:
      return "grid-cols-1";
    case 2:
      return "grid-cols-2";
    case 3:
      return "grid-cols-2";
    case 4:
      return "grid-cols-2 grid-rows-2";
    case 5:
    case 6:
      return "grid-cols-3 grid-rows-2";
    case 7:
    case 8:
    case 9:
      return "grid-cols-3 grid-rows-3";
    default:
      return "grid-cols-4 grid-rows-3"; // For larger numbers
  }
}

const ParticipantVideo: React.FC<{
  user: SimpleUser;
}> = ({ user }) => {
  const call = useCall();

  if (call === undefined) {
    return <ConnectingAnimation isConnected={false} />;
  }

  return <ParticipantVideoConnected user={user} call={call} />;
};

const ParticipantVideoConnected: React.FC<{
  user: SimpleUser;
  call: Call;
}> = ({ user }) => {
  const { useParticipants } = useCallStateHooks();
  const participants = useParticipants();
  const participant = participants.find((p) => p.userId === user.id);

  if (isNullOrUndefined(participant)) {
    return <ParticipantVideoPlaceholder participant={user} />;
  }

  console.log("rendering participant video", participant);

  return (
    <div className="flex-1 flex flex-col min-h-0 relative min-w-[200px] min-h-[200px]">
      <CustomParticipantView participant={participant} user={user} />
    </div>
  );
};

export const ParticipantVideoPlaceholder: React.FC<{
  participant: SimpleUser;
}> = ({ participant }) => {
  return (
    <div
      className="flex-1 flex flex-col justify-center items-center gap-2 bg-vid-black-900 text-white border border-blue-400 rounded-[20px] p-4"
      // style={style}
    >
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(participant.profilePhoto)}
        size={60}
      />
      <h4 className="font-bold text-lg">{participant.name}</h4>
    </div>
  );
};

const ContentFocusedView: React.FC<{
  toggleMaximized: () => void;
}> = ({ toggleMaximized }) => {
  const { roomId, baseSessionId, selectedContent$ } = useLiveRoomVM();
  const selectedContent = useObservableEagerState(selectedContent$);

  console.log("selectedContent", selectedContent);

  if (isNullOrUndefined(selectedContent)) {
    return <div></div>;
  }

  return (
    <div className="flex-1 flex flex-col gap-2 relative rounded-[12px]">
      {viewForSelectedContent(selectedContent, roomId, baseSessionId)}
      <div
        className="absolute bottom-4 right-4 cursor-pointer"
        onClick={toggleMaximized}
      >
        <MaximizeIcon className="text-white" />
      </div>
    </div>
  );
};

function viewForSelectedContent(
  selectedContent: SelectedContent,
  roomId: Id<"rtcLiveRooms">,
  sessionId: Id<"sessionConfig">
) {
  return Match.value(selectedContent).pipe(
    Match.when({ tag: "VISUALIZATION" }, ({ mediaFile }) => {
      return <VisulaziationContentView mediaFile={mediaFile} />;
    }),
    Match.when({ tag: "EMDR" }, () => {
      return <EmdrBallRtcContent roomId={roomId} />;
    }),
    Match.when({ tag: "SETUP_BREAKOUT_ROOMS" }, ({ activityId }) => {
      return <BreakoutRoomsDnd activityId={activityId} />;
    }),
    Match.when({ tag: "REVIEW" }, () => {
      return (
        <div className="flex-1 flex flex-col bg-white overflow-y-clip max-h-[90%] rounded-[12px] p-8">
          <SessionWrapup sessionId={sessionId} />
        </div>
      );
    }),
    Match.exhaustive
  );
}

const VisulaziationContentView: React.FC<{
  mediaFile: KnownVisualizationMediaFile | null;
}> = ({ mediaFile }) => {
  if (mediaFile === null) {
    return null;
  }

  return (
    <video
      className="absolute inset-0 w-full h-full object-cover"
      src={matchMeditationVideo(mediaFile)}
      autoPlay
      loop
      muted
    />
  );
};

const StageTopNav: React.FC = () => {
  const { stageView, roomId } = useLiveRoomVM();
  const titleDisplaySection = useObservableEagerState(
    stageView.titleDisplaySection$
  );

  if (!titleDisplaySection) {
    return <div className="h-[50px] w-full"></div>;
  }

  return (
    <StageTopNavView
      titleDisplaySection={titleDisplaySection}
      clockSection={<ClockSection />}
      roomId={roomId}
    />
  );
};

const ClockSection: React.FC = () => {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const { mainCountdownClockInfo$, specialClockDisplay$ } = useGetClockInfo({
    baseSessionId,
    roomId,
  });
  const mainCountdownClock = useObservableEagerState(mainCountdownClockInfo$);
  const specialClockDisplay = useObservableEagerState(specialClockDisplay$);

  if (mainCountdownClock === undefined) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      {specialClockDisplay && (
        <CountdownClockView
          timeLeftSeconds={specialClockDisplay.secondsLeft}
          backgroundColor={Match.value(specialClockDisplay.color).pipe(
            Match.when("red", () => "red-400"),
            Match.when("purple", () => "vid-purple"),
            Match.exhaustive
          )}
          showSeconds={true}
          // message={specialClockDisplay.source}
        />
      )}
      <ClockSectionView mainCountdownClock={mainCountdownClock} />
    </div>
  );
};

const ClockSectionView: React.FC<{
  mainCountdownClock: {
    secondsLeft: number;
    showWarning: string | null;
  };
}> = ({ mainCountdownClock }) => {
  return (
    <CountdownClockView
      timeLeftSeconds={mainCountdownClock.secondsLeft}
      showSeconds={false}
      backgroundColor={
        mainCountdownClock.showWarning ? "vid-purple" : undefined
      }
      message={mainCountdownClock.showWarning ?? undefined}
    />
  );
};

const CountdownClockView: React.FC<{
  timeLeftSeconds: number | null;
  onClick?: () => void;
  backgroundColor?: string;
  showSeconds?: boolean;
  message?: string;
}> = ({ timeLeftSeconds, onClick, backgroundColor, showSeconds, message }) => {
  return (
    <TopItemContainer
      backgroundColor={backgroundColor}
      onClick={onClick}
      px="5"
    >
      <img src="/alarm.svg" alt="Alarm icon" width={20} height={20} />
      {message ? (
        <div>{message}</div>
      ) : (
        timeLeftSeconds !== null &&
        (showSeconds
          ? format(new Date(timeLeftSeconds * 1000), "m:ss")
          : `${Math.floor(timeLeftSeconds / 60)}`)
      )}
    </TopItemContainer>
  );
};

const StageTopNavView: React.FC<{
  titleDisplaySection: TitleDisplaySection;
  clockSection: React.ReactNode;
  roomId: Id<"rtcLiveRooms">;
}> = ({ titleDisplaySection, clockSection, roomId }) => {
  console.log("rendering stage top nav", roomId);
  return (
    <div className="flex justify-between gap-4">
      <TopItemContainer>
        {Match.value(titleDisplaySection).pipe(
          Match.when(
            { tag: "TITLE_AND_PARTICIPANTS" },
            ({ participants, title }) => (
              <div className="flex items-center gap-2 pr-4">
                <AvatarCircles
                  sources={participants.map((p) => p.profilePhoto)}
                  size={40}
                  padding={0}
                />
                {title && <div className="text-white font-medium">{title}</div>}
              </div>
            )
          ),
          Match.when({ tag: "APPROVE_USER_ENTERED" }, ({ userName }) => (
            <div>{userName}</div> // TODO
          )),
          Match.exhaustive
        )}
        {/* <div className="text-white">{roomId}</div> */}
      </TopItemContainer>
      {clockSection}
    </div>
  );
};

const TopItemContainer: React.FC<{
  children: React.ReactNode;
  backgroundColor?: string;
  onClick?: () => void;
  px?: string;
  py?: string;
}> = ({ children, backgroundColor, onClick, px, py }) => {
  return (
    <div
      className={`border border-vid-black-800 rounded-[30px] flex items-center gap-2 text-white 
        ${px ? `px-${px}` : "px-2"} 
        ${py ? `py-${py}` : "py-1"} 
        h-full ${backgroundColor ? `bg-${backgroundColor}` : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const matchMeditationVideo = (mediaFile: KnownVisualizationMediaFile) => {
  return Match.value(mediaFile).pipe(
    Match.when("candle.mp4", () => Candle),
    Match.when("birdseye-waves.mp4", () => BirdseyeWaves),
    Match.when("stream.mp4", () => WaterStream),
    Match.exhaustive
  );
};
