import { useAction, useConvex, type ConvexReactClient } from "convex/react";
import { Option } from "effect";
import { useOnce, useQuery$ } from "frontend-shared/src/util";
import { useEffect, useMemo } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { api } from "shared/be/convex/_generated/api";
import type { ChatRoomSimple } from "shared/be/convex/Chat/Chat.Types";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { isNotNullOrUndefined } from "shared/util";
import { GlobalMessengerVM } from "web-shared/src/domains/chat/messenger.vm";
import { BaseStateMgr } from "../base.statemgr";
import {
  GenericRtcMgr,
  type GenericRtcChannelMgr,
  type GenericRtcEngine,
} from "../rtc.statemgr";
/*
    Common states/actions for all users (hp, cp, etc)
*/

export abstract class UserStateMgr<
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
> extends BaseStateMgr {
  me$: Rx.BehaviorSubject<SimpleUserWithProfilePhoto>;
  profilePhoto$: Rx.Observable<Option.Option<ImageSrc>>; // derivced value for easier access

  rtcMgr$ = new Rx.BehaviorSubject<RtcMgr | null>(null);

  messengerVM = new GlobalMessengerVM();

  constructor(
    readonly p: {
      me: SimpleUserWithProfilePhoto;
      convex: ConvexReactClient;
      rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
      roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
      mkRtcMgr: (p: RtcCredentials) => RtcMgr;
    }
  ) {
    super({ convex: p.convex });
    this.me$ = new Rx.BehaviorSubject(p.me);
    this.profilePhoto$ = this.me$.pipe(
      RxO.map((me) =>
        Option.fromNullable(me.profilePhoto).pipe(
          Option.map((url) => new ImageSrc({ _tag: "URL", url }))
        )
      )
    );

    const watch = this.convex.watchQuery(api.User.UserFns.getMbMe, {});

    watch.onUpdate(() => {
      const nextMe = watch.localQueryResult();
      if (isNotNullOrUndefined(nextMe)) {
        this.me$.next(nextMe);
      }
    });

    p.rtcCreds$.subscribe((rtcCreds) => {
      console.log("$$$$$$RTC CREDENTIALS! ", rtcCreds);
      if (rtcCreds !== null) {
        this.rtcMgr$.next(p.mkRtcMgr(rtcCreds));
      } else {
        this.rtcMgr$.next(null);
      }
    });
  }
}

export function useSetupUStateMgr<
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
  PlatUStateMgr extends UserStateMgr<RtcChannelMgr, RtcEngine, RtcMgr>,
>(
  me: SimpleUserWithProfilePhoto,
  mkPlatUStateMgr: (p: {
    me: SimpleUserWithProfilePhoto;
    convex: ConvexReactClient;
    roomsIBelongTo$: Rx.BehaviorSubject<ChatRoomSimple[] | undefined>;
    rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
  }) => PlatUStateMgr
): Option.Option<PlatUStateMgr> {
  const rtcCreds$ = useOnce(
    () => new Rx.BehaviorSubject<RtcCredentials | null>(null)
  );

  const convex = useConvex();

  const getMyRtcCreds = useAction(
    api.ThirdPartyServices.CallNodeFns.getMyRtcCreds
  );

  useEffect(() => {
    getMyRtcCreds().then((r) => {
      rtcCreds$.next(r);
    });
  }, []);

  const roomsIBelongTo$ = useQuery$(api.Chat.ChatFns.getAllMyRooms);

  const mbUStateMgr = useMemo(
    () =>
      mkPlatUStateMgr({
        me,
        convex,
        roomsIBelongTo$: roomsIBelongTo$,
        rtcCreds$: rtcCreds$,
      }),
    [rtcCreds$]
  );

  // const mbUStateMgr = useObservableEagerState(mbUStateMgr$);

  return Option.some(mbUStateMgr);
}
