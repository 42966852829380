import { CommunityCardContainer } from "@/src/domains/communities/community/components/community-card";
import { useNavigate } from "react-router-dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";

interface MemberCardProps {
  userId: Id<"users">;
  name: string;
  profilePhoto: string | null;
  modalities: string[];
  index: number;
  isAvailable: boolean;
}

export const MemberCard: React.FC<MemberCardProps> = ({
  userId,
  name,
  profilePhoto,
  modalities,
  index,
}) => {
  const nav = useNavigate();
  return (
    <CommunityCardContainer
      title={name}
      height="small"
      content={<div>{modalities.join(" | ")}</div>}
      headerBackgroundType={{ _tag: "INDEXED", index }}
      onClick={() => {
        nav(
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.DISCUSSION.buildPath(
            { userId }
          )
        );
      }}
      button={{
        title: "View",
      }}
      innerCircleComponent={
        <AvatarCircle
          size={56}
          mbProfilePhoto={profilePhoto ? ImageSrc.fromURL(profilePhoto) : null}
        />
      }
    />
  );
};
