import { useOnChatRoomMount } from "frontend-shared/src/chat/chat-room.vm";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { ChatRoomMessage } from "shared/be/convex/Chat/Chat.Types";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";

export const ChatRoom: React.FC<{
  roomId: Id<"chatRooms">;
}> = ({ roomId }) => {
  const { messages, onSend } = useOnChatRoomMount(roomId);

  if (messages === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <ChatRoomView messages={messages} onSend={onSend} />;
};

const ChatRoomView: React.FC<{
  messages: ChatRoomMessage[];
  onSend: (message: string) => void;
}> = ({ messages, onSend }) => {
  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const previousMessagesLength = useRef(messages.length);

  useEffect(() => {
    if (messages.length > previousMessagesLength.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    previousMessagesLength.current = messages.length;
  }, [messages]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim()) {
      onSend(input);
      setInput("");
    }
  };

  const sortedMessages = useMemo(
    () =>
      [...messages].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
    [messages]
  );

  return (
    <div className="flex flex-col h-[500px]">
      <div className="flex flex-col flex-1 bg-gray-50 overflow-hidden">
        <div className="flex-1 overflow-y-auto p-4 space-y-4 flex flex-col-reverse">
          <div ref={messagesEndRef} />
          {sortedMessages.map((message) => (
            <div
              key={message._id}
              className="animate-[fadeIn_0.3s_ease-in-out]"
            >
              <ChatMessage message={message} />
            </div>
          ))}
        </div>

        <form
          onSubmit={handleSubmit}
          className="h-[70px] shrink-0 p-4 bg-white border-t"
        >
          <div className="flex gap-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
              placeholder="Type a message..."
            />
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ChatMessage: React.FC<{
  message: ChatRoomMessage;
}> = ({ message }) => {
  return (
    <div className="flex gap-3">
      {message.user.avatar ? (
        <img
          src={message.user.avatar}
          alt={message.user.name}
          className="w-8 h-8 rounded-full"
        />
      ) : (
        <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
          {message.user.name[0]}
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex gap-2 items-baseline">
          <span className="font-medium">{message.user.name}</span>
          <span className="text-sm text-gray-500">
            {new Date(message.createdAt).toLocaleTimeString()}
          </span>
        </div>
        <p className="text-gray-700">{message.text}</p>
      </div>
    </div>
  );
};
