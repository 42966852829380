import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { PrimaryButton } from "web-shared/src/components/button";
import {
  DashboardLayoutContainer,
  LeftMenu,
  NavbarContainer,
} from "web-shared/src/components/dashboard/dashboard-layout";
import {
  CalendarIcon,
  ClientsIcon,
  CommunityIcon,
  InvoicesIcon,
  LeftMenuLink,
  SettingsIcon,
} from "web-shared/src/components/dashboard/main-nav-links";
import { AuthinForm } from "web-shared/src/domains/auth/authin.form";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";
import { useDialogVM } from "web-shared/src/web-context";

export const AnonDashLayout: React.FC = () => {
  return (
    <AnonDashboardContainer>
      <Outlet />
    </AnonDashboardContainer>
  );
};

function useOnAnonDashClick() {
  const dialogVM = useDialogVM();

  return () => {
    dialogVM.openVM.event(
      <AuthinForm
        onAuthinSuccess={() => {
          dialogVM.closeDialogue();
        }}
        logoIcon={<InsightLiveLogoWithText />}
      />
    );
  };
}

export const AnonDashboardContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const onAnonDashClick = useOnAnonDashClick();
  return (
    <div className="flex flex-col w-full h-full">
      <DashboardLayoutContainer
        leftMenu={
          <LeftMenu
            logoWithText={<InsightLiveLogoWithText />}
            links={[
              <LeftMenuLink
                to={null}
                name="Clients"
                onClick={onAnonDashClick}
                icon={(isSelected) => <ClientsIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Community"
                icon={() => <CommunityIcon isSelected={true} />}
                overrideSelected={true}
              />,
              <LeftMenuLink
                to={null}
                name="Calendar"
                onClick={onAnonDashClick}
                icon={(isSelected) => <CalendarIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Invoices"
                onClick={onAnonDashClick}
                icon={(isSelected) => <InvoicesIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Settings"
                onClick={onAnonDashClick}
                icon={(isSelected) => <SettingsIcon isSelected={isSelected} />}
              />,
            ]}
            onLogoClick={() => {
              window.location.href = "/";
            }}
            extraTop={
              <PrimaryButton title="Schedule session" onClick={() => {}} />
            }
          />
        }
        mainContent={children}
        navbar={
          <NavbarContainer
            title="Community"
            rightSection={<NavbarRightSection />}
          />
        }
        showReloading={false}
        showBottomToast={null}
        closeToast={() => {}}
      />
    </div>
  );
};

const NavbarRightSection: React.FC = () => {
  const nav = useNavigate();
  return (
    <div className="flex items-center gap-4">
      <button
        className="text-vid-purple font-medium flex justify-center items-center px-6 py-3"
        onClick={() => {
          nav(ROUTES.AUTH.AUTHIN.path);
        }}
      >
        Sign up
      </button>
      <PrimaryButton
        title="Login"
        onClick={() => {
          nav("/login");
        }}
      />
    </div>
  );
};
