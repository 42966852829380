import type React from "react";
import { Link } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { SessionWrapup } from "src/domains/sessions/analysis/wrapup/wrapup";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";

export const USessionReviewPage: React.FC = () => {
  const { sessionId } = useTypedParams(UROUTES.SESSIONS.SESSION_ID);

  return (
    <div className="flex-1 flex flex-col  gap-4">
      <Navbar />
      <div className="flex-1 flex flex-col items-center gap-8 py-4">
        <h1 className="text-[36px] font-medium">Session wrap up</h1>
        <SessionWrapup sessionId={sessionId as Id<"sessionConfig">} />
      </div>
    </div>
  );
};

const Navbar: React.FC = () => {
  return (
    <nav className="flex flex-row justify-between items-center border-b-2 px-8 py-6 shadow-sm">
      <div
        className="cursor-pointer"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <InsightLiveLogoWithText />
      </div>
      <div className="flex flex-1" />
      <Link
        to={UROUTES.HP.MY.DASHBOARD.CLIENTS.path}
        className="text-vid-purple"
      >
        Back to dashboard
      </Link>
    </nav>
  );
};
