import React from "react";
import { useLocation } from "react-router-dom";
import { HPROUTES } from "shared/routes/routes";
import { HpSettingsClientAndSchedulingDash } from "web-shared/src/domains/hp/settings/clients-and-scheduling-dash.js";

export const HpSettingsClientAndSchedulingPage: React.FC = () => {
  const { pathname } = useLocation();
  const settingsClientAndSchedulingPath =
    HPROUTES.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.relativePath;
  return (
    <HpSettingsClientAndSchedulingDash
      pathname={pathname}
      settingsClientAndSchedulingPath={settingsClientAndSchedulingPath}
    />
  );
};
