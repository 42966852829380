export const CameraStrikethruSvg = () => {
  return (
    <svg width="30" height="31" fill="none" viewBox="0 0 30 31">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 24.625h10.664c3.938 0 5.263-1.313 5.263-5.264v-8.026c0-.527-.023-1.007-.073-1.443a6.355 6.355 0 00-.301-1.392c-.626-1.774-2.1-2.428-4.889-2.428h-7.9c-3.951 0-5.264 1.313-5.264 5.263v8.026c0 .733.101 1.464.332 2.139M24.403 21.724l-3.475-2.437v-7.889l3.475-2.438c1.7-1.187 3.1-.462 3.1 1.626v9.526c0 2.088-1.4 2.813-3.1 1.613zM24 5L2 27"
      ></path>
    </svg>
  );
};

export const CameraSvg = ({
  color = "#3A3A3A",
  size = 30,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 30 30">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.664 24.278h-7.9c-3.951 0-5.264-2.625-5.264-5.263v-8.026c0-3.95 1.313-5.263 5.263-5.263h7.901c3.95 0 5.263 1.312 5.263 5.263v8.026c0 3.95-1.325 5.263-5.263 5.263zM24.403 21.378l-3.475-2.438v-7.889l3.475-2.437c1.7-1.188 3.1-.463 3.1 1.625v9.526c0 2.088-1.4 2.813-3.1 1.613z"
      ></path>
    </svg>
  );
};
