// App.tsx
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useQuery } from "convex/react";
import { useUnit } from "effector-react";
import React from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { useBreakoutRoomsVM } from "./live/activities/breakout-rooms/setup-breakout-rooms.vm";
import { LiveRoomLayoutView } from "./live/live-room";

export const MockBreakoutRoomsSimulator: React.FC = () => {
  return (
    <LiveRoomLayoutView
      stageAreaView={
        <div className="flex-1 flex flex-col translate-y-full">
          {/* <MockBreakoutRoomsDnd /> */}
        </div>
      }
      maximizedView={<></>}
      rightDrawer={<></>}
      controlPanel={<div></div>}
    />
  );
};

export const BreakoutRoomsDnd: React.FC<{
  activityId: Id<"rtcRoomActivity">;
}> = ({ activityId }) => {
  const vm = useBreakoutRoomsVM({ activityId });
  const rooms = useUnit(vm.$usersInRooms);
  const unassigned = useUnit(vm.$unassignedUsers) ?? [];
  const activeUser = useUnit(vm.$activeUser.store);

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragStart(event: DragStartEvent) {
    const id = String(event.active.id);
    const foundInUnassigned = unassigned.find((user) => user === id);
    if (foundInUnassigned) {
      vm.setActiveUser(foundInUnassigned);
    } else {
      for (const room of rooms ?? []) {
        const found = room.users.find((user) => user === id);
        if (found) {
          vm.setActiveUser(found);
          break;
        }
      }
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!activeUser) return;
    const activeId = active.id.toString();

    console.log("activeId", activeId);
    console.log("over", over);

    if (!over) {
      vm.setActiveUser(null);
      return;
    }

    if (over.id === "unassigned") {
      vm.moveUserToUnassigned(activeUser);
    } else if (over.id !== activeId) {
      console.log("moveUserToRoom", activeUser, over.id);
      vm.moveUserToRoom({ userId: activeUser, roomId: over.id.toString() });
    }

    vm.setActiveUser(null);
  }

  const getGridClassName = (roomCount: number) => {
    switch (roomCount) {
      case 2:
        return "grid grid-cols-2 gap-4";
      case 3:
        // 2 rooms on left, 1 on right using grid areas
        return "grid grid-cols-2 gap-4 [grid-template-areas:'a_c'_'b_c']";
      case 4:
        return "grid grid-cols-2 gap-4";
      default:
        return "grid grid-cols-3 gap-4";
    }
  };

  return (
    <div className="flex-1 flex flex-col bg-vid-black-900 text-white p-6">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className={`${getGridClassName(rooms?.length || 0)} flex-1`}>
          {rooms?.map((room, index) => (
            <DroppableArea
              key={room.brSessionRoomId}
              id={room.brSessionRoomId}
              label={room.title}
              className={`w-full min-h-[215px] flex flex-wrap justify-center items-start gap-2 ${
                rooms.length === 3
                  ? `[grid-area:${["a", "b", "c"][index]}]`
                  : ""
              }`}
            >
              {room.users.map((uid) => (
                <DraggableUser key={uid} uid={uid} />
              ))}
            </DroppableArea>
          ))}
        </div>
      </DndContext>
    </div>
  );
};

interface DroppableAreaProps {
  id: UniqueIdentifier;
  label: string;
  className?: string;
  children?: React.ReactNode;
}
function DroppableArea({ id, label, className, children }: DroppableAreaProps) {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div
      ref={setNodeRef}
      className={`relative flex flex-wrap items-start p-4 rounded-[12px]  border border-vid-black-800 ${className}`}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="absolute top-2 left-2 bg-black/50 rounded px-2 py-1 text-sm">
        {label}
      </div>
      {children}
    </div>
  );
}

interface DraggableUserProps {
  uid: Id<"users">;
}
function DraggableUser({ uid }: DraggableUserProps) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: uid,
    });

  const style = {
    transform: transform ? CSS.Translate.toString(transform) : undefined,
    zIndex: isDragging ? 50 : undefined,
  };

  const user = useQuery(api.User.UserFns.getSimpleUser, { baseUserId: uid });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className="cursor-grab w-[66px] aspect-square flex items-center justify-center rounded-full bg-blue-500 text-white"
    >
      {user !== undefined && (
        <AvatarCircle
          mbProfilePhoto={ImageSrc.fromMbUrl(user.profilePhoto)}
          size={64}
        />
      )}
    </div>
  );
}
