import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import {
  createContextAndHook,
  useOnce,
  useQuery$,
} from "frontend-shared/src/util";
import { BookmarkIcon } from "lucide-react";
import { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { QuickActionToolTag } from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import { RxO } from "web-shared/src/prelude";
import {
  QUICK_ACTION,
  QuickActionButton,
} from "../../../quick-action/quick-action-button";
import { QuickActionButtonVM } from "../../../quick-action/quick-action.vm";

export const QuickActionButtonPanel: React.FC = () => {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const qaTools = useQuery(api.Sessions.Rooms.LiveRoomFns.getQuickActionTools, {
    baseSessionId: baseSessionId,
    roomId: roomId,
  });

  if (qaTools === undefined) {
    return null;
  }

  return <QuickActionButtonPanelView quickActionTools={qaTools} />;
};

class QuickActionButtonPanelVM {
  openNotesMenuForMe: () => Promise<null>;
  openQuickScheduleMenuForMe: () => Promise<null>;

  constructor(p: {
    openNotesMenuForMe: () => Promise<null>;
    openQuickScheduleMenuForMe: () => Promise<null>;
  }) {
    this.openNotesMenuForMe = p.openNotesMenuForMe;
    this.openQuickScheduleMenuForMe = p.openQuickScheduleMenuForMe;
  }
}

const [QuickActionButtonPanelVMContext, useQuickActionButtonPanelVM] =
  createContextAndHook<QuickActionButtonPanelVM>();

const QuickActionButtonPanelView: React.FC<{
  quickActionTools: QuickActionToolTag[];
}> = ({ quickActionTools }) => {
  const { baseSessionId, roomId } = useLiveRoomVM();

  const openToolSpecificMenuForMe = useMutation(
    api.Sessions.Rooms.Live.LiveRoomMenuFns.openToolSpecificMenuForMe
  );

  const openNotesMenuForMe = () => {
    return openToolSpecificMenuForMe({
      baseSessionId,
      roomId,
      knownToolTag: "NOTES",
    });
  };

  const openQuickScheduleMenuForMe = () => {
    return openToolSpecificMenuForMe({
      baseSessionId,
      roomId,
      knownToolTag: "QUICK_SCHEDULE",
    });
  };

  const vm = useOnce(
    () =>
      new QuickActionButtonPanelVM({
        openNotesMenuForMe,
        openQuickScheduleMenuForMe,
      })
  );

  return (
    <QuickActionButtonPanelVMContext.Provider value={vm}>
      <div className="w-[55px] pt-4 relative z-50 h-[300px]">
        <div className="flex flex-col justify-end gap-14 h-full pb-12">
          {quickActionTools.map((qaTool) =>
            Match.value(qaTool).pipe(
              Match.when("BOOKMARK", () => {
                return <BookmarkButton key="bookmark" />;
              }),
              Match.when("BOOKEND", () => {
                return <RecordSpanButton key="record-span" />;
              }),
              Match.when("NOTES", () => <NotesButton key="notes" />),
              Match.when("QUICK_SCHEDULE", () => (
                <QuickScheduleButton key="quick-schedule" />
              )),
              Match.exhaustive
            )
          )}
        </div>
      </div>
    </QuickActionButtonPanelVMContext.Provider>
  );
};

const BookmarkButton: React.FC = () => {
  const qavm = useQuickActionButtonPanelVM();
  const { baseSessionId } = useLiveRoomVM();
  const onBookmarkClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onBookmarkClick
  );

  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onSave: async () => {
          console.log("saving bookmark");
          await onBookmarkClick({ baseSessionId });
          qavm.openNotesMenuForMe().catch();
        },
      })
  );

  useEffect(() => {
    vm.$state.watch((state) => {
      if (state.tag === "OPEN" && state.state === "IDLE") {
        vm.save();
      }
    });
  }, [vm]);

  return (
    <QuickActionButton
      config={{
        id: "bookmark",
        icon: <BookmarkIcon />,
        label: "Bookmark",
      }}
      vm={vm}
    />
  );
};

const RecordSpanButton: React.FC = () => {
  const qavm = useQuickActionButtonPanelVM();
  const { baseSessionId } = useLiveRoomVM();

  const isRecording$ = useQuery$(
    api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
    {
      baseSessionId: baseSessionId,
    }
  );

  const onStartRecordingClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onSpanRecordingQuickActionClick
  ).withOptimisticUpdate((localState, args) => {
    const isRecording = localState.getQuery(
      api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
      {
        baseSessionId: args.baseSessionId,
      }
    );

    if (isRecording !== undefined) {
      localState.setQuery(
        api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
        {
          baseSessionId: args.baseSessionId,
        },
        true
      );
    }
  });

  const onStopRecordingClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onStopRecordingQuickActionClick
  );

  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onCapture: () => onStartRecordingClick({ baseSessionId }),
        onSave: async () => {
          await onStopRecordingClick({ baseSessionId });
          qavm.openNotesMenuForMe().catch();
        },
      })
  );
  const [state] = useUnit([vm.$state]);
  const isCapturing = state.tag === "OPEN" && state.state === "CAPTURING";

  useEffect(() => {
    isRecording$.pipe(RxO.distinctUntilChanged()).subscribe((isRecording) => {
      if (isRecording) {
        vm.setCaptureStateOnly();
      }
    });
  }, [isRecording$]);

  return (
    <QuickActionButton
      config={{
        id: "record-span",
        icon: <RecordSpanIcon isRecording={isCapturing} />,
        label: "Recording",
      }}
      vm={vm}
      actionButton={
        <button
          onClick={() => (isCapturing ? vm.save() : vm.startCapturingEvt())}
          className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-colors flex-shrink-0"
        >
          {isCapturing ? "Stop Recording" : "Start Recording"}
        </button>
      }
    />
  );
};

class NoteInputVM {
  noteChangeEvt = createEvent<string>();
  clearNoteEvt = createEvent();
  note = createStore<string>("")
    .on(this.noteChangeEvt, (_, note) => note)
    .reset(this.clearNoteEvt);
}

const NotesButton: React.FC = () => {
  const qavm = useQuickActionButtonPanelVM();
  const { baseSessionId } = useLiveRoomVM();
  const saveNoteMutation = useMutation(
    api.Sessions.Annotations.AnnotationFns.saveNote
  );
  const noteInputVm = useOnce(() => new NoteInputVM());

  const vm = useOnce(() => {
    const saveNote = async () => {
      const currentNote = noteInputVm.note.getState();
      console.log("saving note", currentNote);
      await saveNoteMutation({ baseSessionId, note: currentNote });
      noteInputVm.clearNoteEvt();
      qavm.openNotesMenuForMe().catch();
    };

    return new QuickActionButtonVM({
      onSave: saveNote,
    });
  });

  useEffect(() => {
    const unsub = vm.$state.watch((state) => {
      if (state.tag === "CLOSED") {
        noteInputVm.clearNoteEvt();
      }
    });
    return () => unsub();
  }, [vm, noteInputVm]);

  return (
    <QuickActionButton
      config={{
        id: "notes",
        icon: <NotesIconSvg />,
        label: "Notes",
      }}
      vm={vm}
      renderOpenIdle={() => <NoteInput vm={noteInputVm} />}
    />
  );
};

const NoteInput: React.FC<{ vm: NoteInputVM }> = ({ vm }) => {
  const [note] = useUnit([vm.note]);
  return (
    <div className="relative w-[200px] h-full">
      <textarea
        autoFocus
        className="absolute left-0 right-0 -top-8 -bottom-8 z-50 rounded-[12px] text-black p-2"
        value={note}
        onChange={(e) => vm.noteChangeEvt(e.target.value)}
      />
    </div>
  );
};

const QuickScheduleButton: React.FC<{}> = () => {
  const qavm = useQuickActionButtonPanelVM();
  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onSave: async () => {
          qavm.openQuickScheduleMenuForMe().catch();
        },
      })
  );

  return (
    <QuickActionButton
      vm={vm}
      actionButton={
        <button
          onClick={() => {
            vm.save();
          }}
          className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-colors flex-shrink-0"
        >
          Schedule
        </button>
      }
      config={{
        id: "schedule",
        icon: (
          <img
            src="/clipboard-tick.svg"
            alt="schedule"
            width={24}
            height={24}
          />
        ),
        label: "Schedule",
      }}
    />
  );
};

/* Icons */

interface NotesIconSvgProps {
  color?: string;
}

const NotesIconSvg: React.FC<NotesIconSvgProps> = ({ color = "white" }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M8 2.5V5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 2.5V5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11.5H15"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 15.5H12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 22.5H9C4 22.5 3 20.44 3 16.32V10.15C3 5.45 4.67 4.19 8 4H16C19.33 4.18 21 5.45 21 10.15V16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 16.5L15 22.5V19.5C15 17.5 16 16.5 18 16.5H21Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RecordSpanIcon: React.FC<{ isRecording: boolean }> = ({
  isRecording,
}) => {
  const src = isRecording
    ? "/bookendRecording.gif"
    : "/live-record-section-static-icon.png";

  return (
    <div className={`relative ${QUICK_ACTION.BUTTON_SIZE}`}>
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          src={src}
          className={`object-contain ${
            isRecording ? QUICK_ACTION.ICON_CONTAINER_SIZE : "w-[18px] h-[18px]"
          }`}
        />
      </div>
    </div>
  );
};
