import { AuthinForm } from "web-shared/src/domains/auth/authin.form";
import { InsightLiveLogoWithText } from "web-shared/src/icons/il-logo-with-text.fc";

export const AuthinPage: React.FC = () => {
  return (
    <div className="mt-8">
      <AuthinForm logoIcon={<InsightLiveLogoWithText />} />
    </div>
  );
};
