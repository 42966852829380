import { createEvent, createStore, sample, type StoreWritable } from "effector";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { api } from "shared/be/convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import type { LessonETO } from "shared/be/convex/Learning/Courses/Courses.Types";
import { useMemo } from "react";

interface Section {
  type: "VIDEO" | "TEXT" | "QUIZ" | "INTERACTIVE";
  content: {
    videoUrl?: string;
    markdownContent?: string;
    quizQuestions?: any[];
    interactiveConfig?: any;
  };
  orderIndex: number;
}

export function useSetupEditLessonVM(lessonId: Id<"courseLessons">) {
  const lesson = useQuery(api.Learning.Courses.LessonsFns.getLesson, {
    lessonId,
  });

  const updateLesson = useMutation(
    api.Learning.Courses.LessonsFns.updateLesson
  );

  const vm = useMemo(
    () =>
      lesson
        ? new EditLessonVM({
            lesson,
            updateLesson,
          })
        : undefined,
    [lesson]
  );
  return vm;
}

export class EditLessonVM {
  readonly $lesson: StoreWritable<LessonETO>;
  readonly $sections = createStore<Section[]>([]);

  readonly setTitleEvt = createEvent<string>();
  readonly addSectionEvt = createEvent<void>();
  readonly removeSectionEvt = createEvent<number>();
  readonly updateSectionTypeEvt = createEvent<{
    index: number;
    type: "VIDEO" | "TEXT" | "QUIZ" | "INTERACTIVE";
  }>();
  readonly updateSectionContentEvt = createEvent<{
    index: number;
    content: Partial<Section["content"]>;
  }>();
  readonly saveLessonEvt = createEvent<void>();

  // Events for internal state updates
  private readonly sectionsUpdatedEvt = createEvent<Section[]>();

  constructor({
    lesson,
    updateLesson,
  }: {
    lesson: LessonETO;
    updateLesson: any;
  }) {
    // Initialize stores with data from the API
    this.$lesson = createStore(lesson);
    this.$sections = createStore(lesson.sections || []);

    sample({
      clock: this.sectionsUpdatedEvt,
      target: this.$sections,
    });

    // Handle title updates
    sample({
      clock: this.setTitleEvt,
      source: this.$lesson,
      fn: (lesson, title) => {
        if (!lesson) return lesson;
        return { ...lesson, title };
      },
      target: this.$lesson,
    });

    // Handle section updates
    sample({
      clock: this.addSectionEvt,
      source: this.$sections,
      fn: (sections): Section[] => [
        ...sections,
        {
          type: "TEXT" as const,
          content: {},
          orderIndex: sections.length,
        },
      ],
      target: this.$sections,
    });

    sample({
      clock: this.removeSectionEvt,
      source: this.$sections,
      fn: (sections, index) => sections.filter((_, i) => i !== index),
      target: this.$sections,
    });

    sample({
      clock: this.updateSectionTypeEvt,
      source: this.$sections,
      fn: (sections, { index, type }) =>
        sections.map((section, i) =>
          i === index ? { ...section, type, content: {} } : section
        ),
      target: this.$sections,
    });

    sample({
      clock: this.updateSectionContentEvt,
      source: this.$sections,
      fn: (sections, { index, content }) =>
        sections.map((section, i) =>
          i === index
            ? { ...section, content: { ...section.content, ...content } }
            : section
        ),
      target: this.$sections,
    });

    // Handle save
    const updateLessonEvt = createEvent<Parameters<typeof updateLesson>[0]>();
    updateLessonEvt.watch(updateLesson);

    sample({
      clock: this.saveLessonEvt,
      source: { lesson: this.$lesson, sections: this.$sections },
      filter: Boolean,
      fn: ({ lesson, sections }) => ({
        lessonId: lesson!.id,
        title: lesson!.title,
        sections: sections.map((section, index) => ({
          ...section,
          orderIndex: index,
        })),
      }),
      target: updateLessonEvt,
    });
  }
}
