import { StreamVideoClient, type Call } from "@stream-io/video-react-sdk";
import { GenericRtcMgr } from "frontend-shared/src/mgrs/state-mgrs/rtc.statemgr";
import type { RtcCredentials } from "shared/schemas/call.schemas";

export class WebRtcMgr extends GenericRtcMgr<StreamVideoClient, Call> {
  constructor(readonly creds: RtcCredentials) {
    console.log("CREDENTIALS! ", creds);
    super(creds, (creds) => new StreamVideoClient(creds.apiKey));
  }
}
