import { FullContainerLoadingSpinner } from "@webapp/loading";
import {
  Authenticated,
  AuthLoading,
  Unauthenticated,
  useMutation,
  useQuery,
} from "convex/react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useTypedParams,
  useTypedSearchParams,
} from "react-router-typesafe-routes/dom";
import { api } from "shared/be/convex/_generated/api";
import type { Doc, Id } from "shared/be/convex/_generated/dataModel";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import { ROUTES } from "shared/routes/routes";
import { ONBOARD_ROUTES } from "src/route-views/onboard-route-views";

export const InvitePage: React.FC = () => {
  return (
    <div className="flex-1 w-full">
      <Authenticated>
        <InvitePageForAuthenticatedUser />
      </Authenticated>
      <AuthLoading>
        <FullContainerLoadingSpinner />
      </AuthLoading>
      <Unauthenticated>
        <UnauthenticatedInvitePage />
      </Unauthenticated>
    </div>
  );
};

const InviteError: React.FC<{ error: string }> = ({ error }) => {
  const nav = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      {error.includes("usage limit") ? (
        <>
          <p className="text-red-500 text-xl font-medium">
            This invite link has reached its maximum number of uses.
          </p>
          <button
            onClick={() => nav("/u")}
            className="px-4 py-2 text-white bg-vid-purple rounded-lg hover:bg-blue-700"
          >
            Return Home
          </button>
        </>
      ) : (
        <p className="text-red-500 text-xl font-medium">{error}</p>
      )}
    </div>
  );
};

export const UnauthenticatedInvitePage: React.FC = () => {
  const nav = useNavigate();
  const { inviteRes, email, error } = useAutoValidateOnMount();

  useEffect(() => {
    if (error?.includes("Authentication required")) {
      nav(ROUTES.AUTH.AUTHIN.path);
      return;
    }

    if (inviteRes !== undefined) {
      nav(ROUTES.AUTH.AUTHIN.path);
    }
  }, [inviteRes, email, error]);

  if (error) {
    return <InviteError error={error} />;
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <h4>You are being redirected...</h4>
    </div>
  );
};

export const InvitePageForAuthenticatedUser: React.FC = () => {
  const me = useQuery(api.User.UserFns.getAssumedSimpleMe);

  if (me === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <AuthedAndLoaded me={me} />;
};

const AuthedAndLoaded: React.FC<{ me: SimpleUserWithProfilePhoto }> = ({
  me,
}) => {
  const nav = useNavigate();
  const mountRes = useAutoValidateOnMount();

  useEffect(() => {
    if (mountRes.inviteRes !== undefined) {
      nav(mountRes.inviteRes.redirectPath);
    }
  }, [mountRes]);

  if (mountRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (mountRes.error) {
    return <InviteError error={mountRes.error} />;
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <h4>{`Welcome ${me.firstName}. You are being redirected...`}</h4>
    </div>
  );
};

function useAutoValidateOnMount() {
  const { inviteId } = useTypedParams(ROUTES.INVITES.INVITE_ID);
  const [{ email: urlEmail }] = useTypedSearchParams(ROUTES.INVITES.INVITE_ID);
  const validateInvite = useMutation(
    api.Invite.TrackInviteFns.validateAndTrackInvite
  );

  const invite = useQuery(api.Invite.TrackInviteFns.getInvite, {
    inviteId: inviteId as Id<"inviteLinks">,
  });

  const email = useMemo(() => {
    if (invite?.target.tag === "CLIENT_INVITE") {
      return invite.target.clientEmail;
    }
    return urlEmail;
  }, [invite, urlEmail]);

  const [inviteRes, setInviteRes] = useState<
    | {
        invite: Doc<"inviteLinks">;
        redemptionId: Id<"inviteRedemptions">;
        redirectPath: string;
      }
    | undefined
  >(undefined);

  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    let isActive = true;

    if (inviteId && !inviteRes && !error) {
      validateInvite({ inviteId: inviteId as Id<"inviteLinks">, email })
        .then(({ invite, redemptionId, redirectPath }) => {
          if (isActive) {
            setInviteRes({ invite, redemptionId, redirectPath });
          }
        })
        .catch((err) => {
          if (isActive) {
            setError(err.message);
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [inviteId, email, inviteRes, error]);

  return {
    inviteRes,
    email,
    error,
  };
}
