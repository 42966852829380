import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTypedHash } from "react-router-typesafe-routes/dom";
import { api } from "shared/be/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { useScrollToAnchor } from "../../../utils/utils.js";
import { SettingsContainer } from "./components/settings.components.js";

export const HpSettingsClientAndSchedulingDash: React.FC<{
  pathname: string;
  settingsClientAndSchedulingPath: string;
}> = ({ pathname, settingsClientAndSchedulingPath }) => {
  useScrollToAnchor();

  useEffect(() => {
    if (pathname === settingsClientAndSchedulingPath) {
      window.location.hash = "reminders";
    }
  }, [pathname]);

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex gap-8">
        <Sidebar />
        <div className="flex flex-col gap-4 w-[50%]">
          {/* <ClientIntakeFormsView /> */}
          <AppointmentRemindersView />
          <CalendarSyncingView />
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
      {/* <div>
        <SidebarTab tab="intake-forms" />
      </div> */}
      <div>
        <SidebarTab tab="reminders" />
      </div>
      <div>
        <SidebarTab tab="calendar" />
      </div>
    </div>
  );
};

type SideTabHashes = "intake-forms" | "reminders" | "calendar";

function titleForTab(tab: SideTabHashes): string {
  return Match.value(tab).pipe(
    Match.when("intake-forms", () => "Client Intake Forms"),
    Match.when("reminders", () => "Appointment Reminders"),
    Match.when("calendar", () => "Calendar Syncing"),
    Match.exhaustive
  );
}

const SidebarTab: React.FC<{ tab: SideTabHashes }> = ({ tab }) => {
  const curHash = useTypedHash(
    UROUTES.HP.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING
  );

  return (
    <Link
      to={`${UROUTES.HP.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.buildPath(
        {},
        undefined,
        tab
      )}`}
      className="flex items-center gap-2"
    >
      <div
        className={`rounded-lg pr-4 pl-4 py-2 font-sans ${
          curHash !== undefined && curHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </Link>
  );
};

const ClientIntakeFormsView: React.FC = () => {
  return (
    <SettingsContainer id="intake-forms" title="Client Intake Forms">
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Configure and manage your client intake forms here.
        </p>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p>Placeholder: Form builder and management interface will go here</p>
        </div>
      </div>
    </SettingsContainer>
  );
};

const AppointmentRemindersView: React.FC = () => {
  const settings = useQuery(api.User.Hp.HpFns.getAppointmentReminderSettings);
  const updateRecipients = useMutation(
    api.User.Hp.HpFns.updateAppointmentReminderRecipients
  ).withOptimisticUpdate((localState, args) => {
    const settings = localState.getQuery(
      api.User.Hp.HpFns.getAppointmentReminderSettings,
      {}
    );

    if (settings) {
      localState.setQuery(
        api.User.Hp.HpFns.getAppointmentReminderSettings,
        {},
        {
          ...settings,
          ...args,
        }
      );
    }
  });

  const updateTiming = useMutation(
    api.User.Hp.HpFns.updateAppointmentReminderTiming
  );

  if (!settings) return null;

  const handleRecipientChange = (
    key: "sendToClient" | "sendToHp",
    value: boolean
  ) => {
    updateRecipients({
      sendToClient: key === "sendToClient" ? value : settings.sendToClient,
      sendToHp: key === "sendToHp" ? value : settings.sendToHp,
    });
  };

  const handleTimeChange = (value: "15_MIN" | "30_MIN" | "1_HR") => {
    updateTiming({
      timeBeforeSession: value,
    });
  };

  return (
    <SettingsContainer id="reminders" title="Appointment reminders">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <h3 className="text-purple-600 font-medium">Send reminder to</h3>
          <div className="flex flex-col gap-2">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5 rounded-sm border-gray-300 text-purple-600 focus:ring-purple-500"
                checked={settings.sendToClient}
                onChange={(e) =>
                  handleRecipientChange("sendToClient", e.target.checked)
                }
              />
              <span>Client</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5 rounded-sm border-gray-300 text-purple-600 focus:ring-purple-500"
                checked={settings.sendToHp}
                onChange={(e) =>
                  handleRecipientChange("sendToHp", e.target.checked)
                }
              />
              <span>Me</span>
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h3 className="text-purple-600 font-medium">Time before session</h3>
          <div className="flex flex-col gap-2">
            {[
              { value: "15_MIN", label: "15 min" },
              { value: "30_MIN", label: "30 min" },
              { value: "1_HR", label: "1 hr" },
            ].map(({ value, label }) => (
              <label key={value} className="flex items-center gap-2">
                <input
                  type="radio"
                  className="w-5 h-5 border-gray-300 text-purple-600 focus:ring-purple-500"
                  checked={settings.timeBeforeSession === value}
                  onChange={() =>
                    handleTimeChange(value as "15_MIN" | "30_MIN" | "1_HR")
                  }
                />
                <span>{label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </SettingsContainer>
  );
};

const CalendarSyncingView: React.FC = () => {
  return (
    <SettingsContainer id="calendar" title="Calendar Syncing">
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Connect and manage your external calendar integrations.
        </p>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p>Placeholder: Calendar integration settings will go here</p>
        </div>
      </div>
    </SettingsContainer>
  );
};
