import { Match } from "effect";
import { EyeOffIcon, ScreenShareIcon } from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import type { SettingsMenuViewState } from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import { ButtonWithIcon } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { FormRadioCard } from "web-shared/src/components/ui/radio-group";
import type { Rx } from "web-shared/src/prelude";
import { useLiveRoomStreamVM } from "web-shared/src/web-context";

export const SettingsMenu: React.FC<{
  viewState$: Rx.BehaviorSubject<SettingsMenuViewState | undefined>;
  setSettingsMenuViewState: (viewState: SettingsMenuViewState) => void;
}> = ({ viewState$, setSettingsMenuViewState }) => {
  const viewState = useObservableEagerState(viewState$);

  if (viewState === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return Match.value(viewState).pipe(
    Match.when({ tag: "FULL_MENU" }, () => (
      <div className="flex flex-col gap-4">
        <ButtonWithIcon
          content={{
            title: "Background filters",
            description: "Apply background filters like blurring",
            icon: <EyeOffIcon color="#3E3648" size={20} />,
          }}
          onClick={() => {
            setSettingsMenuViewState({
              tag: "SETTINGS_SELECTION",
              setting: "background-filter",
            });
          }}
        />
        <ButtonWithIcon
          content={{
            title: "Screenshare",
            description: "Share your screen with others",
            icon: <ScreenShareIcon color="#3E3648" size={20} />,
          }}
          onClick={() => {
            setSettingsMenuViewState({
              tag: "SETTINGS_SELECTION",
              setting: "screenshare",
            });
          }}
        />
      </div>
    )),
    Match.when({ tag: "SETTINGS_SELECTION" }, ({ setting }) => {
      return Match.value(setting).pipe(
        Match.when("background-filter", () => <BackgroundFilterSettingsMenu />),
        Match.when("screenshare", () => <ScreenshareSettingsMenu />),
        Match.orElse(() => <div></div>)
      );
    }),
    Match.exhaustive
  );
};

const BackgroundFilterSettingsMenu: React.FC = () => {
  const liveRoomStreamVM = useLiveRoomStreamVM();

  const isBlurred = useObservableEagerState(
    liveRoomStreamVM.isBackgroundBlurred$
  );

  return (
    <div className="flex flex-col gap-4">
      <FormRadioCard
        title="Background filter"
        radioProps={{
          options: [
            { name: "Blur", value: "blur" },
            { name: "None", value: "none" },
          ],
          value: isBlurred ? "blur" : "none",
          onChange: (value) => {
            console.log(value);
            liveRoomStreamVM.setIsBackgroundBlurred(value === "blur");
          },
        }}
      />
    </div>
  );
};

const ScreenshareSettingsMenu: React.FC = () => {
  const liveRoomStreamVM = useLiveRoomStreamVM();
  const isScreenshareEnabled = useObservableEagerState(
    liveRoomStreamVM.isScreenshareEnabled$
  );

  return (
    <div className="flex flex-col gap-4">
      <FormRadioCard
        title="Screenshare"
        radioProps={{
          options: [
            { name: "Enabled", value: "enabled" },
            { name: "Disabled", value: "disabled" },
          ],
          value: isScreenshareEnabled ? "enabled" : "disabled",
          onChange: (value) => {
            liveRoomStreamVM.setIsScreenshareEnabled(value === "enabled");
          },
        }}
      />
    </div>
  );
};
