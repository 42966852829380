import { FullContainerLoadingSpinner, LoadingSpinner } from "@/src/loading";
import { useAction, useQuery } from "convex/react";
import { Match } from "effect";
import { useUnit } from "effector-react";
import { Upload } from "lucide-react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { InputProfileConfig } from "shared/be/convex/Onboard/Onboard.Types";
import { UROUTES } from "shared/routes/u.routes";
import { MyProfileForm } from "web-shared/src/domains/user/my-profile-form";
import { useWebSetupMyProfileForm } from "web-shared/src/web-context";

type ViewState = "ENTER_PSYCH_TODAY_URL" | "MANUAL_INPUT";

export const ImportProfilePage: React.FC = () => {
  const [viewState, setViewState] = useState<ViewState>("MANUAL_INPUT");
  const formConfig = useQuery(api.Onboard.OnboardFns.getInputProfileConfig);

  if (formConfig === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  const content = Match.value(viewState).pipe(
    Match.when("MANUAL_INPUT", () => {
      return (
        <ManualInputProfileData
          formConfig={formConfig}
          onClickPsychTodayUrlInput={() => {
            setViewState("ENTER_PSYCH_TODAY_URL");
          }}
        />
      );
    }),
    Match.when("ENTER_PSYCH_TODAY_URL", () => {
      return (
        <EnterPsychTodayUrlForm
          onDoneSubmitting={() => {
            setViewState("MANUAL_INPUT");
          }}
        />
      );
    }),
    Match.exhaustive
  );

  return (
    <div className="flex-1 flex flex-col gap-6 justify-center items-center bg-vid-purple">
      <div className="w-[600px] p-12 rounded-[24px] flex flex-col bg-white">
        {content}
      </div>
    </div>
  );
};

const ManualInputProfileData: React.FC<{
  formConfig: InputProfileConfig;
  onClickPsychTodayUrlInput: () => void;
}> = ({ formConfig, onClickPsychTodayUrlInput }) => {
  const myProfileFormVM = useWebSetupMyProfileForm({
    formConfig,
  });
  const [shouldShowPsychTodayInput, setShouldShowPsychTodayInput] = useState(
    formConfig.showPsychTodayUrlInput
  );
  const [isSubmitting] = useUnit([myProfileFormVM.$isSubmitting]);

  useEffect(() => {
    if (isSubmitting) {
      setShouldShowPsychTodayInput(false);
    }
  }, [isSubmitting]);

  if (isSubmitting) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col gap-6 justify-center items-center">
      <MyProfileForm
        onSuccessSubmit={() => {
          window.location.href = UROUTES.HP.MY.DASHBOARD.CLIENTS.path;
        }}
        button={{ title: "Next" }}
        vm={myProfileFormVM}
      />
      {shouldShowPsychTodayInput && (
        <div className="flex flex-col gap-6 justify-center items-center">
          <Break />

          <ImportFromPsychTodayButton onClick={onClickPsychTodayUrlInput} />
        </div>
      )}
    </div>
  );
};

const EnterPsychTodayUrlForm: React.FC<{
  onDoneSubmitting: () => void;
}> = ({ onDoneSubmitting }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [psychTodayUrl, setPsychTodayUrl] = useState("");

  const onSubmitUrl = useAction(
    api.Onboard.OnboardFns.onSubmitUrlForProfileImport
  );

  if (isSubmitting) {
    return (
      <div className="flex-1 flex flex-col gap-6 justify-center items-center">
        <div className="flex flex-col items-center gap-2 text-center">
          <div className="text-vid-purple text-[24px] font-medium">
            Importing profile from Psychology Today...
          </div>
          <div className="text-gray-600 text-sm">
            This process may take a couple of minutes. Please don't close this
            window.
          </div>
        </div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col gap-6 justify-center items-center">
      <div className="text-vid-purple text-[24px] font-medium">
        Enter your Psychology Today URL
      </div>
      <input
        className="w-full border border-gray-300 rounded-buttons p-2"
        placeholder="e.g. https://www.psychologytoday.com/us/therapists/1234567890"
        value={psychTodayUrl}
        onChange={(e) => {
          setPsychTodayUrl(e.target.value);
        }}
      />
      <ImportFromPsychTodayButton
        onClick={() => {
          console.log("import from psych today");

          if (psychTodayUrl.length === 0) {
            return;
          }

          setIsSubmitting(true);
          onSubmitUrl({ url: psychTodayUrl }).then(() => {
            setIsSubmitting(false);
            onDoneSubmitting();
          });
        }}
      />
    </div>
  );
};

const ImportFromPsychTodayButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <button
      className="flex items-center gap-2 px-6 py-3 text-sm font-medium text-white bg-community-blue hover:bg-[#3EBDD1] rounded-buttons border border-[#53CEDF]/20 transition-colors duration-200 shadow-sm"
      onClick={onClick}
    >
      <Upload className="w-4 h-4" />
      Import from Psychology Today
    </button>
  );
};

const Break: React.FC<{}> = ({}) => {
  return (
    <div className="flex items-center w-full gap-3">
      <div className="h-px bg-gray-300 flex-1" />
      <div className="text-sm text-gray-500">
        {`Or import from psychology today`}
      </div>
      <div className="h-px bg-gray-300 flex-1" />
    </div>
  );
};
