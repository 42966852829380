interface Column<T> {
  key: keyof T | string;
  header: string;
  render?: (item: T) => React.ReactNode;
  sortable?: boolean;
  align?: "left" | "right" | "center";
}

interface DataTableProps<T> {
  columns: Column<T>[];
  data: T[];
  onSort?: (key: keyof T) => void;
  sortKey?: keyof T;
  sortDirection?: "asc" | "desc";
}

export const DataTable = <T extends object>({
  columns,
  data,
  onSort,
  sortKey,
  sortDirection,
}: DataTableProps<T>) => {
  return (
    <div className="w-full border border-vid-black-200 rounded-[12px] overflow-hidden">
      {/* Header */}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] bg-vid-black-100 px-9 py-5">
        {columns.map((column) => (
          <div
            key={column.key.toString()}
            className={`
                font-semibold text-sm text-vid-black-900
                ${column.align === "right" ? "text-right" : ""}
                ${column.align === "center" ? "text-center" : ""}
                ${column.sortable ? "cursor-pointer" : ""}
              `}
            onClick={() => column.sortable && onSort?.(column.key as keyof T)}
          >
            <div className="flex items-center gap-2">
              {column.header}
              {column.sortable && sortKey === column.key && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Rows */}
      <div className="divide-y divide-vid-black-200">
        {data.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] px-9 py-7 items-center"
          >
            {columns.map((column) => (
              <div
                key={column.key.toString()}
                className={`
                    ${column.align === "right" ? "text-right" : ""}
                    ${column.align === "center" ? "text-center" : ""}
                  `}
              >
                {column.render
                  ? column.render(item)
                  : String(item[column.key as keyof T])}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

// ... existing interfaces and DataTable component ...

export interface SimpleColumn<T> {
  key: keyof T | string;
  header: string;
  render?: (item: T) => React.ReactNode;
  align?: "left" | "right" | "center";
}

export interface SimpleTableInfo<T extends object> {
  columns: SimpleColumn<T>[];
  data: T[];
}

export interface TableAction<T> {
  icon?: React.ReactNode;
  onClick: (item: T) => void;
}

export interface SimpleTableProps<T extends object> extends SimpleTableInfo<T> {
  roundedTop?: boolean;
  hasTableBelow?: boolean;
  action?: TableAction<T>;
  emptyState?: React.ReactNode;
}

interface SimpleTableContainerProps<T extends object> {
  columns: SimpleColumn<T>[];
  children: React.ReactNode;
  roundedTop?: boolean;
  hasTableBelow?: boolean;
  hasAction?: boolean;
  emptyState?: React.ReactNode;
}

export const SimpleTableContainer = <T extends object>({
  columns,
  children,
  roundedTop,
  hasTableBelow,
  hasAction,
  emptyState,
}: SimpleTableContainerProps<T>) => {
  return (
    <div
      className={`
        w-full
        ${
          hasTableBelow
            ? "border-x border-t border-vid-black-200"
            : "border-x border-t border-b border-vid-black-200"
        } 
        ${roundedTop ? "rounded-tl-[12px] rounded-tr-[12px]" : ""} 
        overflow-hidden
      `}
    >
      <div
        className={`
        grid ${
          hasAction
            ? `grid-cols-[1fr_40px]`
            : "grid-cols-[repeat(auto-fit,minmax(0,1fr))]"
        } bg-vid-black-100 px-9 py-5
      `}
      >
        <div className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
          {columns.map((column) => (
            <div
              key={column.key.toString()}
              className={`
                  font-semibold text-sm text-vid-black-900
                  ${column.align === "right" ? "text-right" : ""}
                  ${column.align === "center" ? "text-center" : ""}
                `}
            >
              {column.header}
            </div>
          ))}
        </div>
        {hasAction && <div className="w-[20px]" />}
      </div>

      {emptyState ? emptyState : children}
    </div>
  );
};

export const SimpleTable = <T extends object>({
  columns,
  data,
  roundedTop,
  hasTableBelow,
  action,
  emptyState,
}: SimpleTableProps<T>) => {
  return (
    <SimpleTableContainer
      columns={columns}
      roundedTop={roundedTop}
      hasTableBelow={hasTableBelow}
      hasAction={!!action}
      emptyState={data.length === 0 ? emptyState : undefined}
    >
      <div className="divide-y divide-vid-black-200">
        {data.map((item, index) => (
          <div
            key={index}
            className={`
              grid ${
                action
                  ? `grid-cols-[1fr_40px]`
                  : "grid-cols-[repeat(auto-fit,minmax(0,1fr))]"
              } px-9 items-center
              ${action ? "pr-[40px]" : ""} py-7
            `}
          >
            <div className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
              {columns.map((column) => (
                <div
                  key={column.key.toString()}
                  className={`
                      ${column.align === "right" ? "text-right" : ""}
                      ${column.align === "center" ? "text-center" : ""}
                    `}
                >
                  {column.render
                    ? column.render(item)
                    : String(item[column.key as keyof T])}
                </div>
              ))}
            </div>
            {action && (
              <div
                className="cursor-pointer flex justify-center items-center w-[60px] aspect-square"
                onClick={() => action.onClick(item)}
              >
                {action.icon || "→"}
              </div>
            )}
          </div>
        ))}
      </div>
    </SimpleTableContainer>
  );
};

interface SimpleTableListProps {
  tables: (SimpleTableInfo<any> & { action?: TableAction<any> })[];
}

export const SimpleTableList = ({ tables }: SimpleTableListProps) => {
  const nonEmptyTables = tables.filter((table) => table.data.length > 0);

  return (
    <div className="space-y-0">
      {nonEmptyTables.map((table, index) => (
        <SimpleTable
          key={index}
          roundedTop={index === 0}
          hasTableBelow={index < nonEmptyTables.length - 1}
          columns={table.columns}
          data={table.data}
          action={table.action}
        />
      ))}
    </div>
  );
};
