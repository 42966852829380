import { FullContainerLoadingSpinner } from "@/src/loading";
import { useConvexAuth } from "convex/react";
import { useEffect } from "react";

export const AuthinSuccessPage: React.FC = () => {
  const { isLoading, isAuthenticated } = useConvexAuth();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      window.location.href = "/";
    }
  }, []);

  return <FullContainerLoadingSpinner />;
};
