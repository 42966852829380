import { Match } from "effect";
import {
  useOnOpenMeditationActivityForm as useOnOpenMeditationPanel,
  useOnSetupMeditationActivityForm,
} from "frontend-shared/src/sessions/rooms/activity/meditation-activity.vm";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { KnownVisualizationMediaFile } from "shared/be/convex/Sessions/Rooms/Live/LiveRoom.Types";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { FormRadioCard } from "web-shared/src/components/ui/radio-group";

export const MeditationPanel: React.FC<{
  sessionId: Id<"sessionConfig">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ sessionId, roomId }) => {
  const activityId = useOnOpenMeditationPanel({
    baseSessionId: sessionId,
    roomId,
  });

  if (activityId === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-2">
      <MeditationSettingsForm timedActivityId={activityId} />
    </div>
  );
};

const MeditationSettingsForm: React.FC<{
  timedActivityId: Id<"rtcRoomActivity">;
}> = ({ timedActivityId }) => {
  const setup = useOnSetupMeditationActivityForm({
    activityId: timedActivityId,
  });

  if (setup === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  const {
    timerMinutesSetting,
    setTimerMinutesSetting,
    visualizationMediaFile,
  } = setup;

  if (
    timerMinutesSetting === undefined ||
    visualizationMediaFile === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  const timerSecondsSetting = timerMinutesSetting
    ? timerMinutesSetting * 60
    : null;

  const PlayButton = () => {
    return (
      <PrimaryButton title="Play" onClick={setup.onPlayMeditationActivity} />
    );
  };

  const PauseButton = () => {
    return (
      <InversePrimaryButton
        title="Pause"
        onClick={setup.onPauseMeditationActivity}
      />
    );
  };

  const StopButton = () => {
    return (
      <InversePrimaryButton
        title="Stop"
        onClick={setup.onStopMeditationActivity}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <FormRadioCard
        radioProps={{
          options: [
            { name: "1 min", value: 60 },
            { name: "5 min", value: 300 },
            { name: "10 min", value: 600 },
            { name: "20 min", value: 1200 },
            // { name: "Custom", value: "Custom" },
          ],
          value: timerSecondsSetting,
          onChange: (v) => {
            const value = v as number | null;
            const minutes = value ? Math.floor(value / 60) : null;
            setTimerMinutesSetting(minutes);
          },
        }}
        title="Timer"
      />
      <VisualOptionsRadioGroup
        curVisualization={visualizationMediaFile}
        setVisualization={setup.setMeditationVisualizationMediaFile}
      />
      <div className="flex flex-col gap-2">
        {Match.value(setup.playState).pipe(
          Match.when("playing", () => (
            <div className="flex flex-row gap-2">
              <PauseButton />
              <StopButton />
            </div>
          )),
          Match.when("paused", () => <PlayButton />),
          Match.when("stopped", () => <StopButton />),
          Match.orElse(() => <PlayButton />)
        )}
      </div>
    </div>
  );
};

const VisualOptionsRadioGroup: React.FC<{
  curVisualization: KnownVisualizationMediaFile | null;
  setVisualization: (
    visualizationMediaFile: KnownVisualizationMediaFile | null
  ) => void;
}> = ({ curVisualization, setVisualization }) => {
  const visualOptions: {
    name: string;
    value: KnownVisualizationMediaFile | null;
  }[] = [
    { name: "None", value: null },
    { name: "Candle", value: "candle.mp4" },
    { name: "Birdseye", value: "birdseye-waves.mp4" },
    { name: "Stream", value: "stream.mp4" },
  ];

  if (curVisualization === undefined) {
    return <div></div>;
  }

  return (
    <FormRadioCard
      radioProps={{
        options: visualOptions,
        value: curVisualization as KnownVisualizationMediaFile | null,
        onChange: (s) => {
          setVisualization(s as KnownVisualizationMediaFile);
        },
      }}
      title="Visual Options"
    />
  );
};
