import { CommunityCardContainer } from "@/src/domains/communities/community/components/community-card";
import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { Match } from "effect";
import { useOnce } from "frontend-shared/src/util";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { UpcomingCommunityCalEventInstanceCard } from "shared/be/convex/Screens/Community/CommunityEventsScreen.Types";
import { UROUTES } from "shared/routes/u.routes";
import { AvatarCircles } from "web-shared/src/components/avatar.tc";
import { useUMgr, useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { CommunityEventDetailsPanel } from "./view-community-event-details";

export const CommunityEventCardContainer: React.FC<{
  initialCardData: typeof UpcomingCommunityCalEventInstanceCard.Encoded;
  showCommunityName?: boolean;
}> = ({ initialCardData, showCommunityName = false }) => {
  const uMgr = useUMgr();
  const webDisplayVM = useWebGlobalDisplayVM();
  const now = useOnce(() => Date.now());
  const nav = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitCta = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.handleCtaSubmit
  );

  const onLivePlayButtonClick = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.onLivePlayButtonClick
  );

  const fullCardData = useQuery(
    api.Screens.Community.CommunityEventsScreenFns.getCommunityCardData,
    {
      instanceId: initialCardData.instance._id as Id<"calEventInstances">,
      now,
    }
  );

  const cardData = fullCardData ?? initialCardData;
  const isLoading = fullCardData === undefined || cardData.isLoading;

  const openDetailsPanel = () => {
    webDisplayVM.globalSheetVM.openSheet(
      {
        title: "Edit",
        type: { tag: "edit" },
      },
      <CommunityEventDetailsPanel
        instanceId={cardData.instance._id as Id<"calEventInstances">}
        communitySlug={cardData.communitySlug}
        closePanel={(mbMessage) => {
          if (mbMessage) {
            webDisplayVM.globalSheetVM.closeSheet();
            uMgr.toastVM.showToastEvt({
              id: "community-event-card-toast",
              title: mbMessage.message,
              duration: 5000,
              type: "success",
            });
          }
        }}
      />
    );
  };

  return (
    <CommunityCardContainer
      key={cardData.instance._id}
      //   title={cardData.title}
      isLoading={isLoading}
      height="medium"
      headerBackgroundType={{
        _tag: "CUSTOM",
        color: "purple",
        hideCircleThings: true,
        content: (
          <div className="w-full h-full relative flex flex-col ">
            <div className="absolute inset-0 flex flex-col">
              {/* <PurpleSvgHeaderBg /> */}
            </div>
            {cardData.isLive && (
              <div
                className="flex-1 flex flex-col p-3 cursor-pointer"
                onClick={() => {
                  onLivePlayButtonClick({
                    instanceId: cardData.instance
                      ._id as Id<"calEventInstances">,
                  }).then(({ baseSessionId }) => {
                    nav(
                      UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                        sessionId: baseSessionId,
                      })
                    );
                  });
                }}
              >
                <h4 className="self-start py-[6px] px-3 rounded-[12px] text-white bg-black backdrop-blur-[0.95px] font-roboto-flex font-semibold">
                  Live
                </h4>
                <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <div className="">
                      <img src={"/Play.svg"} className="w-12 h-12" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ),
      }}
      onClick={() => {
        // openDetailsPanel();
      }}
      content={
        <div
          className="flex flex-col cursor-pointer gap-1"
          onClick={() => {
            openDetailsPanel();
          }}
        >
          <div className="font-semibold line-clamp-2">
            {showCommunityName
              ? `${cardData.title} | ${cardData.communitySlug}`
              : cardData.title}
          </div>
          <h4 className="">{cardData.occurenceMessage}</h4>
          <div className="text-md text-vid-black-500">
            {format(cardData.instance.startAt, "MMM d, yyyy h:mm a")}
          </div>
          <div className="flex flex-col gap-1 items-center mt-2">
            <AvatarCircles
              sources={cardData.participants.map((m) => m.profilePhoto)}
              size={44}
            />
          </div>
        </div>
      }
      button={
        cardData.button
          ? {
              ...cardData.button,
              isLoading: isSubmitting,
              onClick: () => {
                if (cardData.button?.cta) {
                  setIsSubmitting(true);
                  onSubmitCta({
                    instanceId: cardData.instance
                      ._id as Id<"calEventInstances">,
                    templateId:
                      cardData.templateId as Id<"calendarEventTemplates">,
                    cta: cardData.button.cta,
                  }).then((r) => {
                    setIsSubmitting(false);
                    const nextAction: () => void = Match.value(r).pipe(
                      Match.when(
                        { nextAction: "GO_TO_SESSION" },
                        ({ baseSessionId }) => {
                          return () =>
                            nav(
                              UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                                sessionId: baseSessionId,
                              })
                            );
                        }
                      ),
                      Match.when({ nextAction: "CLOSE_AND_REFRESH" }, () => {
                        return () => {};
                      }),
                      Match.when({ nextAction: "DO_NOTHING" }, () => {
                        return () => {};
                      }),
                      Match.exhaustive
                    );

                    nextAction();
                  });
                }
              },
            }
          : null
      }
    />
  );
};
