import * as S from "@effect/schema/Schema";
import { differenceInSeconds, format } from "date-fns";
import { Array, Match, Option, Order, Record } from "effect";
import { ADJACENT_BEFORE_SECONDS_THRESHOLD } from "shared/constants";
import { epipe } from "../../../base-prelude";
import SampleHakomi1Mock from "../../../be/convex/Mocks/Sessions/SampleHakomi1.Mock.json";
import type { MomentTemporalType } from "../../../be/convex/Sessions/Annotations/Annotation.Types";

export class CallTranscriptSegment extends S.Class<CallTranscriptSegment>(
  "CallTranscriptSegment"
)({
  startTime: S.Date,
  endTime: S.Date,
  speakerId: S.String,
  text: S.String,
}) {
  mbCombineAdjacentLaterSegment = (p: {
    laterSegment: CallTranscriptSegment;
  }): Option.Option<CallTranscriptSegment> => {
    const { laterSegment } = p;
    const earlierSegment = this;
    const isSameSpeaker = earlierSegment.speakerId === laterSegment.speakerId;
    const isBeforeAdjacentThreshold =
      differenceInSeconds(laterSegment.startTime, earlierSegment.endTime) <
      ADJACENT_BEFORE_SECONDS_THRESHOLD;

    if (isSameSpeaker && isBeforeAdjacentThreshold) {
      return Option.some({
        ...earlierSegment,
        endTime: laterSegment.endTime,
        text: `${earlierSegment.text} ${laterSegment.text}`,
      });
    }
    return Option.none();
  };
}

export class CallTranscript extends S.Class<CallTranscript>("CallTranscript")({
  segments: S.Array(CallTranscriptSegment),
}) {
  get isEmpty() {
    return this.segments.length === 0;
  }

  get asString() {
    return this.segments
      .map(
        (segment) =>
          `Start: ${segment.startTime.toISOString()}, End: ${segment.endTime.toISOString()}, Speaker: ${
            segment.speakerId
          }\nText: ${segment.text}`
      )
      .join("\n\n");
  }

  startAndEndDates = (): { minStartTime: Date; maxEndTime: Date } => {
    const [first, ...rest] = this.segments;
    const allStartTimes = [
      first.startTime,
      ...rest.map((s) => s.startTime),
    ] as [Date, ...Date[]];
    const allEndTimes = [first.endTime, ...rest.map((s) => s.endTime)] as [
      Date,
      ...Date[],
    ];
    return {
      minStartTime: Array.min(allStartTimes, Order.Date),
      maxEndTime: Array.max(allEndTimes, Order.Date),
    };
  };

  static bySegmentStartTime = Order.mapInput(
    Order.Date,
    (s: CallTranscriptSegment) => s.startTime
  );

  sortSelf = () =>
    epipe(
      this.segments,
      Array.sortBy(CallTranscript.bySegmentStartTime),
      (ss) => CallTranscript.make({ segments: ss }, { disableValidation: true })
    );

  asDbJsonValue = (p: { speakerIdMap: TranscriptSpeakerIdMap }) =>
    JSON.stringify(
      S.encodeSync(SavedTranscript)({
        speakerIdMap: p.speakerIdMap,
        segments: this.segments,
      })
    );

  withMappedIdentifiedSpeakers = (p: {
    identifiedSpeakers: TranscriptSpeakerIdMap;
  }): CallTranscript => {
    return CallTranscript.make(
      {
        segments: this.segments.map((segment) => {
          const mbMatchingName = Record.get(segment.speakerId)(
            p.identifiedSpeakers
          );

          return Option.match(mbMatchingName, {
            onNone: () => segment,
            onSome: (name) => ({
              ...segment,
              speakerId: name,
            }),
          });
        }),
      },
      { disableValidation: true }
    );
  };

  /* MERGING */

  static recursivelyMerge = (
    unmerged: CallTranscript,
    merged: CallTranscript = CallTranscript.asEmpty()
  ): CallTranscript => {
    const sortedUnmergedSegments = unmerged.sortSelf().segments;
    const sortedMergedSegments = merged.sortSelf().segments;

    const mbFirstUnmergedSegment = Array.head(sortedUnmergedSegments);

    if (Option.isNone(mbFirstUnmergedSegment)) {
      return merged;
    }

    const unmergedWithoutHead = CallTranscript.fromSegments(
      sortedUnmergedSegments.slice(1, sortedUnmergedSegments.length)
    );
    const mergedWithoutLast = CallTranscript.make(
      {
        segments: sortedMergedSegments.slice(
          0,
          sortedMergedSegments.length - 1
        ),
      },
      { disableValidation: true }
    );
    const mbLastMergedSegment = Array.last(sortedMergedSegments);
    if (Option.isNone(mbLastMergedSegment)) {
      return this.recursivelyMerge(
        unmergedWithoutHead,
        CallTranscript.fromSegments([mbFirstUnmergedSegment.value])
      );
    }

    const mbCombinedSegment =
      mbLastMergedSegment.value.mbCombineAdjacentLaterSegment({
        laterSegment: mbFirstUnmergedSegment.value,
      });

    const newMergedSegments = Option.match(mbCombinedSegment, {
      onNone: () => [...sortedMergedSegments, mbFirstUnmergedSegment.value],
      onSome: (combinedSegment) => [
        ...mergedWithoutLast.segments,
        combinedSegment,
      ],
    });

    return this.recursivelyMerge(
      unmergedWithoutHead,
      CallTranscript.fromSegments(newMergedSegments)
    );
  };

  public mergeSelf = (): CallTranscript => {
    return CallTranscript.recursivelyMerge(this);
  };

  combineAndSort = (transcript: CallTranscript): CallTranscript => {
    const rawCombinedSegments = [...this.segments, ...transcript.segments];
    const sortedCombinedSegments = epipe(
      rawCombinedSegments,
      Array.sortBy(CallTranscript.bySegmentStartTime),
      (ss) => CallTranscript.fromSegments(ss)
    );

    return sortedCombinedSegments.mergeSelf();
  };

  static combineAndSortAll = (
    transcripts: CallTranscript[]
  ): CallTranscript => {
    return epipe(
      transcripts,
      Array.reduce(CallTranscript.asEmpty(), (acc, t) => acc.combineAndSort(t))
    );
  };

  static asEmpty = () =>
    CallTranscript.make({ segments: [] }, { disableValidation: true });

  static fromSegments = (segments: CallTranscriptSegment[]) =>
    CallTranscript.make({ segments }, { disableValidation: true });

  static fromString = (str: string): CallTranscript => {
    const asJson = S.decodeSync(S.parseJson())(str);
    const segments = S.decodeUnknownSync(S.Array(CallTranscriptSegment))(
      asJson
    );
    return CallTranscript.make({ segments }, { disableValidation: true });
  };

  asDisplayableTranscript = (p: {
    identifiedSpeakers: TranscriptSpeakerIdMap;
    callStartTime: Date;
  }): DisplayableTranscript => {
    return this.segments.map((segment) => ({
      start: differenceInSeconds(segment.startTime, p.callStartTime),
      end: differenceInSeconds(segment.endTime, p.callStartTime),
      speaker: segment.speakerId,
      text: segment.text,
    }));
  };
}

export type DisplayableTranscript = {
  start: number;
  end: number;
  speaker: string;
  text: string;
}[];

const TranscriptSpeakerIdMapSchema = S.Record({
  key: S.String,
  value: S.String,
});
export type TranscriptSpeakerIdMap = typeof TranscriptSpeakerIdMapSchema.Type;

export class SavedTranscript extends S.Class<SavedTranscript>(
  "SavedTranscript"
)({
  speakerIdMap: S.Record({
    key: S.String,
    value: S.String,
  }),
  segments: S.Array(CallTranscriptSegment),
}) {
  static fromString(data: string): SavedTranscript {
    const asJson = S.decodeSync(S.parseJson())(data);
    return S.decodeUnknownSync(SavedTranscript)(asJson);
  }
}

export class DisplayableTranscriptSegment extends S.Class<DisplayableTranscriptSegment>(
  "DisplayableTranscriptSegment"
)({
  speaker: S.String,
  text: S.String,
  start: S.Number, // as seconds into session
  end: S.Number, // as seconds into session
  matchingMoment: S.optional(
    S.Struct({
      temporalType: S.Union(S.Literal("BOOKMARK"), S.Literal("SPAN")),
      label: S.String,
      color: S.String,
    })
  ),
}) {}

const TranscriptSpeakerInfo = S.Struct({
  name: S.String,
  profilePhotoUrl: S.NullOr(S.String),
});

const TranscriptSpeakerInfoMap = S.Record({
  key: S.String,
  value: TranscriptSpeakerInfo,
});
export type TranscriptSpeakerInfoMap = typeof TranscriptSpeakerInfoMap.Type;

export class DisplayableTranscriptST extends S.Class<DisplayableTranscriptST>(
  "DisplayableTranscriptST"
)({
  speakerInfoMap: TranscriptSpeakerInfoMap,
  segments: S.Array(DisplayableTranscriptSegment),
  highlightedSegmentsBySis: S.optional(S.Array(S.Number)),
}) {
  static from(p: {
    segments: (typeof DisplayableTranscriptSegment.Encoded)[];
    speakerInfoMap: TranscriptSpeakerInfoMap;
    timeFilter?: { secondsIntoSession: number };
  }): DisplayableTranscriptST {
    const decodedSegments = S.decodeUnknownSync(
      S.Array(DisplayableTranscriptSegment)
    )(p.segments);

    let highlightedSegmentsBySis: readonly number[] | undefined;

    console.log("p.timeFilter!!!", p.timeFilter);

    if (p.timeFilter?.secondsIntoSession !== undefined) {
      const closestSegments = decodedSegments.reduce((acc, current) => {
        const currentDistance = Math.abs(
          current.start - p.timeFilter!.secondsIntoSession
        );
        const closestDistance =
          acc.length > 0
            ? Math.abs(acc[0].start - p.timeFilter!.secondsIntoSession)
            : Infinity;

        if (currentDistance < closestDistance) {
          return [current]; // New closest segment
        } else if (currentDistance === closestDistance) {
          return [...acc, current]; // Add to list of equally close segments
        }
        return acc;
      }, [] as DisplayableTranscriptSegment[]);

      if (closestSegments.length > 0) {
        highlightedSegmentsBySis = closestSegments.map(
          (segment) => segment.start
        );
      }
    }

    return DisplayableTranscriptST.make(
      {
        segments: decodedSegments,
        speakerInfoMap: p.speakerInfoMap,
        highlightedSegmentsBySis,
      },
      { disableValidation: true }
    );
  }

  static fromEncoded(dte: typeof DisplayableTranscriptST.Encoded) {
    return S.decodeUnknownSync(DisplayableTranscriptST)(dte);
  }

  static default = () =>
    DisplayableTranscriptST.make(
      { segments: [], speakerInfoMap: {} },
      { disableValidation: true }
    );

  get encoded() {
    return S.encodeUnknownSync(DisplayableTranscriptST)(this);
  }

  getAssumedSpeakerInfo(p: { speaker: string }) {
    return epipe(this.speakerInfoMap, Record.get(p.speaker), Option.getOrThrow);
  }

  getMetaTitle(p: { speakerName: string; start: number; end: number }) {
    // The speaker name followed by the time range of the segment. Such as "John Doe (00:33-01:00)"
    return `${p.speakerName} (${format(p.start * 1000, "mm:ss")}-${format(p.end * 1000, "mm:ss")})`;
  }

  get asStandardDisplaySegments(): {
    start: number;
    header: {
      profilePhotoUrl: string | null;
      speakerName: string;
      sisStr: string;
      metaTitle: string;
    };
    body: {
      transcriptText: string;
    };
    matchingMoment:
      | {
          temporalType: MomentTemporalType;
          label: string;
          color: string;
        }
      | undefined;
  }[] {
    return this.segments
      .map((s) => {
        const speakerInfo = this.getAssumedSpeakerInfo({ speaker: s.speaker });
        const metaTitle = this.getMetaTitle({
          speakerName: speakerInfo?.name ?? s.speaker,
          start: s.start,
          end: s.end,
        });
        return {
          start: s.start,
          header: {
            metaTitle,
            profilePhotoUrl: speakerInfo?.profilePhotoUrl ?? null,
            speakerName: speakerInfo?.name ?? s.speaker,
            sisStr: format(s.start * 1000, "mm:ss"),
          },
          body: {
            transcriptText: s.text,
          },
          matchingMoment: s.matchingMoment,
        };
      })
      .sort((a, b) => a.start - b.start);
  }

  static fromMock = (mock: KnownMockTranscripts) => {
    const segments = matchKnownMock(mock);
    return DisplayableTranscriptST.from({
      segments,
      speakerInfoMap: {
        "1": {
          name: "Bob McTherapist",
          profilePhotoUrl: null,
        },
        "2": {
          name: "Alice McClient",
          profilePhotoUrl: null,
        },
      },
    });
  };

  getTimeWindowedTranscript(p: {
    targetSis: number;
    windowSize: number;
  }): DisplayableTranscriptST {
    const { targetSis, windowSize } = p;
    const windowStart = targetSis - windowSize;
    const windowEnd = targetSis + windowSize;

    const filteredSegments = this.segments
      .filter(
        (segment) => segment.start >= windowStart && segment.end <= windowEnd
      )
      // Ensure target segment is in the middle of the window
      .sort(
        (a, b) => Math.abs(a.start - targetSis) - Math.abs(b.start - targetSis)
      );

    return DisplayableTranscriptST.from({
      segments: filteredSegments,
      speakerInfoMap: this.speakerInfoMap,
    });
  }
}

type KnownMockTranscripts = "SampleHakomi1";

function matchKnownMock(
  knownMock: KnownMockTranscripts
): DisplayableTranscriptSegment[] {
  const json = Match.value(knownMock).pipe(
    Match.when("SampleHakomi1", () => SampleHakomi1Mock),
    Match.exhaustive
  );

  console.log("json", json);

  const decoded = S.decodeUnknownSync(S.Array(DisplayableTranscriptSegment))(
    json.transcript.segments
  );
  return [...decoded];
}
