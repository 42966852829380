import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { useGetClientInfoDash } from "frontend-shared/src/users/hp/clients.vms";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { TopLevelTabLink } from "web-shared/src/components/dashboard/dashboard-layout";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { SimpleTable } from "web-shared/src/components/table";
import { ChatRoom } from "web-shared/src/domains/chat/chat-room";

export const ClientInfoDashboardLayout: React.FC = () => {
  const { clientId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID
  );

  const { client } = useGetClientInfoDash({
    clientId: clientId as Id<"users">,
  });

  if (client === undefined) {
    return <FullContainerLoadingSpinner />;
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-8">
        <ClientProfileSection client={client} />
        <Header />
      </div>
      <div className="flex flex-col py-6">
        <Outlet />
      </div>
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <div className="flex items-center justify-between">
      <Tabs />
      <ActionButtons />
    </div>
  );
};

const Tabs: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex gap-4">
      <TopLevelTabLink
        to={"past-sessions"}
        isActive={pathname.includes("past-sessions")}
      >
        Past sessions
      </TopLevelTabLink>
      <TopLevelTabLink to={"chat"} isActive={pathname.includes("chat")}>
        Chats
      </TopLevelTabLink>
    </div>
  );
};

const ClientProfileSection: React.FC<{
  client: SimpleUserWithProfilePhoto;
}> = ({ client }) => {
  return (
    <div className="flex items-center gap-4">
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(client.profilePhoto)}
        size={100}
      />
      <div className="flex flex-col gap-2">
        <h4 className="text-[20px]">{client.name}</h4>
        <p className="text-[14px] text-vid-black-700">{client.email}</p>
      </div>
    </div>
  );
};

const ActionButtons: React.FC = () => {
  return <div></div>;
};

export const PastSessionsWithClient: React.FC = () => {
  const navigate = useNavigate();
  const { clientId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID
  );

  const pastSessions = useQuery(
    api.Screens.Hp.Dashboard.Clients.ClientDetailDashFns
      .getPastSessionsWithClient,
    { clientId: clientId as Id<"users"> }
  );

  if (pastSessions === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div>
      <SimpleTable
        action={{
          onClick: (s) => {
            navigate(
              UROUTES.SESSIONS.SESSION_ID.REVIEW.buildPath({
                sessionId: s.baseSessionId,
              })
            );
          },
        }}
        columns={[
          {
            key: "pastSessions",
            header: "Past Sessions",
            render: (session) => {
              return <div>{session.clientInfo.name}</div>;
            },
          },
          {
            key: "mbEndedAt",
            header: "Ended At",
            render: (session) => {
              return (
                <div>{format(new Date(session.endedAt), "MMM dd, yyyy")}</div>
              );
            },
          },
        ]}
        data={pastSessions}
        roundedTop={true}
      />
    </div>
  );
};

export const ChatWithClient: React.FC = () => {
  const { clientId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID
  );
  const [chatRoomId, setChatRoomId] = useState<Id<"chatRooms"> | undefined>(
    undefined
  );

  const getOrCreateChatRoomWithClient = useMutation(
    api.Screens.Hp.Dashboard.Clients.ClientDetailDashFns
      .getOrCreateChatRoomWithClient
  );

  useEffect(() => {
    getOrCreateChatRoomWithClient({ clientId: clientId as Id<"users"> }).then(
      (chatRoomId) => {
        setChatRoomId(chatRoomId);
      }
    );
  }, [clientId]);

  if (chatRoomId === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4">
      <ChatRoom roomId={chatRoomId} />
    </div>
  );
};
