import type { Id } from "shared/be/convex/_generated/dataModel";
import { PrimaryButton } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { Match } from "effect";
import { useUnit } from "effector-react";
import { useOnEnterSession } from "frontend-shared/src/sessions/session.vm";
import type React from "react";
import { useEffect } from "react";
import type { PostLiveRoomType } from "shared/be/convex/Sessions/Rooms/Room.Types";
import { isNullOrUndefined } from "shared/util";
import { LiveRoom } from "./rooms/live/live-room";

export const SessionContainer: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  const { sessionVM, hasEnteredSession } = useOnEnterSession({ sessionId });
  const myCurrentRoom = useUnit(sessionVM.$myCurrentRoom);

  useEffect(() => {
    console.log("SESSION MY CURRENT ROOM! : ", myCurrentRoom);
  }, [myCurrentRoom]);

  if (isNullOrUndefined(myCurrentRoom) || !hasEnteredSession) {
    return <FullContainerLoadingSpinner />;
  }

  return Match.value(myCurrentRoom).pipe(
    Match.when({ tag: "LIVE" }, ({ roomId }) => (
      <LiveRoom baseSessionId={sessionId} roomId={roomId} />
    )),
    Match.when({ tag: "PRE_LIVE" }, () => (
      <PreLiveRoom sessionId={sessionId} />
    )),
    Match.when({ tag: "POST_LIVE" }, ({ roomType }) => (
      <PostLiveRoom roomType={roomType} />
    )),
    Match.exhaustive
  );
};

const PostLiveRoom: React.FC<{ roomType: PostLiveRoomType }> = ({
  roomType,
}) => {
  return Match.value(roomType).pipe(
    Match.when("REVIEW", () => <ReviewRoom />),
    Match.when("EXIT", () => <ExitRoom />),
    Match.exhaustive
  );
};

const ExitRoom: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/u";
    }, 200);
  }, []);

  return <div></div>;
};

const ReviewRoom: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 p-16">
      <h4>Review</h4>
      <PrimaryButton
        title="Back to dashboard"
        onClick={() => {
          window.location.href = "/u";
        }}
      />
    </div>
  );
};

const PreLiveRoom: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div>Admissions Lobby</div>
      <div>{sessionId}</div>
    </div>
  );
};
