import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";
import { EllipseMenuIcon } from "../../assets/ellipse.svg";
import { cn } from "../../utils/utils";

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      ref={ref}
      {...props}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "flex h-4 w-4 items-center justify-center rounded-full border border-gray-300 outline-none",
        "focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        "disabled:cursor-not-allowed disabled:opacity-50",
        "data-[state=checked]:border-purple-500",
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="h-3.5 w-3.5 rounded-full bg-purple-500" />
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

interface RadioOption {
  name: string;
  value: string | number | null;
  icon?: React.ReactNode;
}

interface RadioButtonsProps {
  options: RadioOption[];
  value: string | number | null;
  onChange: (value: string | number | null) => void;
}

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      value={value?.toString() ?? ""}
      onValueChange={(val: string) => {
        if (val === "") {
          onChange(null);
        } else {
          const option = options.find((opt) => opt.value?.toString() === val);
          onChange(option?.value ?? null);
        }
      }}
      className="flex flex-col gap-5"
    >
      {options.map((option) => (
        <div key={option.name} className="flex items-center space-x-2">
          <RadioGroupItem
            value={option.value?.toString() ?? ""}
            id={option.name}
          />
          <label
            htmlFor={option.name}
            className="flex flex-1 justify-between items-center cursor-pointer"
          >
            <span className="text-vid-black-900 text-sm font-roboto-flex line-clamp-1 ml-2">
              {option.name}
            </span>
            {option.icon && (
              <div className="flex items-center">{option.icon}</div>
            )}
          </label>
        </div>
      ))}
    </RadioGroup>
  );
};

const FormCardContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col border rounded-lg p-6">{children}</div>;
};

export const FormRadioCard: React.FC<{
  radioProps: RadioButtonsProps;
  title: string;
  optionsMenu?: React.ReactNode;
}> = ({ radioProps, title, optionsMenu }) => {
  return (
    <FormCardContainer>
      <div className="flex items-center justify-between">
        <span className="text-vid-purple font-sans font-light text-sm">
          {title}
        </span>
        {optionsMenu && (
          <div>
            <EllipseMenuIcon size={3} fill={"gray"} />
          </div>
        )}
      </div>
      <div className="mt-2 flex-1 flex flex-col font-sans text-sm font-light">
        <RadioButtons {...radioProps} />
      </div>
    </FormCardContainer>
  );
};
