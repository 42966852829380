import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { BaseSelect } from "web-shared/src/components/ui/select";
import { Rx } from "web-shared/src/prelude";

export const SetDurationSection: React.FC<{
  durationInMinutes$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
}> = ({ durationInMinutes$, onChange }) => {
  const durationInMinutes = useObservableEagerState(durationInMinutes$);

  return (
    <div>
      <BaseSelect
        label="Duration"
        placeholder="Select duration"
        value={durationInMinutes?.toString()}
        onChange={(v) => onChange(parseInt(v))}
        options={[
          { value: "60", label: "60 minutes" },
          { value: "90", label: "90 minutes" },
          { value: "120", label: "120 minutes" },
        ]}
      />
    </div>
  );
};

export class DurationPickerVM {
  $selectedDurationMins = createStore<number>(60);
  setDurationMins = createEvent<number>();

  constructor() {
    this.$selectedDurationMins.on(
      this.setDurationMins,
      (_, newValue) => newValue
    );
  }
}

export const DurationSelector: React.FC<{
  vm: DurationPickerVM;
}> = ({ vm }) => {
  const selectedDurationMins = useUnit(vm.$selectedDurationMins);

  return (
    <div>
      <BaseSelect
        label="Duration"
        placeholder="Select duration"
        value={selectedDurationMins.toString()}
        onChange={(v) => vm.setDurationMins(parseInt(v))}
        options={[
          { value: "60", label: "60 minutes" },
          { value: "90", label: "90 minutes" },
          { value: "120", label: "120 minutes" },
        ]}
      />
    </div>
  );
};

export const SetEndingSessionReminderSection: React.FC<{
  endingSessionReminder$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
}> = ({ endingSessionReminder$, onChange }) => {
  const endingSessionReminder = useObservableEagerState(endingSessionReminder$);

  return (
    <div>
      <BaseSelect
        label="Ending Session Reminder"
        placeholder="Select reminder time"
        value={endingSessionReminder?.toString()}
        onChange={(v) => onChange(parseInt(v))}
        options={[
          { value: "5", label: "5 minutes" },
          { value: "10", label: "10 minutes" },
          { value: "15", label: "15 minutes" },
          { value: "20", label: "20 minutes" },
        ]}
      />
    </div>
  );
};

// export const SetPaymentSection: React.FC<{
//   isOpen: boolean;
//   onToggle: (isOpen: boolean) => void;
//   priceCents$: Rx.Observable<number | null>;
//   setPriceCents: (v: number | null) => void;
// }> = ({ isOpen, onToggle, priceCents$, setPriceCents }) => {
//   const priceCents = useObservableEagerState(priceCents$);
//   const [inputValue, setInputValue] = React.useState(
//     priceCents?.toString() || ""
//   );

//   const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const priceInDollarsStr = e.target.value;
//     setInputValue(priceInDollarsStr);

//     if (priceInDollarsStr === "") {
//       setPriceCents(null);
//     } else {
//       const numericValue = parseFloat(priceInDollarsStr);
//       if (!isNaN(numericValue) && numericValue >= 0) {
//         setPriceCents(numericValue * 100);
//       }
//     }
//   };

//   // TODO
//   return (
//     <div>
//     </div>
//   );
// };
