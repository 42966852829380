import { route, string } from "react-router-typesafe-routes/dom";
import { UROUTES } from "./u.routes";

export * from "./hp/hp.routes";

export const ROUTES = route(
  "",
  {},
  {
    LOGIN: route("login", {
      searchParams: {
        prefilledEmail: string(),
        redirectTo: string(),
      },
    }),
    AUTH: route(
      "auth",
      {},
      {
        AUTHIN: route("authin"),
        VERIFY_EMAIL: route("verify-email"),
        OAUTH_CALLBACK: route("oauth-callback"),
        SUCCESS: route("success", {
          searchParams: {
            email: string(),
          },
        }),
      }
    ),
    U: UROUTES,
    INVITES: route(
      "invites",
      {},
      {
        INVITE_ID: route(":inviteId", {
          params: {
            inviteId: string().defined(),
          },
          searchParams: {
            email: string(),
          },
        }),
      }
    ),
  }
);

export const ANON_ROUTES = route(
  "anon",
  {},
  {
    CONFIRM_EMAIL_INVITE: route("confirm-email-invite", {
      searchParams: {
        email: string().defined(),
        redirectTo: string().defined(),
        action: string().defined(),
        source: string().defined(),
        clientId: string(),
      },
    }),

    DASH: route(
      "dash",
      {},
      {
        CLIENTS: route("clients"),
        COMMUNITY: route(
          "community",
          {},
          {
            MEMBERS: route(
              "members",
              {},
              {
                MEMBER_ID: route(
                  ":memberId",
                  {
                    params: {
                      memberId: string().defined(),
                    },
                  },
                  {
                    DISCUSSION: route("discussion"),
                  }
                ),
              }
            ),
          }
        ),
      }
    ),

    MY_GLOBAL_PROFILE: route(
      "global-profiles/:userId",
      {
        params: { userId: string().defined() },
      },

      {
        DISCUSSION: route("discussion"),
        EVENTS: route("events"),
        ABOUT: route("about"),
        SCHEDULE: route("schedule"),
      }
    ),
  }
);
