import React from "react";
import { DashboardStateMgr } from "web-shared/src/components/dashboard/dashboard.state";
import type { UWebStateMgr } from "web-shared/src/web-context";

export class PortalWebStateMgr {
  dashboardState: DashboardStateMgr;

  constructor(readonly uMgr: UWebStateMgr) {
    this.dashboardState = new DashboardStateMgr();
  }
}

export const HpStateContext = React.createContext<PortalWebStateMgr | null>(
  null
);

export function useHpState() {
  return React.useContext(HpStateContext)!;
}
