import { route, string } from "react-router-typesafe-routes/dom";
import { HPROUTES } from "shared/routes/hp/hp.routes";
import { CPROUTES } from "./cp.routes";

export const USESSIONS_ROUTES = route(
  "sessions",
  {},
  {
    SESSION_ID: route(
      ":sessionId",
      { params: { sessionId: string().defined() } },
      {
        RTC: route("rtc"),
        REVIEW: route("review"),
      }
    ),
  }
);

export const UPRIVATE_SESSIONS_ROUTES = route(
  "private-sessions",
  {},
  {
    PRIVATE_SESSION_ID: route(
      ":privateSessionId",
      {
        params: { privateSessionId: string().defined() },
      },
      {
        REVIEW: route("review"),
        RTV: route(
          "rtv",
          { searchParams: { startNew: string() } },
          {
            ENTRY: route("entry"),
            CALLS: route(
              "calls",
              {},
              {
                CALL_ID: route(
                  ":callId",
                  {
                    params: { callId: string().defined() },
                  },
                  {
                    HP: route(
                      "hp",
                      {},
                      {
                        MAIN_ROOM: route("main-room"),
                        WAITING_ROOM: route("waiting-room"),
                      }
                    ),
                    CP: route(
                      "cp",
                      {},
                      {
                        MAIN_ROOM: route("main-room"),
                        WAITING_ROOM: route("waiting-room"),
                      }
                    ),
                  }
                ),
              }
            ),
          }
        ),
      }
    ),
  }
);

export const UROUTES = route(
  "u",
  {},
  {
    COMMUNITIES: route(
      "communities",
      {},
      {
        COMMUNITY: route(
          ":communitySlug",
          {
            params: {
              communitySlug: string().defined(),
            },
          },
          {
            ADMIN: route("admin"),
          }
        ),
      }
    ),
    COURSES: route(
      "courses",
      {},
      {
        COURSE_ID: route(
          ":courseId",
          {
            params: { courseId: string().defined() },
          },
          {
            COURSE_APP: route("course-app"),
            ADMIN: route(
              "admin",
              {},
              {
                EDIT_COURSE: route("edit-course"),
                LESSON_ID: route(
                  ":lessonId",
                  {
                    params: { lessonId: string().defined() },
                  },
                  {
                    EDIT_LESSON: route("edit-lesson"),
                  }
                ),
              }
            ),
          }
        ),
      }
    ),
    CP: CPROUTES.ROOT,
    HP: HPROUTES,
    SESSIONS: USESSIONS_ROUTES,
    PRIVATE_SESSIONS: UPRIVATE_SESSIONS_ROUTES,
    ONBOARD: route(
      "onboard",
      {},
      {
        DO_YOU_SEE_CLIENTS: route("do-you-see-clients"),
        INPUT_PROFILE: route("input-profile"),
      }
    ),
  }
);
