import { CameraIcon, CameraOffIcon, Check } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { MuteVideoButton } from "./rooms/control-panel";
import { CameraSvg } from "@/src/assets/icons/camera.svg";

export const useCamera = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    async function setupCamera() {
      if (!isEnabled) {
        if (videoRef.current?.srcObject) {
          const stream = videoRef.current.srcObject as MediaStream;
          stream.getTracks().forEach((track) => track.stop());
          videoRef.current.srcObject = null;
        }
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        setError("Camera access denied or not available");
      }
    }

    setupCamera();

    return () => {
      if (videoRef.current?.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isEnabled]);

  const toggleCamera = () => {
    setIsEnabled((prev) => !prev);
    setError(null);
  };

  return {
    videoRef,
    error,
    isEnabled,
    toggleCamera,
  };
};

export const LocalVideoPreviewView: React.FC<{
  isEnabled: boolean;
  error: string | null;
  videoRef: React.RefObject<HTMLVideoElement>;
}> = ({ isEnabled, error, videoRef }) => {
  return (
    <div className="relative">
      {isEnabled ? (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className="w-[320px] h-[240px] rounded bg-gray-100"
        />
      ) : (
        <div className="flex items-center justify-center w-[320px] h-[240px] bg-vid-black-900 rounded">
          <div className="flex flex-col items-center gap-2">
            <CameraOffIcon className="w-8 h-8 text-white" />
            <span className="text-sm text-white">Camera is off</span>
          </div>
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded">
          <div className="flex flex-col items-center gap-2">
            <CameraIcon className="w-8 h-8 text-gray-400" />
            <span className="text-sm text-gray-500">{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const LocalVideoPreview: React.FC = () => {
  const { isEnabled, error, videoRef, toggleCamera } = useCamera();
  return (
    <div className="w-[320px] h-[240px] relative">
      <LocalVideoPreviewView
        isEnabled={isEnabled}
        error={error}
        videoRef={videoRef}
      />
      <div className="absolute bottom-4 right-4">
        <MuteVideoButton isMuted={!isEnabled} onClick={toggleCamera} />
      </div>
    </div>
  );
};

export const ConnectingAnimation: React.FC<{ isConnected: boolean }> = ({
  isConnected,
}) => {
  return (
    <div
      className={`flex-1 flex flex-col justify-center items-center gap-4 bg-vid-black-900 text-white border border-blue-400 rounded-[20px] p-4 transition-all duration-500`}
    >
      {isConnected ? (
        <div className="flex flex-col items-center gap-3">
          <div className="relative">
            <div className="absolute inset-0 bg-green-400/20 animate-ping rounded-full" />
            <div className="relative w-16 h-16 rounded-full bg-green-400/10 flex items-center justify-center">
              <Check className="w-8 h-8 text-green-400 animate-[checkmark_0.5s_ease-in-out_forwards]" />
            </div>
          </div>
          <h4 className="font-medium text-green-400 animate-[fadeIn_0.5s_ease-in-out]">
            Connected!
          </h4>
        </div>
      ) : (
        <>
          <div className="animate-pulse">
            <CameraSvg size={60} color={"#690DFF"} />
          </div>
          <h4 className="font-medium text flex items-center gap-1">
            Connecting to feed
            <span className="inline-flex">
              <span className="animate-[dots_1.4s_infinite] opacity-0">.</span>
              <span className="animate-[dots_1.4s_0.2s_infinite] opacity-0">
                .
              </span>
              <span className="animate-[dots_1.4s_0.4s_infinite] opacity-0">
                .
              </span>
            </span>
          </h4>
        </>
      )}
    </div>
  );
};
