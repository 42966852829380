import {
  useConvex,
  useMutation,
  useQuery,
  type ConvexReactClient,
} from "convex/react";
import { Option } from "effect";
import { pipe } from "fp-ts/lib/function";
import { useCallback } from "react";
import * as Rx from "rxjs";
import { RD, TE } from "shared/base-prelude";
import { api } from "shared/be/convex/_generated/api";
import type { KnownCalendarAppt } from "shared/be/convex/Calendar/Calendar.Types";
import type { CommunityFeed } from "shared/be/convex/Community/CommunityDiscussion.Types";
import type {
  AllCommunityEventsScreenData,
  UpcomingCommunityCalEventInstanceCard,
} from "shared/be/convex/Screens/Community/CommunityEventsScreen.Types";
import type { SimpleUserWithProfilePhoto } from "shared/be/convex/User/User.Types";
import type { CommunityInfo } from "shared/schemas/community/community.schemas";
import type { NearbyCalendarMonths } from "shared/types/calendar.types";
import { asMb } from "shared/util";
import { CalendarStateMgr } from "../../mgrs/state-mgrs/calendar.statemgr";
import { createContextAndHook, useNow, useQuery$ } from "../../util";
import type { CommunityJoinRequirements } from "shared/be/convex/Community/Community.Types";

export function useSetupCommunityDash(p: {
  communitySlug: string;
}): CommunityDashVM | undefined {
  const communityInfo = useQuery(
    api.Community.CommunityScreenFns.getCommunityInfo,
    {
      communitySlug: p.communitySlug,
    }
  );

  const communityJoinRequirements$ = useQuery$(
    api.Community.CommunityFns.getCommunityJoinRequirements,
    {
      communitySlug: p.communitySlug,
    }
  );

  const postsRes$ = useQuery$(
    api.Community.CommunityScreenFns.getCommunityFeed,
    {
      communitySlug: p.communitySlug,
    }
  );

  const now = useNow();

  const allEventInstances$ = useQuery$(
    api.Screens.Community.CommunityEventsScreenFns
      .getAllCommunityEventsScreenData,
    {
      communitySlug: p.communitySlug,
      now,
    }
  );

  const allMembers$ = useQuery$(
    api.Community.CommunityScreenFns.listAllJoinedMembers,
    {
      communitySlug: p.communitySlug,
    }
  );

  const topLiveEvent = useQuery(
    api.Screens.Community.CommunityEventsScreenFns.getTopLiveEvent,
    {
      communitySlug: p.communitySlug,
      now,
    }
  );

  const onJoinButtonClickM = useMutation(
    api.Community.CommunityScreenFns.onJoinButtonClick
  );

  const onJoinButtonClick: (paymentAgreement?: {
    hasAgreedToPayment: boolean;
  }) => Promise<null> = useCallback(
    (paymentAgreement) => {
      if (!communityInfo) return Promise.resolve(null);
      return onJoinButtonClickM({
        communitySlug: p.communitySlug,
        joinAction: communityInfo.amIJoined ? "unjoin" : "join",
        hasAgreedToPayment: paymentAgreement?.hasAgreedToPayment,
      });
    },
    [onJoinButtonClickM, p.communitySlug, communityInfo]
  );

  const convexCli = useConvex();

  return Option.all({
    communityInfo: asMb(communityInfo),
  }).pipe(
    Option.map(
      ({ communityInfo }) =>
        new CommunityDashVM(
          convexCli,
          communityInfo,
          postsRes$,
          allEventInstances$,
          allMembers$,
          communityJoinRequirements$,
          onJoinButtonClick,
          topLiveEvent
        )
    ),
    Option.getOrUndefined
  );
}

export class CommunityDashVM {
  calendarMgr: CalendarStateMgr;

  constructor(
    readonly convexCli: ConvexReactClient,
    readonly communityInfo: CommunityInfo,
    readonly postsRes$: Rx.BehaviorSubject<CommunityFeed | undefined>,
    readonly allEventInstances$: Rx.BehaviorSubject<
      AllCommunityEventsScreenData | undefined
    >,
    readonly allMembers$: Rx.BehaviorSubject<
      SimpleUserWithProfilePhoto[] | undefined
    >,
    readonly communityJoinRequirements$: Rx.BehaviorSubject<
      CommunityJoinRequirements | undefined | null
    >,
    readonly onJoinButtonClick: (paymentAgreement?: {
      hasAgreedToPayment: boolean;
    }) => Promise<null>,
    readonly topLiveEvent:
      | typeof UpcomingCommunityCalEventInstanceCard.Encoded
      | null
      | undefined
  ) {
    const rdAppts$ = new Rx.BehaviorSubject<
      RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
    >(RD.initial);

    this.calendarMgr = new CalendarStateMgr({
      fetchApptsTE: (dayInFocus) =>
        pipe(
          TE.fromTask(() =>
            convexCli.query(
              api.Screens.Community.CommunityEventsScreenFns
                .getNearbyMonthCalendarAppts,
              {
                communitySlug: this.communityInfo.slug,
                now: dayInFocus.getTime(),
              }
            )
          ),
          TE.chainFirst((r) =>
            TE.fromIO(() => {
              console.log("RESULT OF QUERY! ", r);
            })
          )
        ),
      rdAppts$,
    });
  }
}

export const [CommunityDashVMProvider, useCommunityDashVM] =
  createContextAndHook<CommunityDashVM>();
